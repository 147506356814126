import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';

export const account_buyer_id = {
    validate: function (opportunity: OpportunityViewEntity) {
        const errors: string[] = [];

        if (isEmpty(opportunity?.account_buyer_id)) {
            errors.push(missingField('Account Buyer'));
        }

        return errors;
    }
};
