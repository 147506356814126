import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const em_paid_by_enatera_amount = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.earnest_paid_by_entera === 'yes' &&
      isEmpty(opportunity?.earnest_paid_by_entera_amount)
    ) {
      errors.push(missingField('EM Paid by Entera Amount'));
    }

    return errors;
  }
};
