import { offer_to_list_c } from './offer-to-list-c';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const list_price_c = {
  handleChange: function (
    opportunity: OpportunityViewEntity,
    originalOpportunity: OpportunityViewEntity
  ) {
    const result = {
      offer_to_list_c: offer_to_list_c.calculate(
        opportunity,
        originalOpportunity
      )
    };

    return result;
  }
};
