import { missingField } from 'assets/validation-template';

export const name = {
  validate: function (marketPreference: {data: { name: string}}) {
    const errors: string[] = [];

    if (
      !marketPreference.data?.name ||
      marketPreference?.data.name.trim().length === 0
    ) {
      errors.push(missingField('Name'));
    }

    return errors;
  }
};
