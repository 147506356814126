import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';

export const contract_terminated_date = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      [
        oppurtunityStatusList.closing_cancel_contract,
        oppurtunityStatusList.closing_termination_pending
      ].includes(opportunity?.opportunity_status_c) &&
      (!opportunity?.contract_terminated_date ||
        opportunity?.contract_terminated_date?.trim().length === 0)
    ) {
      errors.push(missingField('Contract Termination Date'));
    }

    return errors;
  }
};
