import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, ListItemText, useTheme } from '@mui/material';
import documentState from 'assets/constants/document-state';
import documentStatus from 'assets/constants/document-status';
import documentType from 'assets/constants/document-type';
import fieldLabel from 'assets/constants/fieldLabel';
import CircularLoader from 'components/dog-loader/dog-lodar';
import ValidationError from 'components/errors/validation-error';
import FormContainer from 'components/form/container';
import UnitItem from 'components/form/unit-item';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import {
  getTableBodyCell,
  getTableRow
} from 'components/table/table-component';
import { toastError } from 'event/toast-event';
import {
  createDocumentName,
  getSubTypeOptions
} from 'helpers/document/document-helper';
import emptyFunction from 'helpers/empty-function-helper';
import { isEmpty } from 'helpers/misc-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { isAmendmentsOrAddendumsDocType } from 'helpers/validation/document-helper';
import { OpportunityContext } from 'pages/opportunity/Context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import documentRevisionService from 'services/document-revision-service';
import documentService from 'services/document-service';
import { tokens } from 'theme';
import { ObjectType } from 'types';
import { ChangeEvent } from 'types/common-types';
import { DocumentUploadItem } from 'types/document-types';
import { ErrorValidation } from 'types/error-types';
import { OpportunityEntity } from 'types/opportunity-types';
import { OptionType } from 'types/option-type';

type DocumentItemProps = {
  document: DocumentUploadItem;
  index: number;
  removeFile: (value: number) => void;
  updateDocuments: (
    index: number,
    document: Partial<DocumentUploadItem>
  ) => void;
  opportunity?: OpportunityEntity;
  opportunityId: string;
  validation: ErrorValidation;
  isSameTypeDocumentSelected: (type: string, sub_type: string) => boolean;
  opportunityIsCreatedAfterExtThreshholdDate: boolean;
};

const DocumentItem = ({
  document,
  index,
  removeFile,
  updateDocuments,
  isSameTypeDocumentSelected,
  opportunityId,
  validation,
  opportunityIsCreatedAfterExtThreshholdDate,
  opportunity
}: DocumentItemProps) => {
  const {
    file,
    category_id,
    sub_type,
    revision,
    status,
    state,
    autoFocus,
    document_extension
  } = document;

  const { originalOpportunity } = useContext(OpportunityContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [subTypetOptions, setSubtypeOption] = useState<OptionType[]>([]);
  const [docRevisions, setDocRevisions] = useState<ObjectType[]>([]);
  const [documentsCount, setDocumentsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRevisionLoaded, setIsRevisionLoaded] = useState(false);

  const isRevisionExists = async () => {
    const query = `?filter[opportunity_id]=${opportunityId}&filter[document_type]=${category_id}&filter[document_subtype]=${sub_type}`;
    let result: ObjectType;

    setIsLoading(true);
    if (
      opportunityIsCreatedAfterExtThreshholdDate &&
      isAmendmentsOrAddendumsDocType(category_id) &&
      !isEmpty(sub_type)
    ) {
      result = await documentService.getList(query);
      setDocumentsCount(result.data.meta.total + 1);
    } else {
      result = await documentRevisionService.getList(query);
    }

    setIsLoading(false);
    const revisions = result.data.data;

    if (opportunityIsCreatedAfterExtThreshholdDate) {
      if (revisions.length === 0) {
        updateDocuments(index, {
          ...document,
          revision: 1,
          document_extension: 1
        });
      }
    }

    if (revisions.length > 0) {
      if (
        opportunityIsCreatedAfterExtThreshholdDate &&
        isAmendmentsOrAddendumsDocType(category_id) &&
        isEmpty(document_extension)
      ) {
        revisions.sort(
          (a: ObjectType, b: ObjectType) =>
            +b.document_extension - +a.document_extension
        );
      } else {
        revisions.sort(
          (a: ObjectType, b: ObjectType) => +b.revision - +a.revision
        );
      }

      setDocRevisions(revisions);
      updateDocuments(index, {
        ...document,
        revision: isNaN(+revisions[0].revision)
          ? 1
          : +revisions[0].revision + 1,
        document_extension: documentsCount
      });
    }
  };
  useEffect(() => {
    setDocRevisions([]);
    setSubtypeOption(
      getSubTypeOptions(
        category_id,
        sub_type,
        opportunityIsCreatedAfterExtThreshholdDate
      )
    );
  }, [category_id]);

  useEffect(() => {
    setDocumentsCount(1);
  }, [category_id, sub_type]);

  useEffect(() => {
    if (category_id == 'misc') return;
    setDocRevisions([]);
    if (isEmpty(sub_type)) return;

    isRevisionExists();
  }, [sub_type]);

  useEffect(() => {
    updateDocuments(index, {
      ...document,
      document_extension: documentsCount
    });
  }, [documentsCount]);

  const Row = getTableRow(colors);
  const Cell = getTableBodyCell(colors);
  return (
    <>
      <Row
        sx={{
          backgroundColor: 'white !important'
        }}
      >
        <Cell sx={{ borderLeft: '3px solid grey' }}>
          <ListItemText primary={`${file.name}`} />
          {!isLoading ? (
            <ListItemText
              primary={`${createDocumentName(
                document,
                opportunity || originalOpportunity
              )}`}
            />
          ) : (
            ''
          )}
          {validation[`documents.${index}.document_name`] && (
            <ValidationError
              data={validation[`documents.${index}.document_name`] ?? ''}
            />
          )}
        </Cell>
        <Cell sx={{ pt: 1 }}>
          <UnitSelect
            label={fieldLabel.documentType}
            name="category_id"
            value={category_id}
            onChange={(e) => {
              updateDocuments(index, {
                file: file,
                category_id: e.target.value,
                sub_type: '',
                status: status,
                revision: 1,
                state: state,
                document_extension: 0
              });
            }}
            records={getObjectEntriesAsArray(documentType)}
            grid={{ xs: 12, sm: 12 }}
            error={validation[`documents.${index}.document_type`] ?? ''}
          />
        </Cell>

        <Cell sx={{ pt: 1 }}>
          {category_id != 'misc' ? (
            <>
              {isAmendmentsOrAddendumsDocType(category_id) &&
              opportunityIsCreatedAfterExtThreshholdDate ? (
                <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
                  <FormContainer>
                    <UnitSelect
                      label={fieldLabel.documentSubType}
                      name="sub_type"
                      value={sub_type}
                      onChange={(e) => {
                        if (
                          isSameTypeDocumentSelected(
                            category_id,
                            e.target.value
                          )
                        ) {
                          toastError(fieldLabel.sameTypeAndSubTypeNotAllowed);
                          return;
                        }
                        updateDocuments(index, {
                          file: file,
                          category_id: category_id,
                          sub_type: e.target.value,
                          status: status,
                          state: state,
                          revision: 1,
                          document_extension: 0
                        });
                      }}
                      records={subTypetOptions}
                      grid={{ xs: 6, sm: 6 }}
                      error={
                        validation[`documents.${index}.document_subtype`] ?? ''
                      }
                    />
                    <UnitText
                      name="document_extension"
                      label={'Ext Num'}
                      value={document_extension}
                      grid={{ xs: 6, sm: 6 }}
                      onChange={emptyFunction}
                      disabled={true}
                    />
                  </FormContainer>
                </UnitItem>
              ) : (
                <UnitSelect
                  label={fieldLabel.documentSubType}
                  name="sub_type"
                  value={sub_type}
                  onChange={(e) => {
                    if (
                      isSameTypeDocumentSelected(category_id, e.target.value)
                    ) {
                      toastError(fieldLabel.sameTypeAndSubTypeNotAllowed);
                      return;
                    }
                    updateDocuments(index, {
                      file: file,
                      category_id: category_id,
                      sub_type: e.target.value,
                      status: status,
                      state: state,
                      revision: 1,
                      document_extension: 0
                    });
                  }}
                  records={subTypetOptions}
                  grid={{ xs: 12, sm: 12 }}
                  error={
                    validation[`documents.${index}.document_subtype`] ?? ''
                  }
                />
              )}
            </>
          ) : (
            <UnitText
              onBlur={(e) => {
                if (!isRevisionLoaded) {
                  setDocRevisions([]);
                  isRevisionExists();
                  setIsRevisionLoaded(true);
                }
              }}
              name="sub_type"
              label={fieldLabel.documentSubType}
              value={sub_type}
              grid={{ xs: 12, sm: 12 }}
              onChange={(e: ChangeEvent) => {
                if (isSameTypeDocumentSelected(category_id, e.target.value)) {
                  toastError(fieldLabel.sameTypeAndSubTypeNotAllowed);
                  return;
                }
                updateDocuments(index, {
                  file: file,
                  category_id: category_id,
                  sub_type: e.target.value,
                  status: status,
                  state: state,
                  revision: 1,
                  document_extension: 0,
                  autoFocus: true
                });
                setIsRevisionLoaded(false);
              }}
              error={validation[`documents.${index}.document_subtype`] ?? ''}
              autoFocus={autoFocus ? true : false}
            />
          )}
        </Cell>

        <Cell sx={{ pt: 1 }}>
          <UnitSelect
            label={fieldLabel.documentState}
            name="state"
            value={state}
            onChange={(e) => {
              updateDocuments(index, {
                file: file,
                category_id: category_id,
                sub_type: sub_type,
                status: status,
                state: e.target.value,
                revision: revision,
                document_extension: document_extension,
                autoFocus: false
              });
            }}
            records={getObjectEntriesAsArray(documentState)}
            grid={{ xs: 12, sm: 12 }}
            error={validation[`documents.${index}.document_state`] ?? ''}
          />
        </Cell>
        <Cell sx={{ pt: 1 }}>
          <UnitSelect
            label={fieldLabel.documentStatus}
            name="status"
            value={status}
            onChange={(e) => {
              updateDocuments(index, {
                file: file,
                category_id: category_id,
                sub_type: sub_type,
                state: state,
                status: e.target.value,
                revision: revision,
                document_extension: document_extension,
                autoFocus: false
              });
            }}
            records={getObjectEntriesAsArray(documentStatus)}
            grid={{ xs: 12, sm: 12 }}
            error={validation[`documents.${index}.document_status`] ?? ''}
          />
        </Cell>

        <Cell>
          <Box p={1} style={{ color: 'black' }}>
            {(!isEmpty(document_extension) ||
              !isAmendmentsOrAddendumsDocType(category_id) ||
              !opportunityIsCreatedAfterExtThreshholdDate) && <>V{revision} </>}
            <IconButton color="error" onClick={() => removeFile(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Cell>
      </Row>

      {isLoading && <CircularLoader />}

      {!isLoading &&
        docRevisions.map((docRevision: ObjectType) => (
          <>
            {isAmendmentsOrAddendumsDocType(category_id) &&
            opportunityIsCreatedAfterExtThreshholdDate ? (
              <></>
            ) : (
              <Row
                sx={{
                  backgroundColor: `paper !important`,
                  p: 0.5
                }}
              >
                <Cell sx={{ paddingLeft: '20px' }}>{`${
                  docRevision.filename ?? docRevision.document_name
                }`}</Cell>
                <Cell>
                  <UnitSelect
                    label={fieldLabel.documentType}
                    name="category_id"
                    value={category_id}
                    onChange={emptyFunction}
                    records={getObjectEntriesAsArray(documentType)}
                    grid={{ xs: 12, sm: 12 }}
                    disabled={true}
                  />
                </Cell>
                <Cell>
                  <Box>
                    {category_id != 'misc' ? (
                      <>
                        {isAmendmentsOrAddendumsDocType(category_id) &&
                        opportunityIsCreatedAfterExtThreshholdDate ? (
                          <>
                            <UnitItem grid={{ xs: 12, sm: 12 }} p={0}>
                              <FormContainer>
                                <UnitSelect
                                  label={fieldLabel.documentSubType}
                                  name="sub_type"
                                  value={sub_type}
                                  onChange={emptyFunction}
                                  records={subTypetOptions}
                                  grid={{ xs: 6, sm: 6 }}
                                  disabled={true}
                                />
                                <UnitText
                                  label="Ext Num"
                                  name="document_extension"
                                  value={docRevision.document_extension}
                                  onChange={emptyFunction}
                                  grid={{ xs: 6, sm: 6 }}
                                  disabled={true}
                                />
                              </FormContainer>
                            </UnitItem>
                          </>
                        ) : (
                          <UnitSelect
                            label={fieldLabel.documentSubType}
                            name="sub_type"
                            value={sub_type}
                            onChange={emptyFunction}
                            records={subTypetOptions}
                            grid={{ xs: 12, sm: 12 }}
                            disabled={true}
                          />
                        )}
                      </>
                    ) : (
                      <UnitText
                        name="sub_type"
                        label={fieldLabel.documentSubType}
                        value={sub_type}
                        grid={{ xs: 12, sm: 12 }}
                        onChange={emptyFunction}
                        disabled={true}
                      />
                    )}
                  </Box>
                </Cell>
                <Cell>
                  <UnitSelect
                    label={fieldLabel.documentState}
                    name="state"
                    value={docRevision.document_state ?? docRevision.state}
                    onChange={emptyFunction}
                    records={getObjectEntriesAsArray(documentState)}
                    grid={{ xs: 12, sm: 12 }}
                    disabled={true}
                  />
                </Cell>
                <Cell>
                  <UnitSelect
                    label={fieldLabel.documentStatus}
                    name="status"
                    value={docRevision.status_id ?? docRevision.status}
                    onChange={emptyFunction}
                    records={getObjectEntriesAsArray(documentStatus)}
                    grid={{ xs: 12, sm: 12 }}
                    disabled={true}
                  />
                </Cell>
                {!isEmpty(docRevision.revision) && (
                  <Cell width={'10px'}>
                    <Box p={1}>V{docRevision.revision}</Box>
                  </Cell>
                )}
              </Row>
            )}
          </>
        ))}

      {/* {isSameTypeDocumentSelected(category_id, sub_type) && (
        <Row>
          <Cell colSpan={6}>
            <ValidationError data={['Duplicate Type and Sub Type']} />
          </Cell>
        </Row>
      )} */}
    </>
  );
};

export default DocumentItem;
