import fieldLabel from 'assets/constants/fieldLabel';
import hasPostOccupancy from 'assets/constants/has-post-occupancy';
import leaseType from 'assets/constants/lease-type';
import occupancyStatus from 'assets/constants/occupancy-status';
import InfoMessage from 'components/errors/info-component';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitDate from 'components/form/unit-date';
import UnitDateTime from 'components/form/unit-date-time';
import UnitEmpty from 'components/form/unit-empty';
import UnitHeading from 'components/form/unit-heading';
import UnitItem from 'components/form/unit-item';
import UnitPercentageFormatter from 'components/form/unit-percentage-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitSwitch from 'components/form/unit-switch';
import UnitText from 'components/form/unit-text';
import ModalComponent from 'components/modal';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import variableConfig from 'config/variable';
import { actionPerform } from 'event/action-event';
import { refreshOpportunity } from 'event/opportunity-event';
import UploadDocuments from 'features/documents/document-upload';
import DateUtility from 'helpers/date-helper';
import emptyFunction from 'helpers/empty-function-helper';
import federalHolidayHelper from 'helpers/federal-holiday-helper';
import { isEmpty } from 'helpers/misc-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import opportunityHelper from 'helpers/opportunity-helper';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import brokerageActionService from 'services/brokerage-action-service';
import partyService from 'services/party-service';
import { due_diligence_end_c } from 'services/validation/due_diligence_end_c';
import { initialAcceptOffer } from 'state/brokerage-actions/accept-offer';
import {
  AcceptOfferEntity,
  AcceptOfferPayloadEntity,
  ActionRecordViewPropTypes
} from 'types/brokerage-action-types';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';

import { getInitialAcceptOffer } from './helper';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  loadingOpportunity,
  isModal = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [documentUploadModal, setDocumentUploadModal] =
    useState<boolean>(false);
  const [data, setData] = useState<AcceptOfferEntity>(initialAcceptOffer);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toggleDocumentUploadModal = () =>
    setDocumentUploadModal(!documentUploadModal);

  const handleChange = (e: InputChangeEvent) => {
    if (e.target) {
      setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
    } else {
      setData(Object.assign({}, data, e));
    }
  };

  const validateData = (): boolean => {
    const fieldTovalidate = [
      'offer_date_c',
      'seller_name_c',
      'tax_id_c',
      'has_post_occupancy',
      'lease_type',
      'lease_agreement_received'
    ];

    const { isValid, errors } = validateBrokerageAction(data, fieldTovalidate);

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async (): Promise<void> => {
    const isValid = validateData();

    if (!isValid) return;

    if (opportunity.id) {
      const newDueDiligenceEndValue = due_diligence_end_c.calculate({
        contract_execution_date_c: data.contract_execution_date_c,
        option_days_type_c: opportunity.option_days_type_c,
        option_period_days_c: opportunity.option_period_days_c
      });

      const reqBody: AcceptOfferPayloadEntity = {
        contract_received_date: data.contract_received_date,
        contract_execution_date_c: data.contract_execution_date_c,
        offer_date_c: data.offer_date_c,

        tax_id_c: data.tax_id_c,
        new_construction_no_tax_id: data.new_construction_no_tax_id,
        seller_name_c: data.seller_name_c,

        has_post_occupancy: data.has_post_occupancy,
        lease_type: data.lease_type,
        lease_agreement_received: data.lease_agreement_received,
        occupancy_status_c: data.occupancy_status_c,
        initial_commission_percentage: data.initial_commission_percentage,
        due_diligence_end_c: newDueDiligenceEndValue
      };

      setIsLoading(true);

      const result =
        await brokerageActionService.update<AcceptOfferPayloadEntity>(
          opportunity.id,
          reqBody,
          'accept_offer'
        );

      setIsLoading(false);

      if (result.isValidationError) {
        setValidation(result.validationMessage);
      }

      if (result.isSuccess) {
        if (isModal) {
          onClose();
          actionPerform.accept_offer();
        } else {
          navigate(`/opportunities/${opportunity.id}/view`);
          refreshOpportunity({});
        }
      }
    }
  };

  const getValidDate = (date: string, field: string) => {
    const { validDate, message } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHolidayAndGetMessage(
        date,
        field,
        opportunityHelper.isOptionDayTypeIsBusinessOrCalendarDaysNoWeekendClosings(
          opportunity.option_days_type_c
        )
      );

    return {
      validDate: DateUtility.getFormattedDateString(validDate),
      message
    };
  };

  const onContractExecutionDateChange = (value: string) => {
    const newDueDiligenceEndValue = due_diligence_end_c.calculate({
      ...opportunity,
      contract_execution_date_c: value
    });

    const { validDate, message } = getValidDate(
      newDueDiligenceEndValue,
      fieldLabel.dueDiligenceEndDate
    );

    setValidation((prevValidation) => ({
      ...prevValidation,
      due_diligence_end_c_federal_holiday_validation_message: message
    }));

    setData((prevData: AcceptOfferEntity) => ({
      ...prevData,
      contract_execution_date_c: value,
      due_diligence_end_c: DateUtility.addHours(
        moment(validDate),
        variableConfig.DUE_DILIGENCE_END_START_TIME
      )
    }));
  };

  const loadParties = async () => {
    const partyList = await partyService.getList(
      `?page=1&filter[opportunity_id]=${opportunity.id}`
    );

    setData(getInitialAcceptOffer(opportunity, partyList.data.data));
  };

  useEffect(() => {
    if (!loadingOpportunity) {
      loadParties();
    }
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer id="accept_offer_form">
            <UnitDate
              label={fieldLabel.contractReceivedDate}
              name="contract_received_date"
              value={data.contract_received_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'contract_received_date', value: e }
                })
              }
              error={validation['contract_received_date'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitDate
              label={fieldLabel.contractExecutionDate}
              name="contract_execution_date_c"
              value={data.contract_execution_date_c ?? ''}
              onChange={(e: string) => {
                onContractExecutionDateChange(e);
              }}
              error={validation['contract_execution_date_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitItem grid={{ xs: 12, sm: 4 }}>
              <UnitDateTime
                label={fieldLabel.dueDiligenceEndDate}
                name="due_diligence_end_c"
                value={data.due_diligence_end_c || null}
                onChange={emptyFunction}
                error={validation['due_diligence_end_c'] ?? ''}
                grid={{ sm: 12, xs: 12 }}
                disabled
              />
              {!isEmpty(
                validation.due_diligence_end_c_federal_holiday_validation_message
              ) && (
                <InfoMessage
                  message={
                    validation.due_diligence_end_c_federal_holiday_validation_message ??
                    ''
                  }
                />
              )}
            </UnitItem>

            <UnitDate
              label={fieldLabel.offerDate}
              name="offer_date_c"
              value={data.offer_date_c ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'offer_date_c', value: e }
                })
              }
              error={validation['offer_date_c'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              required
            />

            <UnitText
              label={fieldLabel.taxID}
              name="tax_id_c"
              value={data.tax_id_c ?? ''}
              onChange={handleChange}
              error={validation['tax_id_c'] ?? ''}
              required
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSwitch
              value={data.new_construction_no_tax_id}
              onChange={handleChange}
              name="new_construction_no_tax_id"
              label={fieldLabel.newConstructionNoTaxId}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitText
              label={fieldLabel.sellerName}
              name="seller_name_c"
              value={data.seller_name_c ?? ''}
              onChange={handleChange}
              error={validation['seller_name_c'] ?? ''}
              required
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitHeading title="Post Occupancy" />

            <UnitSelect
              name="has_post_occupancy"
              label={fieldLabel.hasPostOccupancy}
              records={getObjectEntriesAsArray(hasPostOccupancy)}
              value={data.has_post_occupancy ? data.has_post_occupancy : 'yes'}
              onChange={handleChange}
              error={validation['has_post_occupancy'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              hasBlankOption={false}
            />

            <UnitSelect
              name="lease_type"
              label={fieldLabel.leaseType}
              records={getObjectEntriesAsArray(leaseType)}
              value={data.lease_type ?? ''}
              onChange={handleChange}
              error={validation['lease_type'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
              required
            />

            <UnitSwitch
              value={data.lease_agreement_received ?? 0}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value === 1) {
                  toggleDocumentUploadModal();
                }
              }}
              name="lease_agreement_received"
              label={fieldLabel.leaseArgumentReceived}
              error={validation['lease_agreement_received'] ?? ''}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitSelect
              name="occupancy_status_c"
              label={fieldLabel.occupancyStatus}
              records={getObjectEntriesAsArray(occupancyStatus)}
              value={data.occupancy_status_c ?? ''}
              onChange={handleChange}
              grid={{ xs: 12, sm: 4 }}
            />

            <UnitEmpty grid={{ xs: 0, sm: 4 }} />
            <UnitEmpty grid={{ xs: 0, sm: 4 }} />

            <UnitPercentageFormatter
              label={fieldLabel.initialCommissionPercentage}
              name="initial_commission_percentage"
              value={data.initial_commission_percentage ?? ''}
              onChange={handleChange}
              grid={{ xs: 12, sm: 4 }}
            />
          </FormContainer>
          {documentUploadModal == true && (
            <ModalComponent
              title={'Upload Document'}
              Component={UploadDocuments}
              data={{
                document_type: 'lease_agreement',
                onSuccess: () => {
                  toggleDocumentUploadModal();
                },
                opportunity,
                action: 'lease_agreement_document'
              }}
              onClose={(val: number) => {
                toggleDocumentUploadModal();
                if (val !== 1) {
                  setData((preData: AcceptOfferEntity) => ({
                    ...preData,
                    lease_agreement_received: 0
                  }));
                }
              }}
              loading={false}
              size="md"
            ></ModalComponent>
          )}
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
