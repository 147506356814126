import React, { useState } from 'react';
import { CardHeader, CardContent, Card } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import partiesService from 'services/party-service';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import UnitText from 'components/form/unit-text';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import SaveButton from 'components/form/button-save';
import CancelButton from 'components/form/button-cancel';
import { EditContainer } from '../record-view/container';
import UnitItem from 'components/form/unit-item';
import { PartyEntity, TenantPayloadEntity } from 'types/party-types';
import { validateTenantParty } from 'helpers/validation/party-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { ChangeEvent, DispatchSetState } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { toastSuccess } from 'event/toast-event';
import { CreateResponse } from 'types/api-response-types';
import UnitButtonIcon from 'components/form/button-icon';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import { isEmpty } from 'helpers/misc-helper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';

const TenantEdit = ({
  opportunity,
  setOpportunity,
  setOriginalOpportunity
}: {
  opportunity: OpportunityViewEntity;
  setOpportunity: DispatchSetState<OpportunityViewEntity>;
  setOriginalOpportunity: DispatchSetState<OpportunityViewEntity>;
  readOnly: boolean;
}) => {
  const [edit, setEdit] = useState(false);

  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );

  const [data, setData] = useState(opportunity.tenant);
  const [isDataChanged, setIsDataChanged] = useState(false);

  const [validation, setValidtion] = useState<ErrorValidation>({});

  const handleChange = (e: ChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
    setIsDataChanged(true);
  };

  const save = async () => {
    setValidtion({});

    const reqBody: TenantPayloadEntity = {
      name: data.name,
      mobile: data.mobile,
      email: data.email,
      type: 'tenant',
      opportunity_id: opportunity.id
    };

    const { status, errors } = validateTenantParty(reqBody);

    setValidtion(errors);

    if (!status) return;

    let result: CreateResponse<PartyEntity>;

    if (!opportunity.parties_tenant_id) {
      result = await partiesService.create<TenantPayloadEntity>(reqBody);
    } else {
      result = await partiesService.update<TenantPayloadEntity>(
        opportunity.parties_tenant_id,
        reqBody
      );
    }

    if (result.isValidationError) {
      setValidtion(result.validationMessage);
      return;
    }

    setOpportunity((prevOpportunity) => ({
      ...prevOpportunity,
      parties_tenant_id: result.data.id,
      tenant: result.data
    }));

    setOriginalOpportunity((prevOpportunity) => ({
      ...prevOpportunity,
      parties_tenant_id: result.data.id,
      tenant: result.data
    }));

    setData(result.data);

    toastSuccess('Party Saved');
    setEdit(!edit);
  };

  const toggleConfirmBox = () => {
    setConfirmModal({
      open: true,
      title: fieldLabel.areYouSure,
      text: fieldLabel.changesWillDiscarded,
      proceed: () => {
        setEdit(!edit);
        setConfirmModal(initialConfirmModalState);
        setData(opportunity.tenant);
        setOpportunity((prevOpportunity) => ({
          ...prevOpportunity,
          tenant: opportunity.tenant
        }));
        setOriginalOpportunity((prevOpportunity) => ({
          ...prevOpportunity,
          tenant: opportunity.tenant
        }));

        setIsDataChanged(false);
      },
      cancel: () => {
        setConfirmModal(initialConfirmModalState);
      }
    });
  };

  const toggleEditMode = () => {
    if (edit) {
      if (isDataChanged) {
        toggleConfirmBox();
      } else {
        setEdit(false);
        setIsDataChanged(false);
      }
    } else {
      setEdit(true);
      setIsDataChanged(false);
    }
  };

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          action={
            <UnitButtonIcon
              Icon={
                edit ? CloseIcon : !isEmpty(data?.id) ? EditIcon : AddCircleIcon
              }
              onClick={() => {
                toggleEditMode();
              }}
            />
          }
          title={fieldLabel.tenant}
        />
        <CardContent>
          <EditContainer>
            <FormContainer>
              <UnitText
                label={fieldLabel.tenantName}
                name="name"
                value={data.name ?? ''}
                onChange={handleChange}
                readOnly={!edit}
                grid={{ sm: 12, xs: 12 }}
                error={validation['name']}
              />

              <UnitText
                label={fieldLabel.tenantPhoneNumber}
                name="mobile"
                value={data.mobile ?? ''}
                onChange={handleChange}
                readOnly={!edit}
                grid={{ sm: 12, xs: 12 }}
                error={validation['mobile']}
              />

              <UnitText
                label={fieldLabel.tenantEmailAddress}
                name="email"
                value={data.email ?? ''}
                onChange={handleChange}
                readOnly={!edit}
                grid={{ sm: 12, xs: 12 }}
                error={validation['email']}
              />

              {edit ? (
                <UnitItem grid={{ sm: 12, xs: 12 }}>
                  <StackRowWithDivider>
                    <SaveButton onClick={save} />
                    <CancelButton onClick={() => toggleEditMode()} />
                  </StackRowWithDivider>
                </UnitItem>
              ) : (
                <></>
              )}
            </FormContainer>
          </EditContainer>
        </CardContent>
      </Card>
      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </>
  );
};

export default TenantEdit;
