import {
  Card,
  CardContent,
  CardHeader,
  FormLabel,
  IconButton
} from '@mui/material';
import ConfirmBox, {
  ConfirmModalProps,
  initialConfirmModalState
} from 'components/confirm-box/confirm-box';
import { EditContainer } from '../record-view/container';
import { GetResponse } from 'types/api-response-types';
import { DispatchSetState, InputChangeEvent } from 'types/common-types';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityServiceProviderEntity } from 'types/opportunity-service-provider-types';
import { OptionType } from 'types/option-type';
import { toastSuccess } from 'event/toast-event';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddVendor from 'features/brokerage-actions/due-diligence-fees/add-vendor';
import CancelButton from 'components/form/button-cancel';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import ModalComponent from 'components/modal';
import opportunityServiceProvidersService from 'services/opportunity-service-providers';
import React, { useEffect, useState } from 'react';
import SaveButton from 'components/form/button-save';
import serviceProviderService from 'services/service-provider-service';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import UnitButtonIcon from 'components/form/button-icon';
import UnitDate from 'components/form/unit-date';
import UnitItem from 'components/form/unit-item';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import feeAdvanceTypes from 'assets/constants/fee-advance-type';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import { ServiceProviderListEntity } from 'types/service-provider-types';
import { OpportunityViewEntity } from 'types/opportunity-types';

interface FeeAdvanceFormProps {
  data: OpportunityServiceProviderEntity;
  opportunity: OpportunityViewEntity;
  vendors: OptionType[];
  toggleVendorModal: () => void;
  updateOpportunity: DispatchSetState<OpportunityViewEntity>;
  index: number;
}

const FeeAdvanceForm = ({
  data,
  opportunity,
  vendors,
  index,
  toggleVendorModal,
  updateOpportunity
}: FeeAdvanceFormProps) => {
  const [editMode, setEditMode] = useState(false);
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>(
    initialConfirmModalState
  );

  const [feeAdvance, setFeeAdvance] = useState({ ...data });

  const [isDataChanged, setIsDataChanged] = useState(false);

  const handleChange = (e: InputChangeEvent) => {
    setFeeAdvance(
      Object.assign({}, feeAdvance, {
        [e.target.name]: e.target.value
      })
    );
    setIsDataChanged(true);
  };

  const save = async () => {
    const reqBody = {
      name: opportunity.name,
      opportunity_id: opportunity?.id,
      service_provider_id: feeAdvance.service_provider_id,
      service_amount: feeAdvance.service_amount,
      service_type: feeAdvance.service_type,
      service_date: feeAdvance.service_date
    };

    let response: GetResponse<OpportunityServiceProviderEntity>;

    if (!isEmpty(feeAdvance.id)) {
      response = await opportunityServiceProvidersService.update(
        feeAdvance.id,
        reqBody
      );
    } else {
      response = await opportunityServiceProvidersService.create(reqBody);
    }

    if (response.isSuccess) {
      toastSuccess(`${fieldLabel.feeAdvance} Saved`);
      updateOpportunity((prevOpportunity) => ({
        ...prevOpportunity,
        feeAdvances: [...opportunity.feeAdvances].map(
          (x: OpportunityServiceProviderEntity, i: number) =>
            i === index ? response.data : x
        )
      }));
      setFeeAdvance(response.data);
      setEditMode(!editMode);
    }
  };

  const toggleConfirmBox = () => {
    setConfirmModal({
      open: true,
      title: fieldLabel.areYouSure,
      text: fieldLabel.changesWillDiscarded,
      proceed: () => {
        setEditMode(!editMode);
        setConfirmModal(initialConfirmModalState);
        updateOpportunity((prevOpportunity) => ({
          ...prevOpportunity,
          feeAdvances: [...opportunity.feeAdvances].map(
            (x: OpportunityServiceProviderEntity, i: number) =>
              i === index ? data : x
          )
        }));
        setFeeAdvance(data);

        setIsDataChanged(false);
      },
      cancel: () => {
        setConfirmModal(initialConfirmModalState);
      }
    });
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (isDataChanged) {
        toggleConfirmBox();
      } else {
        setEditMode(false);
        setIsDataChanged(false);
      }
    } else {
      setEditMode(true);
      setIsDataChanged(false);
    }
  };

  return (
    <React.Fragment>
      <FormLabel>
        {fieldLabel.feeAdvance} {index + 1}
      </FormLabel>

      <UnitButtonIcon
        Icon={
          editMode
            ? CloseIcon
            : !isEmpty(feeAdvance.id)
            ? EditIcon
            : AddCircleIcon
        }
        onClick={() => {
          toggleEditMode();
        }}
      />
      <EditContainer>
        <FormContainer>
          <UnitSelect
            name="service_provider_id"
            label={fieldLabel.vendor}
            value={feeAdvance.service_provider_id}
            onChange={handleChange}
            records={vendors}
            readOnly={!editMode}
            grid={{ sm: 5, xs: 5 }}
          />

          <UnitItem grid={{ sm: 1, xs: 1 }}>
            {editMode ? (
              <IconButton
                aria-label="add"
                color="success"
                onClick={toggleVendorModal}
              >
                <AddCircleIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </UnitItem>

          <UnitSelect
            name="service_type"
            label={fieldLabel.type}
            records={getObjectEntriesAsArray(feeAdvanceTypes)}
            value={feeAdvance.service_type ?? ''}
            onChange={handleChange}
            readOnly={!editMode}
          />

          <UnitPriceFormatter
            label={fieldLabel.amount}
            name="service_amount"
            value={feeAdvance.service_amount ?? ''}
            onChange={handleChange}
            readOnly={!editMode}
          />

          <UnitDate
            label={fieldLabel.date}
            name="service_date"
            value={feeAdvance.service_date}
            onChange={(e) =>
              handleChange({
                target: { name: 'service_date', value: e }
              })
            }
            readOnly={!editMode}
          />

          {editMode ? (
            <UnitItem grid={{ sm: 6, xs: 12 }}>
              <StackRowWithDivider>
                <SaveButton onClick={save} />
                <CancelButton onClick={() => toggleEditMode()} />
              </StackRowWithDivider>
            </UnitItem>
          ) : (
            <></>
          )}
        </FormContainer>
        {confirmModal.open && <ConfirmBox {...confirmModal} />}
      </EditContainer>
    </React.Fragment>
  );
};

export const FeeAdvance = ({
  opportunity,
  updateOpportunity
}: {
  opportunity: OpportunityViewEntity;
  updateOpportunity: DispatchSetState<OpportunityViewEntity>;
}) => {
  const [vendorForm, showVendorForm] = useState<boolean>(false);
  const [vendors, setVendors] = useState<OptionType[]>([]);

  const toggleVendorModal = () => {
    showVendorForm(!vendorForm);
  };

  const loadVendors = async () => {
    const queryString = listQueryString({
      pagination: {
        page: 0,
        pageSize: 500
      },
      sort: prepareSort([{ field: 'name', sort: 'asc' }], {}),
      filter: {
        type: 'fee_advance'
      }
    });

    const result = await serviceProviderService.getList(queryString);

    if (result.isSuccess) {
      const vendorsOptions = result.data.data.map(
        (x: ServiceProviderListEntity) => ({
          value: x.id,
          label: x.name
        })
      );

      setVendors(vendorsOptions);
    }
  };

  useEffect(() => {
    loadVendors();
  }, []);

  return (
    <React.Fragment>
      <Card variant="outlined">
        <CardHeader title={fieldLabel.feeAdvance} />
        <CardContent>
          <FeeAdvanceForm
            data={opportunity.feeAdvances?.[0]}
            opportunity={opportunity}
            vendors={vendors}
            toggleVendorModal={toggleVendorModal}
            updateOpportunity={updateOpportunity}
            index={0}
          />
          <FeeAdvanceForm
            data={opportunity.feeAdvances?.[1]}
            opportunity={opportunity}
            vendors={vendors}
            toggleVendorModal={toggleVendorModal}
            updateOpportunity={updateOpportunity}
            index={1}
          />
        </CardContent>
        {vendorForm && (
          <ModalComponent
            title={fieldLabel.createNewServiceProvider}
            Component={AddVendor}
            data={{
              type: 'fee_advance',
              reloadVendor: () => {
                loadVendors();
                showVendorForm(false);
              }
            }}
            onClose={() => {
              showVendorForm(false);
            }}
            size="sm"
            isServiceCreateBox={true}
          ></ModalComponent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default FeeAdvance;
