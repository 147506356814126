import municipalInspectionTypes from 'assets/constants/municipal-inspection-types';
import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import accountBuyerService from 'services/account-buyer-service';
import opportunityCommissionService from 'services/opportunity-commission-service';
import opportunityService from 'services/opportunity-service';
import opportunityServiceProvidersService from 'services/opportunity-service-providers';
import partiesService from 'services/party-service';
import userService from 'services/user-service';
import validationService from 'services/validation-service';
import { buyer_rep_pct } from 'services/validation/commission-buyer-rep-pct';
import { buyer_source_pct } from 'services/validation/commission-buyer-source-pct';
import { negotiator_commission_amount } from 'services/validation/commission-negotiator-commission-amount';
import { negotiator_commission_pct } from 'services/validation/commission-negotiator-commission-pct';
import { seller_rep_pct } from 'services/validation/commission-seller-rep-pct';
import { seller_source_pct } from 'services/validation/commission-seller-source-pct';
import { ObjectType } from 'types';
import { OpportunityAssociatedFieldsEntity, OpportunityEntity, OpportunityViewEntity } from 'types/opportunity-types';
import { OptionType } from 'types/option-type';


export const getAdditionalInfo = async (opportunity: OpportunityEntity): Promise<{
  mainFields: OpportunityEntity,
  assotiatedFields: Partial<OpportunityAssociatedFieldsEntity>
}> => {
  let result: Partial<OpportunityAssociatedFieldsEntity> = {};


  const partyList = await partiesService.getList(
    `?page=1&filter[opportunity_id]=${opportunity.id}`
  );

  const opportunityServiceProviders =
    await opportunityServiceProvidersService.getList(
      `?sort[field]=date_entered&sort[direction]=asc&filter[opportunity_id]=${opportunity?.id}`
    );


  result = {
    ...result,
    listingAgent: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_listing_agent_id'),
    coListingAgent: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_co_listing_agent_id'),
    buyerRepresentative: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_buyer_representative_id'),
    escrow: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_escrow_company_id'),
    transactionCoordinator: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_transaction_coordinator_id'),
    tenant: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_tenant_id'),
    seller: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'seller_id'),
    sellerOffMarket: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_seller_id'),
    sellerRepresentative: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_seller_representative_id'),
    intendedBuyer: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_intended_buyer_id'),
    leadOwner: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_lead_owner_id'),
    leadSource: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_lead_source_id'),
    uploadedBy: partiesService.getOpportunityParty(opportunity, partyList.data.data, 'parties_uploaded_by_id'),
    feeAdvances: opportunityServiceProvidersService.getOpportunityServiceProviders(
      opportunityServiceProviders.data.data, 'fee_advance'
    ),
    expense: opportunityServiceProvidersService.getOpportunityServiceProviders(
      opportunityServiceProviders.data.data, 'expense'
    )
  }


  const brokerageUsers =
    await opportunityService.getOpportunityBrokerageUsers(opportunity.id);

  if (brokerageUsers.isSuccess && brokerageUsers.data.data.length > 0) {
    const users = userService.getUsers(brokerageUsers.data.data);
    result = { ...result, ...users };
  }

  const accountBuyers = await accountBuyerService.getOpportunityAccountBuyers(
    opportunity.id
  );

  if (accountBuyers && accountBuyers.length > 0) {
    const buyers = accountBuyerService.getBuyers(accountBuyers);

    result = { ...result, ...buyers };
  }

  if (
    isEmpty(opportunity?.commision_pending) &&
    !isEmpty(opportunity?.initial_commission_amount)
  ) {
    opportunity = {
      ...opportunity,
      commision_pending: opportunity?.initial_commission_amount
    };
  }

  if (
    isEmpty(opportunity?.buyer_commission_c) &&
    !isEmpty(opportunity?.initial_commission_percentage)
  ) {
    opportunity = {
      ...opportunity,
      buyer_commission_c: opportunity?.initial_commission_percentage
    };
  }

  if (
    !isEmpty(opportunity?.municipal_inspection_types) &&
    typeof opportunity?.municipal_inspection_types != 'string'
  ) {
    const municipal_inspection_types = getObjectEntriesAsArray(
      municipalInspectionTypes
    ).filter((x: OptionType) =>
      opportunity?.municipal_inspection_types.includes(x.value)
    );

    result = {
      ...result,
      municipal_inspection_types_edit: municipal_inspection_types,
      municipal_inspection_types_text_disply: municipal_inspection_types
        .map((x: OptionType) => x.label)
        .join(', ')
    };
  } else {
    opportunity = {
      ...opportunity,
      municipal_inspection_types: [],
    };

    result = {
      ...result,
      municipal_inspection_types_edit: [],
      municipal_inspection_types_text_disply: ''
    };
  }

  if (opportunity.data_source === 'off_market') {
    const opportunityCommission =
      await opportunityCommissionService.getList(
        `?page=1&per_page=100&sort[field]=entered_date&sort[direction]=desc&filter[opportunity_id]=${opportunity.id}`
      );

    if (opportunityCommission.data?.data[0]?.id) {
      const data = opportunityCommission.data.data[0];

      const commission = {
        opportunity_commission_id: data?.id,
        buyer_source: data.buyer_source || '',
        buyer_rep: data.buyer_rep || '',
        seller_source: data.seller_source || '',
        seller_rep: data.seller_rep || '',

        buyer_source_pct: data.buyer_source_pct || '',
        buyer_rep_pct: data.buyer_rep_pct || '',
        seller_source_pct: data.seller_source_pct || '',
        seller_rep_pct: data.seller_rep_pct || '',
        negotiator_commission_pct:
          data.negotiator_commission_pct || '',
        negotiator_commission_amount:
          data.negotiator_commission_amount || ''
      };

      result = {
        ...result,
        ...commission
      };
    } else {
      result = {
        ...result,
        opportunity_commission_id: ''
      };
    }
  }

  result = {
    ...result,
    reduction_type_display: !isEmpty(opportunity?.commision_reduction),
    commission_reduction_type2_display: !isEmpty(
      opportunity?.commision_reduction2
    ),
    commission_reduction_type3_display: !isEmpty(
      opportunity?.commision_reduction3
    )
  };


  // const response = { ...opportunity, ...result };

  return { mainFields: opportunity, assotiatedFields: result };
};

export const getValidatioFields = (data_source: string | null): string[] => {
  let fields = [
    'offer_price_c',
    'offer_date_c',
    'contract_execution_date_c',
    'earnest_money_due_date_c',
    'due_diligence_start_c',
    'due_diligence_end_c',
    'close_date_c',
    'option_period_days_c',
    'contract_termination_reasons',
    'contract_terminated_date',
    'reject_reason',
    'offer_submit_by_date',
    'short_sale_seller_accept_date',
    'cancel_reason',
    'restrictions_notes',
    'escrow_company_contact_c',
    'parties_listing_agent_name',
    'seller_name_c',
    'counter_offer',
    'earnest_amount_c',
    'sale_date',
    'sold_price',
    'earnest_recovered_by_buyer',
    'earnest_paid_by_entera',
    'em_recovery_amount',
    'em_paid_by_enatera_amount',
    'commision_pending',
    'contract_price_c',
    'deposit_amount',
    'initial_commission_amount',
    'lease_type',
    'rental_amount',
    'parties_listing_agent_name',
    'sale_date',
    'sold_price',
    'earnest_recovered_by_buyer',
    'earnest_paid_by_entera',
    'em_recovery_amount',
    'em_paid_by_enatera_amount',
    'buyer_commission_c',
    'tax_id_c',
    'type_c',
    'account_buyer_id',
    'cancellation_reason_subcategory',
    'termination_reason_subcategory',
    'purchase_price_c',
    'has_post_occupancy',
    'has_leasing_restrictions',
    'municipal_inspection_types_edit',
    'municipal_inspection_notes',
    'reduction_type',
    'commission_reduction_type2',
    'commission_reduction_type3',
    'active_primary_negotiator_user',
    'msa_id_1',
    'market_preference_id',
    'account_id_1',
    'forecasted_dd_end_date',
    'forecasted_close_date',
    'final_commission_percentage',
    'final_commission'
  ];

  if (data_source === 'off_market') {
    const off_market_fields = [
      'buyer_source',
      'buyer_rep',
      'seller_source',
      'seller_rep',
      'parties_seller_representative_type',
      'parties_lead_source_type',
      'parties_intended_buyer_type',
      'parties_uploaded_by_type'
    ];
    fields = [...fields, ...off_market_fields];
  }

  return fields;
};

export const validateOpportunity = (
  opportunity: OpportunityViewEntity,
  oldOppurtunity: OpportunityViewEntity
) => {
  const fieldsToValidate = getValidatioFields(opportunity?.data_source);

  const result = validationService.validate(
    opportunity,
    fieldsToValidate,
    'edit',
    oldOppurtunity
  );

  return result;
};

export const transformOpportunity = (opportunity: ObjectType) => {
  let result = {};

  const convertTimeZoneToUtc = () => {
    const dateTimeArr = [
      'offer_initialized_at',
      'offer_finalized_at',
      'due_diligence_start_c',
      'due_diligence_end_c',
      'list_price_updated_date_c',
      'mls_updated_timestamp_c',
      'initial_due_diligence_end',
      'offer_documents_created_at',
      'benefitting_negotiator_changed_at'
    ];

    const dateTimeResult: ObjectType = {};
    for (const dateTimeValue of dateTimeArr) {
      if (opportunity[dateTimeValue] && opportunity[dateTimeValue] != '') {
        dateTimeResult[dateTimeValue] = DateUtility.convertTimeZoneToUTC(
          opportunity[dateTimeValue]
        );
      } else {
        dateTimeResult[dateTimeValue] = opportunity[dateTimeValue];
      }
    }

    return dateTimeResult;
  };

  result = {
    ...opportunity,
    ...convertTimeZoneToUtc(),
    municipal_inspection_types: opportunity.municipal_inspection_types.map(
      (x: OptionType) => x.value
    )
  };

  return result;
};

export const saveOpportunityCommission = async (data: OpportunityViewEntity) => {
  const reqBody = {
    name: data.name,
    description: data.description,
    opportunity_id: data.id,

    buyer_source: data.buyer_source || '',
    buyer_rep: data.buyer_rep || '',
    seller_source: data.seller_source || '',
    seller_rep: data.seller_rep || '',
    buyer_source_pct: buyer_source_pct.calculate(data).toString(),
    buyer_rep_pct: buyer_rep_pct.calculate(data).toString(),
    seller_source_pct: seller_source_pct.calculate(data).toString(),
    seller_rep_pct: seller_rep_pct.calculate(data).toString(),
    negotiator_commission_pct: negotiator_commission_pct.calculate(
      data
    ).toString(),
    negotiator_commission_amount:
      negotiator_commission_amount.calculate(data).toString()
  };

  if (!isEmpty(data.opportunity_commission_id)) {
    await opportunityCommissionService.updateCommission(
      data.opportunity_commission_id,
      reqBody
    );
  } else {
    await opportunityCommissionService.createCommission(reqBody);
  }
};
