import { due_diligence_end_c } from './due_diligence_end_c';
import { isEmpty } from 'helpers/misc-helper';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import fieldLabel from 'assets/constants/fieldLabel';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import variableConfig from 'config/variable';
import DateUtility from 'helpers/date-helper';
import federalHolidayHelper from 'helpers/federal-holiday-helper';
import opportunityHelper from 'helpers/opportunity-helper';

export const contract_execution_date_c = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.closing_diligence_period
    ) {
      if (isEmpty(opportunity?.contract_execution_date_c)) {
        errors.push(missingField(fieldLabel.contractExecutionDate));
      }
    }

    return errors;
  },

  handleChange: (
    opportunity: OpportunityViewEntity
  ) => {
    let ddEndDate = due_diligence_end_c.calculate(opportunity);

    const { validDate, message } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHolidayAndGetMessage(
        ddEndDate,
        fieldLabel.dueDiligenceEnd,
        opportunityHelper.isOptionDayTypeIsBusinessOrCalendarDaysNoWeekendClosings(
          opportunity?.option_days_type_c
        )
      );

    ddEndDate = DateUtility.addHourInDate(
      validDate,
      variableConfig.DUE_DILIGENCE_END_START_TIME
    );

    const result = {
      due_diligence_end_c: ddEndDate,
      due_diligence_end_c_federal_holiday_validation_message: message,
      ...due_diligence_end_c.handleChange({
        ...opportunity,
        due_diligence_end_c: ddEndDate
      })
    };

    return result;
  }
};
