import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { buyer_rep_pct } from './commission-buyer-rep-pct';

export const buyer_rep = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.offer_sent_to_seller &&
      opportunity?.data_source === 'off_market' &&
      opportunity?.sellerOffMarket.sub_type !== 'ibuyer' &&
      opportunity?.leadSource.sub_type !== 'entera_client' &&
      (!opportunity?.buyer_rep ||
        opportunity?.buyer_rep.trim().length === 0)
    ) {
      errors.push(missingField('Did You Represent the Buyer?'));
    }

    return errors;
  },
  calculate: function (
    opportunity: OpportunityViewEntity,
  ) {
    if (
      opportunity.parties_lead_owner_id ==
      opportunity.parties_buyer_representative_id
    ) {
      return 'yes';
    }
    return 'no';
  },
  handleChange: function (
    opportunity: OpportunityViewEntity,
  ) {
    const result = {
      buyer_rep_pct: buyer_rep_pct.calculate(
        opportunity
      )
    };

    return result;
  }
};
