import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';

export const earnest_recovery_amount = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      isEmpty(opportunity?.earnest_recovery_amount) &&
      opportunity?.earnest_recovered_by_buyer === 'yes'
    ) {
      errors.push(missingField('EM Recovery Amount'));
    }

    return errors;
  }
};
