import { access_requested_by } from './access-requested-by';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const access_requested_date = {
  handleChange: (opportunity: OpportunityViewEntity, originalOpportunity: OpportunityViewEntity) => {
    const result = {
      ...access_requested_by.calculate(
        opportunity,
        originalOpportunity
      )
    };

    return result;
  }
};
