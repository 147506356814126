import { isEmpty, isValueChanged } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const access_requested_by = {
  calculate: (opportunity: OpportunityViewEntity, originalOpportunity: OpportunityViewEntity): Partial<OpportunityViewEntity> => {
    if (
      !isEmpty(opportunity.access_requested_date) &&
      isValueChanged(opportunity, originalOpportunity, 'access_requested_date')
    ) {
      const currentUser = JSON.parse(localStorage.getItem('user') || 'false');
      return {
        access_requested_by: currentUser.user.id,
        accessRequestedBy: currentUser.user
      };
    }
    return {
      access_requested_by: opportunity.access_requested_by,
      accessRequestedBy: opportunity.accessRequestedBy
    };
  }
};
