import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {  IconButton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import React from 'react';

import Tooltip from './index';

const TooltipClickable = ({ data }: { data: string }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        placement="top"
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={data}
      >
        <IconButton onClick={handleTooltipOpen}>
          {open ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TooltipClickable;
