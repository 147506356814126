import fieldLabel from 'assets/constants/fieldLabel';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';

export const counter_offer = {
  validate: function (opportunity: OpportunityViewEntity, status: string) {
    const errors: string[] = [];

    if (status === "edit") {
      if (
        opportunity?.opportunity_status_c ===
        oppurtunityStatusList.offer_seller_countered &&
        isEmpty(opportunity?.counter_offer)
      ) {
        errors.push(missingField(fieldLabel.counterOffer));
      }
    }


    if (status === "action") {
      const statusList = [oppurtunityStatusList.offer_seller_countered, oppurtunityStatusList.Offer_counter_updated];
      if (statusList.includes(opportunity?.opportunity_status_c) && isEmpty(opportunity?.counter_offer)) {
        errors.push(missingField(fieldLabel.counterOffer));
      }
    }


    return errors;
  }
};
