import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const entera_contribution_amount = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.entera_contribution === 'yes' &&
      isEmpty(opportunity?.entera_contribution_amount)
    ) {
      errors.push(missingField('Entera Contribution Amount'));
    }

    return errors;
  }
};
