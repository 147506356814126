import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';

export const type_c = {
  validate: (
    opportunity: OpportunityViewEntity,
  ) => {
    const errors: string[] = [];

    if (isEmpty(opportunity?.type_c)) {
      errors.push(missingField('Type'));
    }
    return errors;
  }
};
