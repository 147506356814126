import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const buyer_contract_name_c = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (isEmpty(opportunity?.buyer_contract_name_c)) {
      errors.push(missingField('Contarct Buyer Name'));
    }

    return errors;
  }
};
