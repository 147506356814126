import { OpportunityViewEntity } from "types/opportunity-types";

export const offer_submit_by_date = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.offer_submit_by_date &&
      new Date(opportunity?.offer_submit_by_date).getTime() <=
      new Date().getTime()
    ) {
      errors.push('Offer Submit By date must be in future.');
    }

    return errors;
  }
};
