import { opportunityStatusEnv } from 'assets/constants/opportunity-status-env';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';
import moment from 'moment';

export const escrow_company_name_c = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    const stateList = [
      oppurtunityStatusList.offer_accepted,
      oppurtunityStatusList.closing_diligence_amended,
      oppurtunityStatusList.closing_sale_pending,
      oppurtunityStatusList.closing_sale_pending_amended,
      oppurtunityStatusList.closing_clear_to_close
    ];

    if (
      stateList.includes(opportunity?.opportunity_status_c) &&
      isEmpty(opportunity?.escrow.company)
    ) {
      errors.push(missingField('Escrow Company Name'));
    }

    const user = JSON.parse(localStorage.getItem('user') || '');
    if (
      !isEmpty(user?.user?.status_restriction_offer_date) &&
      !isEmpty(opportunity?.offer_finalized_at)
    ) {
      const offerFinalizeDate = moment(opportunity?.offer_finalized_at).unix();
      const statusRestrictionDate = moment(
        user.user.status_restriction_offer_date
      ).unix();

      if (
        offerFinalizeDate > statusRestrictionDate &&
        !isEmpty(opportunityStatusEnv[opportunity?.opportunity_status_c]) &&
        isEmpty(opportunity.escrow.name)
      ) {
        errors.push(missingField(`Escrow Company Name`));
      }
    }
    return errors;
  }
};
