import { OpportunityAssociatedFieldsEntity, OpportunityViewEntity } from 'types/opportunity-types';
import { initialOpportunity } from './initial-opportunity';
import initialPartyList from 'state/party/initial-party-list';
import initialOpportuityServiceProvider from 'state/opportunity-service-provider/initial-opportunity-service-provider';
import { initialBrokerageUser } from 'state/opportunity-brokerage-user/initial-brokerage-user';

export const initialAssocciatedFieds: OpportunityAssociatedFieldsEntity = {
    submitting_user_name: '',
    behalf_of_user_name: '',
    listingAgent: initialPartyList[0],
    coListingAgent: initialPartyList[0],
    buyerRepresentative: initialPartyList[0],
    escrow: initialPartyList[0],
    tenant: initialPartyList[0],
    seller: initialPartyList[0],
    sellerOffMarket: initialPartyList[0],
    sellerRepresentative: initialPartyList[0],
    intendedBuyer: initialPartyList[0],
    leadOwner: initialPartyList[0],
    transactionCoordinator: initialPartyList[0],
    leadSource: initialPartyList[0],
    uploadedBy: initialPartyList[0],
    feeAdvances: [initialOpportuityServiceProvider],
    expense: [initialOpportuityServiceProvider],
    benefittingNegotiator: initialBrokerageUser,
    benefittingNegotiatorChangedBy: initialBrokerageUser,
    offerSubmittedBy: initialBrokerageUser,
    saveForSingnatureBy: initialBrokerageUser,
    congratsLetterSentBy: initialBrokerageUser,
    accessRequestedBy: initialBrokerageUser,
    activePrimaryNegotiatorUser: initialBrokerageUser,
    leaseback3SentBy: initialBrokerageUser,
    leaseback1SentBy: initialBrokerageUser,
    submittingUserCode: initialBrokerageUser,
    negoAtOfferSent: initialBrokerageUser,
    negoAtOfferAccept: initialBrokerageUser,
    negoAtOfferClosing: initialBrokerageUser,
    primaryTc: initialBrokerageUser,
    primaryTcAtDiligence: initialBrokerageUser,
    primaryTcAtSalePending: initialBrokerageUser,
    primaryTcAtClosing: initialBrokerageUser,
    primaryLogistics: initialBrokerageUser,
    primaryLogisticsAtDiligence: initialBrokerageUser,
    primaryLogisticsAtSalePending: initialBrokerageUser,
    primaryLogisticsAtClosing: initialBrokerageUser,
    buyerContractNameAtDiligenceStart: '',
    buyerContractNameAtOfferSent: '',
    municipal_inspection_types_edit: [],
    municipal_inspection_types_text_disply: '',
    opportunity_commission_id: '',

    buyer_source: '',
    buyer_rep: '',
    seller_source: '',
    seller_rep: '',
    buyer_source_pct: '',
    buyer_rep_pct: '',
    seller_source_pct: '',
    seller_rep_pct: '',
    negotiator_commission_pct: '',
    negotiator_commission_amount: '',
    reduction_type_display: false,
    commission_reduction_type2_display: false,
    commission_reduction_type3_display: false,

    inspectionConfirmationBy: initialBrokerageUser,
    final_commission_changed: false,
    hud_commission_changed: false,
    initial_commission_amount_changed: false,
    initial_commission_percentage_changed: false,
    accountBuyerName: '',
    seller_name_c: ''
}

export const initialViewOpportunity: OpportunityViewEntity = {
    ...initialOpportunity,
    accountBuyerName: '',
    submitting_user_name: '',
    behalf_of_user_name: '',
    listingAgent: initialPartyList[0],
    coListingAgent: initialPartyList[0],
    buyerRepresentative: initialPartyList[0],
    escrow: initialPartyList[0],
    tenant: initialPartyList[0],
    seller: initialPartyList[0],
    sellerOffMarket: initialPartyList[0],
    sellerRepresentative: initialPartyList[0],
    intendedBuyer: initialPartyList[0],
    leadOwner: initialPartyList[0],
    transactionCoordinator: initialPartyList[0],
    leadSource: initialPartyList[0],
    uploadedBy: initialPartyList[0],
    feeAdvances: [initialOpportuityServiceProvider],
    expense: [initialOpportuityServiceProvider],
    benefittingNegotiator: initialBrokerageUser,
    benefittingNegotiatorChangedBy: initialBrokerageUser,
    offerSubmittedBy: initialBrokerageUser,
    saveForSingnatureBy: initialBrokerageUser,
    congratsLetterSentBy: initialBrokerageUser,
    accessRequestedBy: initialBrokerageUser,
    activePrimaryNegotiatorUser: initialBrokerageUser,
    leaseback3SentBy: initialBrokerageUser,
    leaseback1SentBy: initialBrokerageUser,
    submittingUserCode: initialBrokerageUser,
    negoAtOfferSent: initialBrokerageUser,
    negoAtOfferAccept: initialBrokerageUser,
    negoAtOfferClosing: initialBrokerageUser,
    primaryTc: initialBrokerageUser,
    primaryTcAtDiligence: initialBrokerageUser,
    primaryTcAtSalePending: initialBrokerageUser,
    primaryTcAtClosing: initialBrokerageUser,
    primaryLogistics: initialBrokerageUser,
    primaryLogisticsAtDiligence: initialBrokerageUser,
    primaryLogisticsAtSalePending: initialBrokerageUser,
    primaryLogisticsAtClosing: initialBrokerageUser,

    buyerContractNameAtOfferSent: '',
    buyerContractNameAtDiligenceStart: '',
    municipal_inspection_types_edit: [],
    municipal_inspection_types_text_disply: '',
    opportunity_commission_id: '',

    buyer_source: '',
    buyer_rep: '',
    seller_source: '',
    seller_rep: '',
    buyer_source_pct: '',
    buyer_rep_pct: '',
    seller_source_pct: '',
    seller_rep_pct: '',
    negotiator_commission_pct: '',
    negotiator_commission_amount: '',
    reduction_type_display: false,
    commission_reduction_type2_display: false,
    commission_reduction_type3_display: false,

    inspectionConfirmationBy: initialBrokerageUser,
    final_commission_changed: false,
    hud_commission_changed: false,
    initial_commission_amount_changed: false,
    initial_commission_percentage_changed: false,
    seller_name_c: ''
}