import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ErrorValidation } from 'types/error-types';
import {
  ForgotPassword,
  ForgotResetPassword
} from 'types/forgot-password-type';

export const validateLogin = (
  data: ForgotPassword
): {
  status: boolean;
  errors: ErrorValidation;
} => {
  let validation = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.user_name)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        user_name: [missingField('User Name')]
      }
    };
  }

  return validation;
};

export const validateForgotReset = (
  data: ForgotResetPassword
): {
  status: boolean;
  errors: ErrorValidation;
} => {
  let validation = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.password)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        password: [missingField('Password')]
      }
    };
  }

  if (isEmpty(data.confirm_password)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        confirm_password: [missingField('Confirm Password')]
      }
    };
  }

  if (data.confirm_password !== data.password) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        confirm_password: [
          missingField('Your password and confirmation password do not match.')
        ]
      }
    };
  }

  return validation;
};
