import { isEmpty, isValueChanged } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const congrats_letter_sent_by = {
  calculate: (opportunity: OpportunityViewEntity, originalOpportunity: OpportunityViewEntity): Partial<OpportunityViewEntity> => {
    if (
      !isEmpty(opportunity.congrats_letter_sent_date) &&
      isValueChanged(
        opportunity,
        originalOpportunity,
        'congrats_letter_sent_date'
      )
    ) {
      const user = JSON.parse(localStorage.getItem('user') || '');
      return {
        congrats_letter_sent_by: user?.user?.id,
        congratsLetterSentBy: user?.user
      };
    }
    return {
      congrats_letter_sent_by: opportunity.congrats_letter_sent_by,
      congratsLetterSentBy: opportunity.congratsLetterSentBy
    };
  }
};
