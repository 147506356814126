import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { convertNumber, isEmpty } from 'helpers/misc-helper';
import { balance_to_close_c } from './balance_to_close_c';


export const earnest_amount_c = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.closing_diligence_period &&
      isEmpty(opportunity?.earnest_amount_c)
    ) {
      errors.push(missingField('Earnest Amount'));
    }

    return errors;
  },
  handleChange: (opportunity: OpportunityViewEntity) => {
    const result = {
      balance_to_close_c: balance_to_close_c.calculate(opportunity),
      earnest_amount_c: Math.round(convertNumber(opportunity.earnest_amount_c))
    };

    return result;
  }
};
