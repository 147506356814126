import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';

export const active_primary_negotiator_user = {
  validate: (
    opportunity: OpportunityViewEntity,
  ) => {
    const errors: string[] = [];


    if (isEmpty(opportunity.active_primary_negotiator_user_id)) {
      errors.push(missingField('Primary Negotiator'));
    }

    return errors;
  }
};
