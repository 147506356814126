
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isChecked } from 'components/form/unit-switch';
import { isEmpty } from 'helpers/misc-helper';


export const tax_id_c = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (status == "edit") {
      const stateList = [
        oppurtunityStatusList.offer_accepted,
        oppurtunityStatusList.closing_diligence_amended,
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended,
        oppurtunityStatusList.closing_clear_to_close
      ];


      const isDirty = stateList.includes(opportunity?.opportunity_status_c) &&
        isChecked(opportunity?.new_construction_no_tax_id) == false &&
        !isEmpty(opportunity?.offer_finalized_at) &&
        isEmpty(opportunity.tax_id_c)

      if (isDirty) {
        errors.push(missingField(`Tax ID`));
      }
    } else {

      if (
        isChecked(opportunity?.new_construction_no_tax_id) == false &&
        isEmpty(opportunity.tax_id_c)
      ) {
        errors.push(missingField(`Tax ID`));
      }
    }

    return errors;
  }
};
