import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const restrictions_notes = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.has_leasing_restrictions === 'yes' &&
      (!opportunity?.restrictions_notes ||
        opportunity?.restrictions_notes.trim().length === 0)
    ) {
      errors.push(missingField('Restrictions Notes'));
    }

    return errors;
  }
};
