import { ObjectType } from 'types';
import { convertNumber, isEmpty } from './misc-helper';
import { commision_pending } from 'services/validation/commision_pending';
import { buyer_commission_c } from 'services/validation/buyer_commission_c';
import { final_commission } from 'services/validation/final_commission';
import { final_commission_percentage } from 'services/validation/final_commission_percentage';
import { hud_commission } from 'services/validation/hud_commission';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import opportunityStatus from 'assets/constants/opportunity-status';
import reasonDescription from 'assets/constants/reason-description';
import DateUtility from './date-helper';
import userFullName from './user-name-helper';
import { SettlementCommissionCalculation, NoteEntity, OpportunityEntity, OpportunityPayload, OpportunityViewEntity } from 'types/opportunity-types';
import { OptionType } from 'types/option-type';

const opportunityHelper = {
  getCurrentClosingPrice: (opportunity: SettlementCommissionCalculation): number => {
    let priceForCalculation = 0;

    if (convertNumber(opportunity?.purchase_price_c) > 0) {
      priceForCalculation = convertNumber(opportunity?.purchase_price_c);
    } else if (convertNumber(opportunity?.contract_price_c) > 0) {
      priceForCalculation = convertNumber(opportunity?.contract_price_c);
    } else if (convertNumber(opportunity?.initial_offer_price_c) > 0) {
      priceForCalculation = convertNumber(opportunity?.initial_offer_price_c);
    }

    return priceForCalculation;
  },

  calculateCommission: (opportunity: SettlementCommissionCalculation) => {
    const commissionPending = commision_pending.calculate(opportunity);

    opportunity = {
      ...opportunity,
      commision_pending: commissionPending
    };

    const pendingCommission = buyer_commission_c.calculate(opportunity);

    opportunity = {
      ...opportunity,
      buyer_commission_c: convertNumber(pendingCommission)
    };

    let result: ObjectType = {
      commision_pending: commissionPending,
      buyer_commission_c: pendingCommission
    };

    if (
      [
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended,
        oppurtunityStatusList.closing_clear_to_close,
        oppurtunityStatusList.closed_purchased,
        oppurtunityStatusList.closing_termination_pending
      ].includes(opportunity?.opportunity_status_c)
    ) {
      const finalCommission = final_commission.calculate(opportunity);
      opportunity = {
        ...opportunity,
        final_commission: finalCommission
      };

      const finalCommissionPercentage = final_commission_percentage.calculate(
        opportunity
      );
      opportunity = {
        ...opportunity,
        final_commission_percentage: finalCommissionPercentage
      };

      const hudCommission = hud_commission.calculate(opportunity);
      opportunity = {
        ...opportunity,
        hud_commission: hudCommission?.toString()
      };

      result = {
        ...result,
        final_commission: finalCommission,
        final_commission_percentage: finalCommissionPercentage,
        hud_commission: hudCommission
      };
    }

    return result;
  },

  getTransactionNoteStaring: (note: NoteEntity) => {
    return `
      ${!isEmpty(opportunityStatus[note.opportunity_status])
        ? opportunityStatus[note.opportunity_status] + ' - '
        : ''
      }${!isEmpty(reasonDescription[note.reason_description])
        ? reasonDescription[note.reason_description] + ' - '
        : ''
      }${note.name} - by ${userFullName(
        note.get_created_by
      )} at ${DateUtility.getDateTimeString(
        DateUtility.convertUTCtoTimeZone(note.date_entered)
      )}
    `;
  },

  isOptionDayTypeIsBusinessOrCalendarDaysNoWeekendClosings: (value: string) => {
    return ['business', 'calendar_days_no_weekend_closings'].includes(value);
  },

  getCommissionPayload: (oppportunity: OpportunityViewEntity | OpportunityEntity): SettlementCommissionCalculation => {
    return {
      opportunity_status_c: oppportunity.opportunity_status_c,
      initial_commission_amount: oppportunity.initial_commission_amount,
      initial_commission_percentage: oppportunity.initial_commission_percentage,
      initial_offer_price_c: oppportunity.initial_offer_price_c,
      commision_pending: oppportunity.commision_pending,
      buyer_commission_c: oppportunity.buyer_commission_c,
      final_commission: oppportunity.final_commission,
      final_commission_percentage: oppportunity.final_commission_percentage,
      hud_commission: oppportunity.hud_commission,
      total_commission_reductions: oppportunity.total_commission_reductions,
      commision_reduction: oppportunity.commision_reduction,
      commision_reduction2: oppportunity.commision_reduction2,
      commision_reduction3: oppportunity.commision_reduction3,
      reduction_type: oppportunity.reduction_type,
      commission_reduction_type2: oppportunity.commission_reduction_type2,
      commission_reduction_type3: oppportunity.commission_reduction_type3,
      contract_price_c: oppportunity.contract_price_c,
      purchase_price_c: oppportunity.purchase_price_c,
      buyer_bonus_c: oppportunity.buyer_bonus_c,
      initial_commission_amount_changed: oppportunity.initial_commission_amount_changed,
      final_commission_changed: oppportunity.final_commission_changed,
      hud_commission_changed: oppportunity.hud_commission_changed
    }
  },

  preparePayload: (opportunity: OpportunityViewEntity): OpportunityPayload => {
    return {
      id: opportunity.id,
      name: opportunity.name,
      date_entered: opportunity.date_entered,
      date_modified: opportunity.date_modified,
      modified_user_id: opportunity.modified_user_id,
      created_by: opportunity.created_by,
      description: opportunity.description,
      deleted: opportunity.deleted,
      assigned_user_id: opportunity.assigned_user_id,
      opportunity_type: opportunity.opportunity_type,
      campaign_id: opportunity.campaign_id,
      lead_source: opportunity.lead_source,
      amount: opportunity.amount,
      amount_usdollar: opportunity.amount_usdollar,
      currency_id: opportunity.currency_id,
      date_closed: opportunity.date_closed,
      next_step: opportunity.next_step,
      sales_stage: opportunity.sales_stage,
      probability: opportunity.probability,
      cancel_reason: opportunity.cancel_reason,
      reject_reason: opportunity.reject_reason,
      documentation_delivered: opportunity.documentation_delivered,
      emd_requested_confirmed: opportunity.emd_requested_confirmed,
      approved_dd_release: opportunity.approved_dd_release,
      counter_offer: opportunity.counter_offer,
      sale_avm_actual: opportunity.sale_avm_actual,
      rent_avm_actual: opportunity.rent_avm_actual,
      sold_price: opportunity.sold_price,
      sale_date: opportunity.sale_date,
      short_sale_seller_accept_date: opportunity.short_sale_seller_accept_date,
      roof_type: opportunity.roof_type,
      edit_offer_version: opportunity.edit_offer_version,
      qb_invoice_no: opportunity.qb_invoice_no,
      heating_system: opportunity.heating_system,
      cooling_system: opportunity.cooling_system,
      property_access_contact_phone: opportunity.property_access_contact_phone,
      property_access_contact_name: opportunity.property_access_contact_name,
      property_access_contact_email: opportunity.property_access_contact_email,
      property_access_comments: opportunity.property_access_comments,
      offer_submit_by_date: opportunity.offer_submit_by_date,
      offer_submit_type: opportunity.offer_submit_type,
      bid_id: opportunity.bid_id,
      bid_url: opportunity.bid_url,
      rep_name: opportunity.rep_name,
      rep_email: opportunity.rep_email,
      rep_phone: opportunity.rep_phone,
      rep_company: opportunity.rep_company,
      commision_pending: opportunity.commision_pending,
      commision_reduction: opportunity.commision_reduction,
      final_commission: opportunity.final_commission,
      commission_pay_type: opportunity.commission_pay_type,
      contract_terminated_date: opportunity.contract_terminated_date,
      visit_evaluator_type: opportunity.visit_evaluator_type,
      evaluator_date: opportunity.evaluator_date,
      visit_date: opportunity.visit_date,
      visit_earliest_time: opportunity.visit_earliest_time,
      visit_latest_time: opportunity.visit_latest_time,
      evaluator_comments: opportunity.evaluator_comments,
      visit_comments: opportunity.visit_comments,
      inspect_date: opportunity.inspect_date,
      inspect_comments: opportunity.inspect_comments,
      full_address: opportunity.full_address,
      simba_unique_id: opportunity.simba_unique_id,
      mo_photos_required: opportunity.mo_photos_required,
      mo_photos_date: opportunity.mo_photos_date,
      mo_photos_source: opportunity.mo_photos_source,
      product_type: opportunity.product_type,
      net_offer: opportunity.net_offer,
      is_sqs_opendoor: opportunity.is_sqs_opendoor,
      reject_date: opportunity.reject_date,
      offer_lost_date: opportunity.offer_lost_date,
      initial_commission_percentage: opportunity.initial_commission_percentage,
      final_commission_percentage: opportunity.final_commission_percentage,
      initial_commission_amount: opportunity.initial_commission_amount,
      cda_amount_2: opportunity.cda_amount_2,
      cda_amount_3: opportunity.cda_amount_3,
      cda_pay_to_2: opportunity.cda_pay_to_2,
      cda_pay_to_3: opportunity.cda_pay_to_3,
      visit_agree: opportunity.visit_agree,
      offer_initialized_at: DateUtility.convertTimeZoneToUTC(opportunity.offer_initialized_at),
      offer_finalized_at: DateUtility.convertTimeZoneToUTC(opportunity.offer_finalized_at),
      entera_opportunity_id: opportunity.entera_opportunity_id,
      escrow_percent: opportunity.escrow_percent,
      entera_customer_id: opportunity.entera_customer_id,
      closing_attorney: opportunity.closing_attorney,
      closing_attorney_contract: opportunity.closing_attorney_contract,
      closing_attorney_email: opportunity.closing_attorney_email,
      closing_attorney_phone: opportunity.closing_attorney_phone,
      closing_attorney_address: opportunity.closing_attorney_address,
      msa_id_1: opportunity.msa_id_1,
      primary_tr_id: opportunity.primary_tr_id,
      manager_id: opportunity.manager_id,
      account_id_1: opportunity.account_id_1,
      is_sqs_price_status: opportunity.is_sqs_price_status,
      letter_of_intent: opportunity.letter_of_intent,
      save_for_singnature_by: opportunity.save_for_singnature_by,
      offer_submitted_by: opportunity.offer_submitted_by,
      congrats_letter_sent_by: opportunity.congrats_letter_sent_by,
      congrats_letter_sent_date: opportunity.congrats_letter_sent_date,
      access_requested_by: opportunity.access_requested_by,
      access_requested_date: opportunity.access_requested_date,
      school_district: opportunity.school_district,
      reduction_type: opportunity.reduction_type,
      commision_reduction2: opportunity.commision_reduction2,
      commision_reduction3: opportunity.commision_reduction3,
      hud_commission: opportunity.hud_commission,
      commissions_flags: opportunity.commissions_flags,
      commission_reduction_type2: opportunity.commission_reduction_type2,
      commission_reduction_type3: opportunity.commission_reduction_type3,
      total_commission_reductions: opportunity.total_commission_reductions,
      file_location: opportunity.file_location,
      addendum_no: opportunity.addendum_no,
      addendum_notes: opportunity.addendum_notes,
      restrictions_notes: opportunity.restrictions_notes,
      has_leasing_restrictions: opportunity.has_leasing_restrictions,
      offer_documents_status: opportunity.offer_documents_status,
      commission_received: opportunity.commission_received,
      offer_documents_created_at: DateUtility.convertTimeZoneToUTC(opportunity.offer_documents_created_at),
      escalation_clause_amount: opportunity.escalation_clause_amount,
      customer_reservation_status: opportunity.customer_reservation_status,
      contract_termination_reasons: opportunity.contract_termination_reasons,
      customer_accepted_status_verified: opportunity.customer_accepted_status_verified,
      parties_listing_agent_id: opportunity.parties_listing_agent_id,
      seller_offer_response: opportunity.seller_offer_response,
      next_seller_contact_date: opportunity.next_seller_contact_date,
      offer_price_at_acceptance: opportunity.offer_price_at_acceptance,
      parties_co_listing_agent_id: opportunity.parties_co_listing_agent_id,
      inclusion: opportunity.inclusion,
      exclusion: opportunity.exclusion,
      internal_termination_feedback: opportunity.internal_termination_feedback,
      hoa_name: opportunity.hoa_name,
      roof_age: opportunity.roof_age,
      hoa_url: opportunity.hoa_url,
      hvac_age: opportunity.hvac_age,
      submitting_user_code: opportunity.submitting_user_code,
      behalf_of_user_code: opportunity.behalf_of_user_code,
      market_preference_id: opportunity.market_preference_id,
      rental_amount: opportunity.rental_amount,
      lease_type: opportunity.lease_type,
      deposit_amount: opportunity.deposit_amount,
      lease_end_date: opportunity.lease_end_date,
      nego_at_offer_accept: opportunity.nego_at_offer_accept,
      nego_at_closing: opportunity.nego_at_closing,
      nego_at_offer_sent: opportunity.nego_at_offer_sent,
      special_instructions: opportunity.special_instructions,
      license_supervisor_license_number: opportunity.license_supervisor_license_number,
      licensed_supervisor_of_listing_associate: opportunity.licensed_supervisor_of_listing_associate,
      additional_cancellation_reason: opportunity.additional_cancellation_reason,
      original_close_of_escrow_date: opportunity.original_close_of_escrow_date,
      seller_ownership_representation: opportunity.seller_ownership_representation,
      rpd_received: opportunity.rpd_received,
      mog_received: opportunity.mog_received,
      water_and_sewer: opportunity.water_and_sewer,
      offer_cancelled_date: opportunity.offer_cancelled_date,
      sellers_disclosure_received: opportunity.sellers_disclosure_received,
      settlement_date: opportunity.settlement_date,
      foundation_type: opportunity.foundation_type,
      no_diligence_period: opportunity.no_diligence_period,
      key_location: opportunity.key_location,
      tenant_phone: opportunity.tenant_phone,
      tenant_email: opportunity.tenant_email,
      tenant_name: opportunity.tenant_name,
      parties_tenant_id: opportunity.parties_tenant_id,
      parties_transaction_coordinator_id: opportunity.parties_transaction_coordinator_id,
      is_inspection_contingencies_waived: opportunity.is_inspection_contingencies_waived,
      disable_inspection_emails_for_waived_inspections: opportunity.disable_inspection_emails_for_waived_inspections,
      entera_sourced_off_market: opportunity.entera_sourced_off_market,
      commission_contribution_at_offer_accept: opportunity.commission_contribution_at_offer_accept,
      contract_review_complete: opportunity.contract_review_complete,
      active_primary_negotiator_user_id: opportunity.active_primary_negotiator_user_id,
      street_name: opportunity.street_name,
      has_post_occupancy: opportunity.has_post_occupancy,
      pre_close_notification_to_recipient: opportunity.pre_close_notification_to_recipient,
      pre_close_notification_cc_recipient: opportunity.pre_close_notification_cc_recipient,
      broker_rep_firm: opportunity.broker_rep_firm,
      broker_rep_firm_license_number: opportunity.broker_rep_firm_license_number,
      broker_rep_mlsid: opportunity.broker_rep_mlsid,
      broker_rep_agent_license_number: opportunity.broker_rep_agent_license_number,
      broker_rep_agent_email: opportunity.broker_rep_agent_email,
      executed_contract_email_to: opportunity.executed_contract_email_to,
      executed_contract_email_cc: opportunity.executed_contract_email_cc,
      executed_contract_email_bcc: opportunity.executed_contract_email_bcc,
      buyer_assignability: opportunity.buyer_assignability,
      mo_photos_link: opportunity.mo_photos_link,
      access_notes: opportunity.access_notes,
      primary_tc_at_closing: opportunity.primary_tc_at_closing,
      primary_logistics_at_closing: opportunity.primary_logistics_at_closing,
      primary_tc_at_diligence: opportunity.primary_tc_at_diligence,
      primary_tc_at_sale_pending: opportunity.primary_tc_at_sale_pending,
      primary_logistics_at_diligence: opportunity.primary_logistics_at_diligence,
      primary_logistics_at_sale_pending: opportunity.primary_logistics_at_sale_pending,
      backwards_status_move_reason: opportunity.backwards_status_move_reason,
      parties_seller_id: opportunity.parties_seller_id,
      parties_seller_representative_id: opportunity.parties_seller_representative_id,
      parties_lead_source_id: opportunity.parties_lead_source_id,
      parties_lead_owner_id: opportunity.parties_lead_owner_id,
      parties_buyer_representative_id: opportunity.parties_buyer_representative_id,
      parties_intended_buyer_id: opportunity.parties_intended_buyer_id,
      parties_uploaded_by_id: opportunity.parties_uploaded_by_id,
      earnest_recovered_by_buyer: opportunity.earnest_recovered_by_buyer,
      earnest_recovery_amount: opportunity.earnest_recovery_amount,
      earnest_paid_by_entera: opportunity.earnest_paid_by_entera,
      earnest_paid_by_entera_amount: opportunity.earnest_paid_by_entera_amount,
      new_construction_no_tax_id: opportunity.new_construction_no_tax_id,
      municipal_inspection_types: opportunity.municipal_inspection_types_edit.map(
        (x: OptionType) => x.value
      ),
      municipal_inspection_notes: opportunity.municipal_inspection_notes,
      mailbox_location: opportunity.mailbox_location,
      reject_note: opportunity.reject_note,
      competing_offer_count: opportunity.competing_offer_count,
      competing_offer_highest_price: opportunity.competing_offer_highest_price,
      buyer_offer_key: opportunity.buyer_offer_key,
      cancel_note: opportunity.cancel_note,
      cancel_request_received_date: opportunity.cancel_request_received_date,
      leaseback_3_sent_at: opportunity.leaseback_3_sent_at,
      leaseback_1_sent_at: opportunity.leaseback_1_sent_at,
      leaseback_3_sent_by: opportunity.leaseback_3_sent_by,
      leaseback_1_sent_by: opportunity.leaseback_1_sent_by,
      opportunity_counteroffer_id: opportunity.opportunity_counteroffer_id,
      primary_tc: opportunity.primary_tc,
      primary_logistics: opportunity.primary_logistics,
      earnest_amount_verified: opportunity.earnest_amount_verified,
      option_fee_verified: opportunity.option_fee_verified,
      contract_received_date: opportunity.contract_received_date,
      solar_panel_lease_date_expiration: opportunity.solar_panel_lease_date_expiration,
      other_counter_offer_terms: opportunity.other_counter_offer_terms,
      solar_panel_payoff_amount: opportunity.solar_panel_payoff_amount,
      solar_panel_leased_or_owned: opportunity.solar_panel_leased_or_owned,
      hoa_addendum_received: opportunity.hoa_addendum_received,
      due_diligence_days_confirm: opportunity.due_diligence_days_confirm,
      due_diligence_fee: opportunity.due_diligence_fee,
      preferred_title: opportunity.preferred_title,
      lockbox_code: opportunity.lockbox_code,
      access_type: opportunity.access_type,
      seller_id: opportunity.seller_id,
      parties_escrow_company_id: opportunity.parties_escrow_company_id,
      reason_description: opportunity.reason_description,
      transaction_notes: opportunity.transaction_notes,
      wire_fee_amount: opportunity.wire_fee_amount,
      data_source: opportunity.data_source,
      dd_fee_payment_tracking_number: opportunity.dd_fee_payment_tracking_number,
      offer_strategy: opportunity.offer_strategy,
      extension_request_reason: opportunity.extension_request_reason,
      benefitting_negotiator_id: opportunity.benefitting_negotiator_id,
      benefitting_negotiator_changed_at: DateUtility.convertTimeZoneToUTC(opportunity.benefitting_negotiator_changed_at),
      benefitting_negotiator_changed_by_id: opportunity.benefitting_negotiator_changed_by_id,
      benefitting_negotiator_is_locked: opportunity.benefitting_negotiator_is_locked,
      forecasted_dd_end_date: opportunity.forecasted_dd_end_date,
      forecasted_close_date: opportunity.forecasted_close_date,
      gate_code: opportunity.gate_code,
      retrade_reason: opportunity.retrade_reason,
      buyer_requested_retrade_amount: opportunity.buyer_requested_retrade_amount,
      negotiator_sent_retrade_amount: opportunity.negotiator_sent_retrade_amount,
      actual_retrade_amount: opportunity.actual_retrade_amount,
      request_retrade_amount: opportunity.request_retrade_amount,
      entera_contribution: opportunity.entera_contribution,
      entera_contribution_amount: opportunity.entera_contribution_amount,
      pending_extension: opportunity.pending_extension,
      pending_retrade: opportunity.pending_retrade,
      approved_to_close: opportunity.approved_to_close,
      deprecated_buyer_name_hud_id: opportunity.deprecated_buyer_name_hud_id,
      buyer_contract_name_at_offer_sent_id: opportunity.buyer_contract_name_at_offer_sent_id,
      buyer_contract_name_at_diligence_start_id: opportunity.buyer_contract_name_at_diligence_start_id,
      buyer_contract_name_at_closing_id: opportunity.buyer_contract_name_at_closing_id,
      cancellation_reason_subcategory: opportunity.cancellation_reason_subcategory,
      termination_reason_subcategory: opportunity.termination_reason_subcategory,
      lease_agreement_received: opportunity.lease_agreement_received,
      lowest_acceptable_price: opportunity.lowest_acceptable_price,
      offer_response_details: opportunity.offer_response_details,
      offer_grade: opportunity.offer_grade,
      account_buyer_id: opportunity.account_buyer_id,
      entera_market_id: opportunity.entera_market_id,
      buyer_name_prelim_hud_id: opportunity.buyer_name_prelim_hud_id,
      expected_rehab_tier: opportunity.expected_rehab_tier,
      ip_address: opportunity.ip_address,
      request_id: opportunity.request_id,
      portfolio_id: opportunity.portfolio_id,
      portfolio_name: opportunity.portfolio_name,
      id_c: opportunity.id_c,
      jjwg_maps_lng_c: opportunity.jjwg_maps_lng_c,
      jjwg_maps_lat_c: opportunity.jjwg_maps_lat_c,
      jjwg_maps_geocode_status_c: opportunity.jjwg_maps_geocode_status_c,
      jjwg_maps_address_c: opportunity.jjwg_maps_address_c,
      glt_property_id_c: opportunity.glt_property_id_c,
      mls_c: opportunity.mls_c,
      tax_id_c: opportunity.tax_id_c,
      buyer_contract_name_c: opportunity.buyer_contract_name_c,
      customer_name_c: opportunity.customer_name_c,
      property_address_city_c: opportunity.property_address_city_c,
      property_address_postalcode_c: opportunity.property_address_postalcode_c,
      property_address_c: opportunity.property_address_c,
      msa_c: opportunity.msa_c,
      subdivision_c: opportunity.subdivision_c,
      legal_description_c: opportunity.legal_description_c,
      bed_c: opportunity.bed_c,
      bath_c: opportunity.bath_c,
      year_built_c: opportunity.year_built_c,
      sq_ft_c: opportunity.sq_ft_c,
      lot_size_c: opportunity.lot_size_c,
      pool_c: opportunity.pool_c,
      offer_date_c: opportunity.offer_date_c,
      offer_price_c: opportunity.offer_price_c,
      max_offer_price_c: opportunity.max_offer_price_c,
      financing_c: opportunity.financing_c,
      financing_amount_c: opportunity.financing_amount_c,
      balance_to_close_c: opportunity.balance_to_close_c,
      option_amount_c: opportunity.option_amount_c,
      option_inspection_period_c: opportunity.option_inspection_period_c,
      option_delivery_instructions_c: opportunity.option_delivery_instructions_c,
      earnest_amount_c: opportunity.earnest_amount_c,
      earnest_delivery_instruction_c: opportunity.earnest_delivery_instruction_c,
      additional_terms_comments_c: opportunity.additional_terms_comments_c,
      vacant_c: opportunity.vacant_c,
      opportunity_status_c: opportunity.opportunity_status_c,
      showing_information_instruct_c: opportunity.showing_information_instruct_c,
      title_company_name_c: opportunity.title_company_name_c,
      title_company_address_c: opportunity.title_company_address_c,
      title_company_contact_c: opportunity.title_company_contact_c,
      title_company_phone_c: opportunity.title_company_phone_c,
      title_company_email_c: opportunity.title_company_email_c,
      counter_offer_price_c: opportunity.counter_offer_price_c,
      contract_price_c: opportunity.contract_price_c,
      negotiation_notes_c: opportunity.negotiation_notes_c,
      remove_glt_notes_c: opportunity.remove_glt_notes_c,
      list_date_c: opportunity.list_date_c,
      mls_status_c: opportunity.mls_status_c,
      list_price_c: opportunity.list_price_c,
      offer_to_list_c: opportunity.offer_to_list_c,
      escrow_company_fax_c: opportunity.escrow_company_fax_c,
      occupancy_status_c: opportunity.occupancy_status_c,
      type_of_financing_c: opportunity.type_of_financing_c,
      initial_interest_rate_c: opportunity.initial_interest_rate_c,
      initial_financing_term_years_c: opportunity.initial_financing_term_years_c,
      buyers_attorney_name_c: opportunity.buyers_attorney_name_c,
      buyer_notice_address_c: opportunity.buyer_notice_address_c,
      buyer_phone_c: opportunity.buyer_phone_c,
      buyer_email_c: opportunity.buyer_email_c,
      legal_subdivision_c: opportunity.legal_subdivision_c,
      offer_expiration_date_c: opportunity.offer_expiration_date_c,
      option_fee_status_c: opportunity.option_fee_status_c,
      earnest_money_status_c: opportunity.earnest_money_status_c,
      option_fee_delivery_date_c: opportunity.option_fee_delivery_date_c,
      subdivision_section_c: opportunity.subdivision_section_c,
      property_lot_number_c: opportunity.property_lot_number_c,
      property_block_number_c: opportunity.property_block_number_c,
      property_excludes_c: opportunity.property_excludes_c,
      type_c: opportunity.type_c,
      buyer_commission_c: opportunity.buyer_commission_c,
      buyer_bonus_c: opportunity.buyer_bonus_c,
      listing_commission_c: opportunity.listing_commission_c,
      listing_bonus_c: opportunity.listing_bonus_c,
      close_date_c: opportunity.close_date_c,
      equipment_c: opportunity.equipment_c,
      public_remarks_c: opportunity.public_remarks_c,
      realtor_remarks_c: opportunity.realtor_remarks_c,
      opportunity_rehab_c: opportunity.opportunity_rehab_c,
      projected_rehab_c: opportunity.projected_rehab_c,
      actual_rehab_c: opportunity.actual_rehab_c,
      county_c: opportunity.county_c,
      chat_text_c: opportunity.chat_text_c,
      photo_c: opportunity.photo_c,
      buyer_contract_name_2_c: opportunity.buyer_contract_name_2_c,
      title_payor_c: opportunity.title_payor_c,
      survey_requested_c: opportunity.survey_requested_c,
      land_lots_c: opportunity.land_lots_c,
      district_number_c: opportunity.district_number_c,
      phase_number_c: opportunity.phase_number_c,
      plat_book_number_c: opportunity.plat_book_number_c,
      plat_page_number_c: opportunity.plat_page_number_c,
      deed_book_number_c: opportunity.deed_book_number_c,
      deed_book_page_c: opportunity.deed_book_page_c,
      warranty_required_c: opportunity.warranty_required_c,
      homeowner_association_c: opportunity.homeowner_association_c,
      contract_execution_date_c: opportunity.contract_execution_date_c,
      lease_back_allowed_c: opportunity.lease_back_allowed_c,
      loan_percent_c: opportunity.loan_percent_c,
      seller_closing_costs_c: opportunity.seller_closing_costs_c,
      seller_closing_support_c: opportunity.seller_closing_support_c,
      appraisal_required_c: opportunity.appraisal_required_c,
      unit_number_c: opportunity.unit_number_c,
      market_signer_name_c: opportunity.market_signer_name_c,
      market_signer_initials_c: opportunity.market_signer_initials_c,
      market_signer_name_2_c: opportunity.market_signer_name_2_c,
      market_signer_initials_2_c: opportunity.market_signer_initials_2_c,
      property_address_state_c: opportunity.property_address_state_c,
      due_diligence_end_c: DateUtility.convertTimeZoneToUTC(opportunity.due_diligence_end_c),
      earnest_money_due_c: opportunity.earnest_money_due_c,
      em_delivery_date_c: opportunity.em_delivery_date_c,
      remove_glt_mls_id_c: opportunity.remove_glt_mls_id_c,
      basement_c: opportunity.basement_c,
      option_days_type_c: opportunity.option_days_type_c,
      option_period_days_c: opportunity.option_period_days_c,
      listing_source_c: opportunity.listing_source_c,
      listing_type_c: opportunity.listing_type_c,
      garage_c: opportunity.garage_c,
      earnest_money_due_date_c: opportunity.earnest_money_due_date_c,
      additional_access_information_c: opportunity.additional_access_information_c,
      frequency_closing_status_c: opportunity.frequency_closing_status_c,
      flood_zone_c: opportunity.flood_zone_c,
      census_tract_c: opportunity.census_tract_c,
      hoa_fees_monthly_c: opportunity.hoa_fees_monthly_c,
      rent_avm_c: opportunity.rent_avm_c,
      calculated_tax_c: opportunity.calculated_tax_c,
      sale_avm_c: opportunity.sale_avm_c,
      property_type_c: opportunity.property_type_c,
      customer_brokerage_c: opportunity.customer_brokerage_c,
      customer_broker_name_c: opportunity.customer_broker_name_c,
      wire_request_name_c: opportunity.wire_request_name_c,
      benificiary_name_c: opportunity.benificiary_name_c,
      beneficiary_address_c: opportunity.beneficiary_address_c,
      beneficiary_city_c: opportunity.beneficiary_city_c,
      beneficiary_account_c: opportunity.beneficiary_account_c,
      routing_number_c: opportunity.routing_number_c,
      wire_information_c: opportunity.wire_information_c,
      wire_purpose_c: opportunity.wire_purpose_c,
      wire_status_c: opportunity.wire_status_c,
      initial_offer_price_c: opportunity.initial_offer_price_c,
      additional_earnest_money_c: opportunity.additional_earnest_money_c,
      contract_amendment_date_c: opportunity.contract_amendment_date_c,
      purchase_price_c: opportunity.purchase_price_c,
      entera_property_id_c: opportunity.entera_property_id_c,
      due_diligence_start_c: DateUtility.convertTimeZoneToUTC(opportunity.due_diligence_start_c),
      revised_list_price_c: opportunity.revised_list_price_c,
      list_price_updated_date_c: DateUtility.convertTimeZoneToUTC(opportunity.list_price_updated_date_c),
      mls_updated_timestamp_c: DateUtility.convertTimeZoneToUTC(opportunity.mls_updated_timestamp_c),
      offer_to_market_value_percentage_c: opportunity.offer_to_market_value_percentage_c,
      updated_at_c: opportunity.updated_at_c,
      initial_due_diligence_end: DateUtility.convertTimeZoneToUTC(opportunity.initial_due_diligence_end),
      inspection_confirmation_date: opportunity.inspection_confirmation_date
    }

  }
};

export default opportunityHelper;
