import { UtilityProviderEntity } from "types/utility-provider-types";

const initialProperityServiceProvider: UtilityProviderEntity = {
    id: '',
    opportunity_id: '',
    electric_provider: '',
    water_well_provider: '',
    sewer_provider: '',
    gas_provider: '',
    trash_provider: '',
    is_septic: 0,
    is_well: 0,
    is_electric: 0,
    date_entered: '',
    date_modified: '',
    name: '',
    modified_user_id: '',
    assigned_user_id: '',
    deleted: '',
    created_by: '',
    description: ''

};

export default initialProperityServiceProvider;
