import fieldLabel from 'assets/constants/fieldLabel';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';

export const forecasted_dd_end_date = {
  validate: function (opportunity: OpportunityViewEntity, status: string) {
    const errors: string[] = [];

    if (status === "edit") {
      const stateList = [
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended,
        oppurtunityStatusList.closing_clear_to_close,
        oppurtunityStatusList.closing_cancel_contract,
        oppurtunityStatusList.closed_purchased,
        oppurtunityStatusList.closing_termination_pending
      ];

      if (
        stateList.includes(opportunity?.opportunity_status_c) &&
        !isEmpty(opportunity?.forecasted_dd_end_date) &&
        DateUtility.getDateString(opportunity?.due_diligence_end_c) !== DateUtility.getDateString(opportunity?.forecasted_dd_end_date)
      ) {
        errors.push("DD End Date and Forecasted DD End Date must be equal");
      }
    }

    if (status === "action") {

      const stateList = [
        oppurtunityStatusList.closing_diligence_period,
        oppurtunityStatusList.closing_diligence_amended,
        oppurtunityStatusList.closing_clear_to_close,
      ];

      if (stateList.includes(opportunity?.opportunity_status_c) && isEmpty(opportunity?.forecasted_dd_end_date)) {
        errors.push(missingField(fieldLabel.forecastedDDEndDate))
      }
    }



    return errors;
  }
};
