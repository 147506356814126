import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { SettlementCommissionCalculation, OpportunityViewEntity } from 'types/opportunity-types';
import { final_commission } from './final_commission';
import opportunityHelper from 'helpers/opportunity-helper';
import { convertNumber, isEmpty } from 'helpers/misc-helper';
import fieldLabel from 'assets/constants/fieldLabel';

export const final_commission_percentage = {
  validate: function (opppurtunity: OpportunityViewEntity) {
    const errors: string[] = [];

    const stateList = [
      oppurtunityStatusList.closed_purchased,
      oppurtunityStatusList.closing_clear_to_close
    ];

    if (
      stateList.includes(opppurtunity?.opportunity_status_c) && isEmpty(opppurtunity?.final_commission_percentage)
    ) {
      errors.push(missingField(`${fieldLabel.actualCommissionPercentage} %`));
    }

    return [];
  },
  calculate: (opportunity: SettlementCommissionCalculation) => {
    if (
      ![
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended,
        oppurtunityStatusList.closing_clear_to_close,
        oppurtunityStatusList.closed_purchased,
        oppurtunityStatusList.closing_termination_pending
      ].includes(opportunity?.opportunity_status_c)
    ) {
      return convertNumber(opportunity?.final_commission_percentage);
    }

    let finalCommission;

    if (opportunity?.final_commission_changed) {
      finalCommission = convertNumber(opportunity?.final_commission);
    } else {
      finalCommission = final_commission.calculate(
        opportunity
      );
    }

    const priceForCalculation =
      opportunityHelper.getCurrentClosingPrice(opportunity);

    const finalCommissionPercentage =
      (finalCommission / priceForCalculation) * 100;

    return finalCommissionPercentage;
  }
};