
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { seller_rep_pct } from './commission-seller-rep-pct';

export const seller_rep = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.offer_sent_to_seller &&
      opportunity?.data_source === 'off_market' &&
      opportunity?.sellerOffMarket.sub_type !== 'ibuyer' &&
      opportunity?.leadSource.sub_type !== 'entera_client' &&
      (!opportunity?.seller_rep ||
        opportunity?.seller_rep.trim().length === 0)
    ) {
      errors.push(missingField('Does the Seller have outside Representation?'));
    }

    return errors;
  },

  handleChange: function (
    opportunity: OpportunityViewEntity
  ) {
    const result = {
      seller_rep_pct: seller_rep_pct.calculate(
        opportunity
      )
    };

    return result;
  },

  calculate: (opportunity: OpportunityViewEntity) => {
    if (
      opportunity?.sellerRepresentative?.sub_type == 'listing_agent'
    ) {
      return 'yes';
    }
    return 'no';
  }
};
