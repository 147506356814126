import { buyer_rep } from './commission-buyer-rep';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const parties_lead_owner_name = {
  handleChange: function (
    opportunity: OpportunityViewEntity
  ) {
    const result = {
      buyer_rep: buyer_rep.calculate(
        opportunity
      )
    };
    return result;
  }
};
