import ModalComponent from 'components/modal';
import React from 'react';

import ConfirmBoxContent from './confirm-box-form';
import emptyFunction from 'helpers/empty-function-helper';

export type ConfirmModalProps = {
  open: boolean;
  text: string | JSX.Element;
  title: string;
  proceed: () => void;
  cancel: () => void;
} & { isLoading?: boolean };

export const initialConfirmModalState = {
  open: false,
  text: '',
  title: '',
  proceed: emptyFunction,
  cancel: emptyFunction
};

const ConfirmBox = ({
  title,
  text,
  proceed,
  cancel,
  isLoading = false
}: ConfirmModalProps) => {
  return (
    <>
      <ModalComponent
        Component={ConfirmBoxContent}
        data={{
          title,
          text,
          cancel,
          proceed
        }}
        onClose={cancel}
        loading={isLoading}
        size="md"
        isConfirmationBox={true}
      />
    </>
  );
};

export default ConfirmBox;
