import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ErrorValidation } from 'types/error-types';
import { User } from 'types/user';

const validateLogin = (
  data: User
): {
  status: boolean;
  errors: ErrorValidation;
} => {
  let validation = {
    status: true,
    errors: {}
  };

  if (isEmpty(data.email)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        email: [missingField('Email')]
      }
    };
  }

  if (isEmpty(data.password)) {
    validation = {
      ...validation,
      status: false,
      errors: {
        ...validation.errors,
        password: [missingField('Password')]
      }
    };
  }

  return validation;
};

export default validateLogin;
