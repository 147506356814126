import { Box, List, ListItem } from '@mui/material';
import fieldLabel from 'assets/constants/fieldLabel';
import opportunityStatus from 'assets/constants/opportunity-status';
import sellerResponse from 'assets/constants/seller-response';
import AddButton from 'components/form/button-add';
import BackButton from 'components/form/button-back';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitEmpty from 'components/form/unit-empty';
import UnitItem from 'components/form/unit-item';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import ModalHeader from 'components/modal/modal-header';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import StackRow from 'components/stack/stack-row';
import { isEmpty } from 'helpers/misc-helper';
import negotiatorNoteHelper from 'helpers/negotiator-note/negotiator-note-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { OpportunityContext } from 'pages/opportunity/Context';
import useRouteName from 'pages/route-outlet-context';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import messageService from 'services/message-service';
import oppurtunityService from 'services/oppurtunity-service';
import { ObjectType } from 'types';
import { toastSuccess } from 'event/toast-event';
import { refreshOpportunity } from 'event/opportunity-event';
import { ChangeEvent } from 'types/common-types';
import { NoteEntity, OpportunityEntity } from 'types/opportunity-types';
import { v4 as uuid } from 'uuid';

type NegotiatorNotesEntity = {
  opportunity_status_c: string;
  seller_offer_response: string;
  negotiator_note: string;
};

const NegotiatorNotes = ({
  routeTag,
  opportunity
}: {
  routeTag?: string;
  id?: string;
  onClose?: () => void;
  opportunity?: OpportunityEntity;
}) => {
  const location = useLocation();
  const { opportunity_id, action } = useParams<ObjectType>();
  const outletContext = useRouteName();
  const { originalOpportunity } = useContext(OpportunityContext);

  const [error, showError] = useState<string[]>([]);
  const [notes, setNotes] = useState([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const [data, setData] = useState<NegotiatorNotesEntity>({
    opportunity_status_c:
      opportunity?.opportunity_status_c ||
      originalOpportunity?.opportunity_status_c,
    seller_offer_response:
      opportunity?.seller_offer_response ||
      originalOpportunity?.seller_offer_response,
    negotiator_note: ''
  });

  const handleChange = (e: ChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    setDisabled(true);
    if (data.negotiator_note.trim().length == 0) {
      setDisabled(false);
      showError(['Please enter Notes']);

      return;
    }

    showError([]);

    const reqBody = {
      opportunity_id,
      seller_offer_response: data.seller_offer_response,
      name: data.negotiator_note
    };

    const result = await messageService.postNegotiatorNote(reqBody);
    if (result.isSuccess) {
      toastSuccess(
        `${fieldLabel.noteAdded}: ${data.negotiator_note.substring(0, 30)}`
      );

      setData((data) => ({ ...data, negotiator_note: '' }));
      await loadNegotiatorNotes();
      setDisabled(false);
    }
  };

  const loadNegotiatorNotes = async () => {
    const result = await messageService.getNegotiatorNotes(opportunity_id);

    if (result.isSuccess) {
      setNotes(result.data.data);
    }
  };

  const updateOpportunity = async () => {
    const result = await oppurtunityService.update(opportunity_id, {
      opportunity_id,
      seller_offer_response: data.seller_offer_response
    });

    if (result.isSuccess) {
      toastSuccess(fieldLabel.opportunitySaved);
      refreshOpportunity();
    }
  };

  const isOpportunityDetailsVisible = () => {
    if (action === 'negotiation_notes' && opportunity_id) return true;
    if (
      location.pathname.includes('notes_chats/negotiator_notes') &&
      opportunity_id
    )
      return true;
    return false;
  };
  useEffect(() => {
    loadNegotiatorNotes();
  }, []);

  useEffect(() => {
    if (!isEmpty(useContext) && !isEmpty(routeTag))
      outletContext.setRouteName(routeTag !== undefined ? routeTag : '');
  }, []);

  return (
    <>
      <PaperBox>
        {action === 'negotiation_notes' && (
          <PaperBoxHeader
            value={
              <>
                <UnitItem grid={{ xs: 11, sm: 11 }} p={0}>
                  <ModalHeader
                    title={`${
                      opportunity?.name || originalOpportunity?.name
                    } - ${fieldLabel.negotiationNotes}`}
                    onClose={() => {
                      window.history.back();
                    }}
                  />
                </UnitItem>
              </>
            }
          />
        )}
        <PaperBoxContent sx={{ overflowY: 'auto', p: 2 }}>
          <FormContainer>
            <UnitItem grid={{ sm: 1, xs: 1 }}>
              {location.pathname.includes('notes_chats/negotiator_notes') ? (
                <BackButton />
              ) : (
                <></>
              )}
            </UnitItem>
            <UnitEmpty grid={{ sm: 11, xs: 11 }} />
            {isOpportunityDetailsVisible() && (
              <>
                <UnitSelect
                  name="opportunity_status_c"
                  label={fieldLabel.opportunityStatus}
                  records={getObjectEntriesAsArray(opportunityStatus)}
                  value={data.opportunity_status_c ?? ''}
                  onChange={handleChange}
                  disabled={true}
                  grid={{ xs: 12, sm: 5 }}
                />

                <UnitSelect
                  name="seller_offer_response"
                  label={fieldLabel.sellerResponse}
                  records={getObjectEntriesAsArray(sellerResponse)}
                  value={data.seller_offer_response ?? ''}
                  onChange={handleChange}
                  grid={{ xs: 12, sm: 5 }}
                />

                <UnitItem grid={{ xs: 12, sm: 2 }}>
                  <StackRow isUnitItem>
                    <SaveButton onClick={updateOpportunity} />
                  </StackRow>
                </UnitItem>
              </>
            )}

            <UnitText
              label="Type a note here..."
              name="negotiator_note"
              value={data.negotiator_note}
              onChange={handleChange}
              error={error}
              multiline
              rows={2}
              grid={{ xs: 12, sm: 10 }}
            />
            <UnitItem grid={{ xs: 12, sm: 2 }}>
              <StackRow isUnitItem>
                <AddButton onClick={handleSubmit} disabled={disabled} />
              </StackRow>
            </UnitItem>
          </FormContainer>
          <FormContainer spacing={0}>
            <UnitItem grid={{ xs: 12, sm: 12 }} sx={{ pl: 0 }}>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid #ccc',
                  borderRadius: 1,
                  pr: '0',
                  pl: 1,
                  height: 'calc(100vh - 51.5vh)',
                  overflow: 'auto'
                }}
              >
                <List>
                  {notes.map((note: NoteEntity) => (
                    <ListItem
                      key={uuid()}
                      sx={{ padding: '10px' }}
                      component="div"
                      disablePadding
                    >
                      {negotiatorNoteHelper.getNegotiatorNoteLineItem(note)}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </UnitItem>
          </FormContainer>
        </PaperBoxContent>
      </PaperBox>
    </>
  );
};

export default NegotiatorNotes;
