import { convertNumber } from "helpers/misc-helper";
import { OpportunityViewEntity } from "types/opportunity-types";

export const balance_to_close_c = {
  calculate: (opportunity: Partial<OpportunityViewEntity>) => {

    if (convertNumber(opportunity.offer_price_c) > 0) {
      return (
        convertNumber(opportunity.offer_price_c) -
        convertNumber(opportunity.earnest_amount_c) -
        convertNumber(opportunity.financing_amount_c) -
        convertNumber(opportunity.option_amount_c)
      );
    }

    return opportunity.balance_to_close_c;

  }
};
