
import { initial_commission_amount } from './initial_commission_amount';
import opportunityHelper from 'helpers/opportunity-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const initial_offer_price_c = {

    handleChange: (opportunity: OpportunityViewEntity) => {


        const initialCommissionAmount = initial_commission_amount.calculate(opportunity);
        opportunity = {
            ...opportunity,
            initial_commission_amount: initialCommissionAmount ?? 0
        };

        const commissions = opportunityHelper.calculateCommission(opportunity);

        const result = {
            initial_commission_amount: initialCommissionAmount,
            ...commissions
        };

        return result;
    }
};
