import EVENTS from 'assets/constants/events';
import fieldLabel from 'assets/constants/fieldLabel';
import EnteraLogo2 from 'assets/images/blue_entera-logo.png';
import EnteraLogo from 'assets/images/entera-logo2.png';
import LoginButton from 'components/form/button-login';
import FormContainer from 'components/form/container';
import GoogleLoginButton from 'components/form/google-login-button';
import UnitItem from 'components/form/unit-item';
import UnitPassword from 'components/form/unit-password';
import TextUnit from 'components/form/unit-text';
import LinkExtenalOpenNewIcon from 'components/link/link-external-open-new-icon';
import RouteLink from 'components/link/route-link';
import PaperBox from 'components/paper-box';
import envConfig from 'config/env';
import googleClient from 'config/google-client';
import { toastError } from 'event/toast-event';
import { isLoginPage } from 'helpers/auth-helper';
import eventBus from 'helpers/event-bus-helper';
import { AuthContext } from 'hooks/auth/auth-context';
import useAuthenticated from 'hooks/auth/use-authenticated';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from 'services/auth-service';
import { UserType } from 'types';
import { ErrorValidation } from 'types/error-types';
import validateLogin from 'validations/login';

import { Box, Container, Typography } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { isEmpty } from 'helpers/misc-helper';

const LoginForm: React.FC = () => {
  const isAuthenticated = useAuthenticated();
  const { dispatch } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [loading, setLoading] = useState({
    manualLogin: false,
    googleLogin: false
  });

  const location = useLocation();

  type LoginState = UserType & {
    errorMessage: string | null;
  };

  const initialState: LoginState = {
    id: '',
    email: '',
    password: '',
    errorMessage: null
  };

  const [user, setUser] = useState<LoginState>(initialState);

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setValidation({});

    setUser({
      ...user,
      errorMessage: null
    });

    const { status, errors } = validateLogin(user);

    setValidation(errors);

    if (!status) return;

    setLoading((prevLoading) => ({ ...prevLoading, manualLogin: true }));

    const result = await authService.login(user);
    setLoading((prevLoading) => ({ ...prevLoading, manualLogin: false }));

    if (
      result.isError &&
      !isEmpty(result.errorMessage.message) &&
      result.errorMessage.message.includes('timeout')
    ) {
      toastError(fieldLabel.vpnErrorMessage);
      return;
    } else if (result.isError && typeof result.errorMessage === 'string') {
      toastError(result.errorMessage);
      return;
    }

    if (result.isSuccess) {
      eventBus.dispatch(EVENTS.HIDE_TOAST, {});
      eventBus.dispatch('LOAD_PERMISSION', {});
      dispatch({
        type: 'LOGIN',
        payload: {
          user: user,
          token: result.data.access_token
        }
      });
    }
  };

  const changeHandler = (event: React.FormEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [event.currentTarget.name]: event.currentTarget.value
    });
  };

  useEffect(() => {
    if (isAuthenticated && isLoginPage(location)) {
      navigate('/properties');
    } else {
      navigate(location.pathname);
    }
  }, [isAuthenticated]);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <PaperBox
          sx={{
            p: 3,
            marginTop: 8
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              component="img"
              style={{ width: '160px' }}
              alt="Entera"
              src={EnteraLogo}
              sx={{ mb: 2 }}
            />

            <Box sx={{ mt: 2 }}>
              <FormContainer>
                <TextUnit
                  label={fieldLabel.userName}
                  name="email"
                  value={user.email}
                  onChange={changeHandler}
                  grid={{ sm: 0, xs: 12 }}
                  error={validation['email'] ?? ''}
                />

                <UnitPassword
                  label={fieldLabel.password}
                  name="password"
                  showPassword={showPassword}
                  value={user.password}
                  onChange={changeHandler}
                  grid={{ sm: 0, xs: 12 }}
                  error={validation['password'] ?? ''}
                  setShowPassword={setShowPassword}
                />
                <UnitItem style={{ paddingTop: '10px' }}>
                  <RouteLink
                    url={`/forgot`}
                    name={'Forgot Password?'}
                    target={false}
                  />
                </UnitItem>
              </FormContainer>
              <LoginButton
                fullWidth
                loading={loading.manualLogin}
                disabled={loading.manualLogin}
                sx={{ mt: 3, mb: 2 }}
                onClick={submitHandler}
              />
              <GoogleOAuthProvider clientId={googleClient.enteraRealtyComId}>
                <GoogleLoginButton
                  setLoading={setLoading}
                  loading={loading}
                  label="Login with enterarealty.com"
                />
              </GoogleOAuthProvider>
              <GoogleOAuthProvider clientId={googleClient.enteraAiId}>
                <GoogleLoginButton
                  setLoading={setLoading}
                  loading={loading}
                  label="Login with entera.ai"
                />
              </GoogleOAuthProvider>
            </Box>
          </Box>
        </PaperBox>
        <Typography p={1}>
          By logging into Entera, you agree to the{' '}
          <LinkExtenalOpenNewIcon
            url={`${envConfig.REACT_APP_FRONTEND_URL}/terms-of-use`}
            label="Terms of Use"
            withIcon={false}
          />{' '}
          and{' '}
          <LinkExtenalOpenNewIcon
            url={`${envConfig.REACT_APP_FRONTEND_URL}/privacy-notice`}
            label="Privacy Notice"
            withIcon={false}
          />
        </Typography>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          p: 1
        }}
      >
        Powered by: <img src={EnteraLogo2} height={'25px'} />
      </Box>
    </>
  );
};

export default LoginForm;
