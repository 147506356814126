import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';

export const due_diligence_start_c = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];
    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.closing_diligence_period &&
      isEmpty(opportunity?.due_diligence_start_c)
    ) {
      errors.push(missingField('Due Diligence Start Date'));
    }

    if (
      !isEmpty(opportunity?.due_diligence_start_c) &&
      !isEmpty(opportunity?.due_diligence_end_c) &&
      DateUtility.getDateUnix(opportunity?.due_diligence_start_c) >=
      DateUtility.getDateUnix(opportunity?.due_diligence_end_c)
    ) {
      errors.push(
        'Due Diligence Start Date and Time must be less than Due Diligence End Date and Time'
      );
    }

    return errors;
  }
};
