import envConfig from 'config/env';
import httpService from './http-service';
import { OpportunityCommissionEntity, OpportunityCommissionPayload } from 'types/opportunity-types';

const service = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/opportunity_commissions`,

  getList: async (query: string) => {
    const result = await httpService.list<OpportunityCommissionEntity>(`${service.url}${query}`);
    return result;
  },

  updateCommission: async (id: string, data: OpportunityCommissionPayload) => {
    const result = await httpService.put(`${service.url}/${id}`, data);
    return result;
  },
  createCommission: async (data: OpportunityCommissionPayload) => {
    const result = await httpService.post(`${service.url}`, data);
    return result;
  }
};

export default service;
