
import { offer_to_market_value_percentage_c } from './offer-to-market-value-percentage-c';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const sale_avm_c = {
  handleChange: function (
    opportunity: OpportunityViewEntity,
    originalOpportunity: OpportunityViewEntity
  ) {
    const result = {
      offer_to_market_value_percentage_c:
        offer_to_market_value_percentage_c.calculate(
          opportunity,
          originalOpportunity
        )
    };

    return result;
  }
};
