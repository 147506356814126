import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { initial_commission_amount } from './initial_commission_amount';
import opportunityHelper from 'helpers/opportunity-helper';
import { convertNumber, isEmpty } from 'helpers/misc-helper';

export const contract_price_c = {
  validate: function (oppourtunity: OpportunityViewEntity, status: string) {
    const errors: string[] = [];

    if (status === 'edit') {
      const statuseListEdit = [
        oppurtunityStatusList.offer_short_sale_offer_accepted,
        oppurtunityStatusList.closing_diligence_period
      ];

      if (
        statuseListEdit.includes(oppourtunity?.opportunity_status_c) &&
        isEmpty(oppourtunity?.contract_price_c)
      ) {
        errors.push(missingField('Contract Price'));
      }
    }

    if (status === 'action') {
      if (
        (oppurtunityStatusList.closing_diligence_period ===
          oppourtunity?.opportunity_status_c &&
          isEmpty(oppourtunity?.contract_price_c)) ||
        convertNumber(oppourtunity?.contract_price_c) === 0.0
      ) {
        errors.push(missingField('Contract Price'));
      }
    }

    return errors;
  },

  handleChange: (opportunity: OpportunityViewEntity) => {


    const initialCommissionAmount = initial_commission_amount.calculate(opportunity);
    opportunity = {
      ...opportunity,
      initial_commission_amount: initialCommissionAmount ?? 0
    };

    const commissions = opportunityHelper.calculateCommission(opportunity);

    const result = {
      initial_commission_amount: initialCommissionAmount,
      ...commissions
    }

    return result
  }

};
