import { convertNumber } from 'helpers/misc-helper';
import { negotiator_commission_pct } from './commission-negotiator-commission-pct';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const negotiator_commission_amount = {
  calculate: function (
    opportunity: OpportunityViewEntity
  ) {
    const contractPrice = convertNumber(opportunity?.contract_price_c);
    const initialCommissionPercentage = convertNumber(
      opportunity?.initial_commission_percentage
    );
    const commissionNegotiatorCommissionPct =
      negotiator_commission_pct.calculate(
        opportunity
      );

    const value =
      contractPrice *
      (initialCommissionPercentage / 100) *
      (commissionNegotiatorCommissionPct / 100);

    return value;
  }
};
