import { AsyncSelect } from 'components/async-select/async-select';
import { FormControl, Grid, List, ListItem, ListItemText } from '@mui/material';
import { OptionType } from 'types/option-type';
import FieldLabel from './field-label';
import opportunityService from 'services/opportunity-service';
import React, { useState } from 'react';
import ValidationError from 'components/errors/validation-error';

interface OpportunityUnitInterface {
  grid?: {
    xs: number;
    sm: number;
  };
  value: OptionType;
  fullWidth?: boolean;
  onChange: (e: OptionType) => void;
  error?: string[];
  disabled?: boolean;
  multiple?: boolean;
  readOnly?: boolean;
  label?: string;
}

type OptionsType = OptionType & { account_name: string; msa_name: string };
const UnitOpportunitySelect = ({
  value,
  onChange,
  grid = { xs: 12, sm: 6 },
  fullWidth = true,
  error = [],
  disabled = false,
  readOnly = false,
  multiple = false,
  label = 'Opportunity'
}: OpportunityUnitInterface) => {
  const [options, setOptions] = useState<any[]>([]);

  const onChangeHandle = async (value: string) => {
    if (value && value.length > 2) {
      const result = await opportunityService.getList(
        `?sort[field]=name&sort[direction]=asc&filter[property_address_c]=${value}`
      );

      if (result.isSuccess) {
        const opportunities = result.data.data;

        const opportunityOption = opportunities.map((opportunity) => ({
          label: opportunity.name,
          value: opportunity.id,
          account_name: opportunity.account.name,
          msa_name: opportunity.market.name
        }));

        setOptions(opportunityOption);
      }
    }
  };

  if (readOnly) {
    return (
      <Grid item xs={grid.xs} sm={grid.sm}>
        <FieldLabel>{label}</FieldLabel>
        <FormControl fullWidth={fullWidth}>
          <AsyncSelect
            multiple={multiple}
            options={options}
            value={value}
            id="opportunity"
            label={''}
            name="opportunity"
            setOptions={setOptions}
            onChange={(val: OptionType) => {
              onChange(val);
            }}
            onChangeHandle={onChangeHandle}
            readOnly={readOnly}
            disabled={disabled}
          />
        </FormControl>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={grid.xs} sm={grid.sm}>
        <FieldLabel>{label}</FieldLabel>
        <FormControl fullWidth>
          <AsyncSelect
            multiple={multiple}
            options={options}
            value={value}
            id="opportunity"
            label={''}
            name="opportunity"
            setOptions={setOptions}
            renderOption={(
              props: any,
              option: OptionType & { account_name: string; msa_name: string }
            ) => (
              <List {...props}>
                <ListItem>
                  <ListItemText
                    primary={option.label}
                    secondary={`${option.account_name ?? ''} | ${
                      option.msa_name ?? ''
                    }`}
                  />
                </ListItem>
              </List>
            )}
            onChange={(val: OptionType) => {
              onChange(val);
            }}
            onChangeHandle={onChangeHandle}
          />
        </FormControl>
        {error.length > 0 && <ValidationError data={error} />}
      </Grid>
    );
  }
};
export default UnitOpportunitySelect;
