import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const cancel_request_received_date = {
  validate: function (opportunity: OpportunityViewEntity, status: string) {
    const errors: string[] = [];

    if (status === 'action') {
      if (
        opportunity?.opportunity_status_c ===
        oppurtunityStatusList.offer_canceled &&
        isEmpty(opportunity?.cancel_request_received_date)
      ) {
        errors.push(missingField('Cancel Request Received Date'));
      }
    }

    return errors;
  }
};
