import { CardActions, IconButton } from '@mui/material';
import { isUserAdmin } from 'helpers/user-role-helper';
import { KnowledgeBaseHelpPayloadEntity } from 'types/knowledge-base-types';
import { ObjectType } from 'types';
import CancelButton from 'components/form/button-cancel';
import CircularLoader from 'components/dog-loader/dog-lodar';
import CreateIcon from '@mui/icons-material/Create';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import initialKnowledgeBaseHelper from 'state/knowledge-base/initial-knowledge-base-helper';
import KnowledgeBaseCreate from 'features/knowledge-base/knowledge-base-create';
import knowledgeBaseService from 'services/knowledge-base-service';
import MarkdownEditor from '@uiw/react-md-editor';
import ModalComponent from 'components/modal';
import ModalHeader from 'components/modal/modal-header';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import PrimaryButton from 'components/button/button-primary';
import React, { useState } from 'react';
import SaveButton from 'components/form/button-save';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import UnitItem from 'components/form/unit-item';
import UnitKnowledgeBaseTitle from 'components/form/unit-knowledge-base-title';
import UnitText from 'components/form/unit-text';
import useRouteName from 'pages/route-outlet-context';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { isEmpty } from 'helpers/misc-helper';
import { toastError } from 'event/toast-event';

const KnowledgeBaseHelper = (props: { tag: string }) => {
  const { tag } = props;
  const { routeName } = useRouteName();
  const [title, setTitle] = useState<string>('Knowledge Base Help');
  const [modal, setModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [knowledgeBaseHelper, setKnowledgeBaseHelper] =
    useState<KnowledgeBaseHelpPayloadEntity>(initialKnowledgeBaseHelper);

  const helpHandler = async () => {
    setModal(true);
    setIsLoading(true);
    const queryString = `?filter[tag] = ${tag}`;
    const result = await knowledgeBaseService.getList(queryString);
    const response = result.data.data;
    setIsLoading(false);

    if (response.length > 0) {
      setSelected(true);
      setTitle(response[0].name);
      setKnowledgeBaseHelper((prevKnowledgeBaseHelper) => ({
        ...prevKnowledgeBaseHelper,
        id: response[0].id,
        description: response[0].description
      }));
    }
  };

  const reqBody: KnowledgeBaseHelpPayloadEntity = {
    id: knowledgeBaseHelper.id,
    name: knowledgeBaseHelper.name,
    description: knowledgeBaseHelper.description,
    tag: tag
  };

  const handleSave = async () => {
    if (isEmpty(knowledgeBaseHelper.id)) {
      toastError('Please Select Knowledge base');
      return;
    }

    const response = await knowledgeBaseService.update(
      knowledgeBaseHelper.id,
      reqBody
    );
    setTitle(response.data.name);
    setSelected(true);
  };
  const updateKnowledgeBase = () => {
    setEditMode(false);
    helpHandler();
  };

  const KnowldgeBaseContent = () => {
    return (
      <>
        {!editMode ? (
          <MarkdownEditor.Markdown source={knowledgeBaseHelper.description} />
        ) : (
          <KnowledgeBaseCreate
            routeTag={routeName}
            knowledge_base_id={knowledgeBaseHelper.id}
            setEditMode={setEditMode}
            updateKnowledgeBase={updateKnowledgeBase}
          />
        )}
      </>
    );
  };

  const KnowledgeBaseSelectorContent = () => {
    return (
      <>
        <FormContainer>
          <UnitKnowledgeBaseTitle
            value={{
              label: knowledgeBaseHelper.name,
              value: knowledgeBaseHelper.id
            }}
            label={fieldLabel.knowledgeBase}
            onChange={(value: ObjectType) => {
              setKnowledgeBaseHelper((prevKnowledgeBaseHelper) => ({
                ...prevKnowledgeBaseHelper,
                id: value?.value,
                name: value?.label,
                description: value?.description
              }));
            }}
          />

          <UnitText
            label={fieldLabel.tag}
            name="tag"
            value={tag}
            onChange={emptyFunction}
            readOnly
          />
        </FormContainer>

        <StackRowWithDivider>
          <SaveButton onClick={handleSave} />
          <CancelButton onClick={() => setModal(false)} />
        </StackRowWithDivider>
      </>
    );
  };

  const KnowledgeBaseHelperContent = () => {
    return (
      <PaperBox sx={{ bgcolor: '#EFF1F6' }}>
        <PaperBoxHeader
          value={
            <FormContainer>
              <UnitItem grid={{ xs: 12, sm: 6 }} p={0}>
                <ModalHeader
                  title={
                    <>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {title}
                        {selected && isUserAdmin() && (
                          <IconButton>
                            {!editMode ? (
                              <CreateIcon onClick={() => setEditMode(true)} />
                            ) : (
                              <VisibilityIcon
                                onClick={() => setEditMode(false)}
                              />
                            )}
                          </IconButton>
                        )}
                      </div>
                    </>
                  }
                  onClose={() => {
                    setModal(false);
                    setEditMode(false);
                  }}
                />
              </UnitItem>
            </FormContainer>
          }
        />
        <PaperBoxContent
          sx={{
            maxHeight: 'calc(100vh - 40vh)',
            overflowY: 'auto',
            p: 1
          }}
        >
          {selected ? (
            <KnowldgeBaseContent />
          ) : (
            <KnowledgeBaseSelectorContent />
          )}
        </PaperBoxContent>
      </PaperBox>
    );
  };

  return (
    <>
      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'column-reverse',
          marginLeft: 'auto'
        }}
      >
        <PrimaryButton onClick={helpHandler}>{fieldLabel.help}</PrimaryButton>
      </CardActions>

      {modal && (
        <ModalComponent
          Component={isLoading ? CircularLoader : KnowledgeBaseHelperContent}
          onClose={() => {
            setModal(false);
            setSelected(false);
            setEditMode(false);
          }}
          loading={false}
          size="md"
        ></ModalComponent>
      )}
    </>
  );
};

export default KnowledgeBaseHelper;
