import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { isEmpty } from 'helpers/misc-helper';


export const has_post_occupancy = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    const stateList = [
      oppurtunityStatusList.closing_diligence_period,
      oppurtunityStatusList.closing_diligence_amended
    ];

    const isDirty = stateList.includes(opportunity?.opportunity_status_c) &&
      !isEmpty(opportunity?.offer_finalized_at) &&
      isEmpty(opportunity.has_post_occupancy)

    if (isDirty) {
      errors.push(missingField('Has Post Occupancy'));
    }

    return errors;
  }
};
