import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const earnest_paid_by_entera = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity.opportunity_status_c ===
      oppurtunityStatusList.closing_cancel_contract &&
      isEmpty(opportunity.earnest_paid_by_entera)
    ) {
      errors.push(missingField('EM Paid by Entera'));
    }

    return errors;
  }
};
