import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { SettlementCommissionCalculation, OpportunityViewEntity } from 'types/opportunity-types';
import { convertNumber } from 'helpers/misc-helper';
import opportunityHelper from 'helpers/opportunity-helper';

export const buyer_commission_c = {
  validate: function (opportunity: OpportunityViewEntity) {
    const statuses = [
      oppurtunityStatusList.offer_short_sale_offer_accepted,
      oppurtunityStatusList.closing_diligence_period,
      oppurtunityStatusList.closed_purchased,
      oppurtunityStatusList.offer_accepted,
      oppurtunityStatusList.closing_diligence_amended,
      oppurtunityStatusList.closing_sale_pending,
      oppurtunityStatusList.closing_sale_pending_amended,
      oppurtunityStatusList.closing_clear_to_close
    ];

    const errors: string[] = [];

    if (
      statuses.includes(opportunity?.opportunity_status_c) &&
      (!opportunity?.buyer_commission_c ||
        opportunity?.buyer_commission_c.toString().trim().length === 0)
    ) {
      errors.push(missingField('Pending Commission %'));
    }

    return errors;
  },

  calculate: (opportunity: SettlementCommissionCalculation) => {

    const priceForCalculation = opportunityHelper.getCurrentClosingPrice(opportunity);
    const commissionPending = convertNumber(opportunity?.commision_pending);

    if (priceForCalculation == 0) return parseFloat(`0.00`).toFixed(2);

    const pendingCommission = (commissionPending / priceForCalculation) * 100;

    const result = parseFloat(`${pendingCommission}`).toFixed(2);

    return result;

  }
};
