import { ComposeMailTypeEntity, ContentType } from 'types/email-types';
import {
  ChangeEvent,
  DispatchSetState,
  InputChangeEvent,
  OnKeyDownEvent
} from 'types/common-types';
import { EmailTemplateListEntity } from 'types/email-template-types';
import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { OpportunityContext } from 'pages/opportunity/Context';
import { useParams } from 'react-router-dom';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/email-template/default-sort';
import emptyFunction from 'helpers/empty-function-helper';
import eventBus from 'helpers/event-bus-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { useContext, useEffect, useState } from 'react';
import templateColumns from 'assets/list/email-template/template-box-column';
import TextUnit from 'components/form/unit-text';
import emailTemplateService from 'services/email-template-service';
interface PropTypes {
  setContent?: DispatchSetState<ContentType>;
  handleChange: (val: InputChangeEvent) => void;
  mail: ComposeMailTypeEntity;
  setMail?: DispatchSetState<ComposeMailTypeEntity>;
}

const TemplateBox = ({
  setContent = emptyFunction,
  handleChange,
  mail,
  setMail = emptyFunction
}: PropTypes) => {
  const { originalOpportunity } = useContext(OpportunityContext);
  const { action } = useParams<{ action: string }>();

  const [filter, setFilter] = useState({ name: '', subject: '' });
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const saveFilterOnEnterPress = (e: OnKeyDownEvent) => {
    if (e.key === 'Enter') {
      if (e.target.value !== undefined) {
        setIsFilterChanged(true);
      }
    }
  };

  const updateFilter = (e: ChangeEvent) => {
    setFilter(Object.assign({}, filter, { [e.target.name]: e.target.value }));
  };

  const getEmailTemplates = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<EmailTemplateListEntity>> => {
    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: {
        ...filter,
        market_preference_id: originalOpportunity.market_preference.id
      }
    });

    setIsLoading(true);
    const result = await emailTemplateService.getList(`${queryString}`);
    setIsLoading(false);

    return result;
  };

  const loadTemplatEventHandler = (e: ObjectType) => {
    handleChange({ target: e.target });

    if (mail.count > 0 && mail.status === 'send') {
      setMail((prevMail) => ({
        ...prevMail,
        template: e.target.value,
        template_type: e.target.template_type
      }));
    } else {
      let subject = e.target.subject;

      if (!isEmpty(action)) {
        subject = action === 'forward' ? `Fwd: ${subject}` : `RE: ${subject}`;
      }

      setMail((prevMail) => ({
        ...prevMail,
        template: e.target.value,
        subject: subject,
        template_type: e.target.template_type
      }));
    }
    setContent((prevContent) => ({
      ...prevContent,
      html: e.target.html
    }));
  };

  useEffect(() => {
    eventBus.on('LOAD_TEMPLATE_CONTENT', (data: ObjectType) => {
      loadTemplatEventHandler(data);
    });
  }, []);

  return (
    <React.Fragment>
      <FormContainer>
        <TextUnit
          label={fieldLabel.name}
          name="name"
          value={filter.name}
          onChange={updateFilter}
          onKeyDown={(e: OnKeyDownEvent) => saveFilterOnEnterPress(e)}
          grid={{ xs: 12, sm: 12 }}
        />
        <TextUnit
          label={fieldLabel.subject}
          name="subject"
          value={filter.subject}
          onChange={updateFilter}
          onKeyDown={(e: OnKeyDownEvent) => saveFilterOnEnterPress(e)}
          grid={{ xs: 12, sm: 12 }}
        />
      </FormContainer>
      <DataGrid<EmailTemplateListEntity>
        getData={getEmailTemplates}
        tableColumns={templateColumns}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
        refreshEvent="email_template"
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
      />
    </React.Fragment>
  );
};

export default TemplateBox;
