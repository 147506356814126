import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const deposit_amount = {
  validate: function (opportunity: OpportunityViewEntity, status: string) {
    const errors: string[] = [];

    if (status === 'edit') {
      if (opportunity?.deposit_amount < 0) {
        errors.push('No negative numbers allowed');
      }
    }

    if (status === 'action') {
      if (
        opportunity?.has_post_occupancy !== 'no' &&
        isEmpty(opportunity?.deposit_amount)
      ) {
        errors.push(missingField('Deposit Amount'));
      }
    }

    return errors;
  }
};
