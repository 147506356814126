import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';
import { initial_commission_amount } from './initial_commission_amount';
import opportunityHelper from 'helpers/opportunity-helper';

export const purchase_price_c = {
  validate: function (opportunity: OpportunityViewEntity, status: string) {
    const errors: string[] = [];

    if (status === 'edit') {
      const statuseListEdit = [oppurtunityStatusList.closed_purchased];

      if (
        statuseListEdit.includes(opportunity?.opportunity_status_c) &&
        isEmpty(opportunity?.purchase_price_c)
      ) {
        errors.push(missingField('Purchase Price'));
      }
    }

    if (status === 'action') {
      if (
        (oppurtunityStatusList.closed_purchased ===
          opportunity?.opportunity_status_c &&
          isEmpty(opportunity.purchase_price_c)) ||
        opportunity?.purchase_price_c === 0.0
      ) {
        errors.push(missingField('Purchase Price'));
      }
    }

    return errors;
  },

  handleChange: (opportunity: OpportunityViewEntity) => {

    const initialCommissionAmount = initial_commission_amount.calculate(opportunity);
    opportunity = {
      ...opportunity,
      initial_commission_amount: initialCommissionAmount ?? 0
    };

    const commissions = opportunityHelper.calculateCommission(opportunity);

    const result = {
      initial_commission_amount: initialCommissionAmount,
      ...commissions
    }


    return result;
  }
};
