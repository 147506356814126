import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';


export const cancel_reason = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.offer_canceled &&
      isEmpty(opportunity?.cancel_reason)
    ) {
      errors.push(
        missingField(
          'Cancel Reason must be completed before moving status to Offer: Canceled.'
        )
      );
    }

    return errors;
  }
};
