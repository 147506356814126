import { convertNumber, isEmpty, isValueChanged } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const offer_to_list_c = {
  calculate: (opportunity: OpportunityViewEntity, originalOpportunity: OpportunityViewEntity) => {
    if (
      !isEmpty(opportunity.list_price_c) &&
      !isEmpty(opportunity.offer_price_c)
    ) {
      if (
        parseFloat(opportunity.list_price_c) > 0 &&
        parseFloat(opportunity.offer_price_c) > 0
      ) {
        if (
          isValueChanged(opportunity, originalOpportunity, 'offer_price_c') ||
          isValueChanged(opportunity, originalOpportunity, 'list_price_c')
        ) {
          const value =
            (convertNumber(opportunity.offer_price_c) / convertNumber(opportunity.list_price_c)) * 100;
          return value;
        }
      }
    }

    return 0;
  }
};
