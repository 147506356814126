import { ObjectType } from 'types';
import envConfig from 'config/env';
import httpService from './http-service';
import {
  OpportunityEntity,
  OpportunityListEntity,
  OpportunityPayload,
  OpportunityViewEntity
} from 'types/opportunity-types';
import { GetListResponse } from 'types/api-response-types';
import { BrokerageUserListEntity } from 'types/opportunity-brokerage-user-types';

const opportunityService = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/opportunities`,

  getList: async (
    query: string
  ): Promise<GetListResponse<OpportunityListEntity>> => {
    const url = `${opportunityService.url}${query}`;
    const result = await httpService.list<OpportunityListEntity>(url);
    return result;
  },

  get: async (id: string) => {
    const result = await httpService.get<OpportunityViewEntity>(
      `${opportunityService.url}/${id}`
    );
    return result;
  },

  update: async (id: string, data: OpportunityPayload) => {
    const response = await httpService.put<OpportunityEntity, OpportunityPayload>(
      `${opportunityService.url}/${id}`,
      data
    );
    return response;
  },

  getOpportunityBrokerageUsers: async (id: string) => {
    const result = await httpService.list<BrokerageUserListEntity>(
      `${opportunityService.url}/${id}/brokerage_users`
    );
    return result;
  },

  getFields: async () => {
    const result: ObjectType = await httpService.list(
      `${opportunityService.url}/fields`
    );
    return result;
  },

  deleteAssociatedDocument: async (
    opportunity_id: string,
    document_id: string
  ) => {
    const result = await httpService.del(
      `${opportunityService.url}/${opportunity_id}/documents/${document_id}`
    );

    return result;
  }
};

export default opportunityService;
