import getObjectEntriesAsArray, {
  getObjectKeyValue
} from 'helpers/object-field-helper';
import { close_date_c } from 'services/validation/close_date';
import { isEmpty, valueToLabel } from 'helpers/misc-helper';
import buyerSource from 'assets/constants/buyer-source';
import dataSource from 'assets/constants/data-source';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import React from 'react';
import RouteCreateIconLink from 'components/link/route-create-icon-link';
import RouteEditIconLink from 'components/link/route-edit-icon-link';
import RouteLink from 'components/link/route-link';
import UnitDate from 'components/form/unit-date';
import UnitEmpty from 'components/form/unit-empty';
import UnitItem from 'components/form/unit-item';
import UnitLabel from 'components/form/unit-label';
import UnitParty from 'components/form/unit-party';
import UnitPercentageFormatter from 'components/form/unit-percentage-formatter';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitRead from 'components/form/unit-read';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import yesNo from 'assets/constants/yes-no';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { InputChangeEvent, DispatchSetState } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { PartyListEntity } from 'types/party-types';
import { validation as validationService } from 'services/validation-service';

interface RecordViewType {
  data: OpportunityViewEntity;
  originalOpportunity: OpportunityViewEntity;
  validation?: ErrorValidation;
  handleChange?: (e: InputChangeEvent) => void;
  handleChangeWihEffect?: (e: InputChangeEvent) => void;
  readOnly?: boolean;
  setOpportunity?: DispatchSetState<OpportunityViewEntity>;
  setField?: DispatchSetState<string>;
}

const OffMarketRecordView = ({
  originalOpportunity,
  data,
  validation = {},
  handleChange = emptyFunction,
  handleChangeWihEffect = emptyFunction,
  readOnly = false,
  setOpportunity = emptyFunction,
  setField = emptyFunction
}: RecordViewType) => {
  const CreateEditLink = ({ value, url }: { url: string; value: string }) => {
    return !isEmpty(value) ? (
      <RouteEditIconLink url={url} target={true} />
    ) : (
      <RouteCreateIconLink url={url} target={true} />
    );
  };

  const calculate = (field: string) => {
    return validationService[field]?.calculate(data, originalOpportunity);
  };

  return (
    <>
      <FormContainer>
        <UnitLabel label={fieldLabel.partiesDetails} fontWeight="bold" />
        <UnitLabel label={fieldLabel.offerDetails} fontWeight="bold" />

        {readOnly == true ? (
          <UnitRead
            grid={{ sm: 5, xs: 8 }}
            label={fieldLabel.sellerName}
            value={
              !isEmpty(data?.parties_seller_id) &&
              !isEmpty(data?.sellerOffMarket?.name) ? (
                <RouteLink
                  url={
                    !isEmpty(data?.parties_seller_id)
                      ? `/opportunities/${data.id}/parties/${data.parties_seller_id}/view`
                      : ''
                  }
                  name={data?.sellerOffMarket?.name || ''}
                  target={true}
                />
              ) : (
                ''
              )
            }
          />
        ) : (
          <UnitParty
            label={fieldLabel.sellerName}
            value={{
              label: data?.sellerOffMarket?.name,
              value: data?.parties_seller_id
            }}
            type="seller"
            subType=""
            onChange={(party: PartyListEntity) => {
              setOpportunity((prevOpportunity) => ({
                ...prevOpportunity,
                parties_seller_id: party?.id || '',
                sellerOffMarket: party
              }));

              setField('offmarket_seller_name_c');
            }}
            readOnly={readOnly}
            grid={{ sm: 5, xs: 8 }}
          />
        )}

        <UnitItem grid={{ sm: 1, xs: 4 }}>
          <CreateEditLink
            url={
              !isEmpty(data?.parties_seller_id) &&
              !isEmpty(data.sellerOffMarket.name)
                ? `/opportunities/${data.id}/parties/${data.parties_seller_id}/edit`
                : `/opportunities/${data.id}/parties/create`
            }
            value={data?.sellerOffMarket?.name}
          />
        </UnitItem>

        <UnitText
          label={fieldLabel.dataSource}
          name="data_source"
          value={getObjectKeyValue(dataSource, data?.data_source ?? '')}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
        />

        <UnitText
          label={fieldLabel.sellerEmail}
          name="seller_email"
          value={data?.sellerOffMarket?.email ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
        />

        <UnitDate
          label={fieldLabel.closeDate}
          name="close_date_c"
          value={data?.close_date_c ?? null}
          onChange={(value) => {
            value = close_date_c.calculate({ close_date_c: value });
            handleChangeWihEffect({
              target: {
                name: 'close_date_c',
                value: value
              }
            });
          }}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
        />

        <UnitText
          label={fieldLabel.sellerType}
          name="parties_seller_type"
          value={data?.sellerOffMarket?.type ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
          error={validation['parties_seller_type'] ?? ''}
        />

        <UnitPriceFormatter
          name="contract_price_c"
          value={data?.contract_price_c}
          onChange={handleChange}
          label={fieldLabel.contractPrice}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
          error={validation['contract_price_c'] ?? ''}
        />

        <UnitEmpty />

        <UnitPercentageFormatter
          name="initial_commission_percentage"
          label={`${fieldLabel.initialCommission} %`}
          value={data?.initial_commission_percentage}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitEmpty />
        <UnitEmpty />

        {readOnly ? (
          <UnitRead
            grid={{ sm: 5, xs: 8 }}
            label={fieldLabel.sellerRepresentativeName}
            value={
              !isEmpty(data?.parties_seller_representative_id) &&
              !isEmpty(data?.sellerRepresentative?.name) ? (
                <RouteLink
                  url={
                    !isEmpty(data?.parties_seller_representative_id)
                      ? `/opportunities/${data.id}/parties/${data.parties_seller_representative_id}/view`
                      : ''
                  }
                  name={data?.sellerRepresentative?.name || ''}
                  target={true}
                />
              ) : (
                ''
              )
            }
          />
        ) : (
          <UnitParty
            label={fieldLabel.sellerRepresentativeName}
            type="seller_representative"
            subType=""
            value={{
              label: data?.sellerRepresentative?.name || '',
              value: data?.parties_seller_representative_id || ''
            }}
            onChange={(party: PartyListEntity) => {
              setOpportunity((prevOpportunity) => ({
                ...prevOpportunity,
                parties_seller_representative_id: party?.id || '',
                sellerRepresentative: party
              }));

              setField('parties_seller_representative_name');
            }}
            readOnly={readOnly}
            error={validation['parties_seller_representative_name'] ?? ''}
            grid={{ sm: 5, xs: 8 }}
          />
        )}

        <UnitItem grid={{ sm: 1, xs: 4 }}>
          <CreateEditLink
            url={
              !isEmpty(data?.parties_seller_representative_id) &&
              !isEmpty(data.sellerRepresentative.name)
                ? `/opportunities/${data.id}/parties/${data.parties_seller_representative_id}/edit`
                : `/opportunities/${data.id}/parties/create`
            }
            value={data?.sellerRepresentative?.name}
          />
        </UnitItem>

        <UnitLabel label={fieldLabel.commissionInput} fontWeight="bold" />

        <UnitText
          label={fieldLabel.sellerRepresentativeEmail}
          name="parties_seller_representative_email"
          value={data?.sellerRepresentative?.email ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitSelect
          name="buyer_source"
          label={fieldLabel.buyerSource}
          records={getObjectEntriesAsArray(buyerSource)}
          value={data?.buyer_source ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          error={validation['buyer_source'] ?? ''}
        />

        <UnitText
          label={fieldLabel.sellerRepresentativeCompany}
          name="parties_seller_representative_company"
          value={data?.sellerRepresentative?.company ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitSelect
          name="buyer_rep"
          label={`${fieldLabel.didYouRepresenttheBuyer}?`}
          records={getObjectEntriesAsArray(yesNo)}
          value={data?.buyer_rep ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          error={validation['buyer_rep'] ?? ''}
        />

        <UnitText
          label={fieldLabel.sellerRepresentativeType}
          name="parties_seller_representative_type"
          value={data?.sellerRepresentative?.type ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
          error={validation['parties_seller_representative_type'] ?? ''}
        />

        <UnitEmpty />
        <UnitEmpty />

        <UnitSelect
          name="seller_source"
          label={fieldLabel.sellerSource}
          records={getObjectEntriesAsArray(buyerSource)}
          value={data?.seller_source ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          error={validation['seller_source'] ?? ''}
        />

        <UnitEmpty />
        <UnitEmpty />

        {readOnly == true ? (
          <UnitRead
            grid={{ sm: 5, xs: 8 }}
            label={fieldLabel.leadSourceName}
            value={
              !isEmpty(data?.parties_lead_source_id) &&
              !isEmpty(data.leadSource.name) ? (
                <RouteLink
                  url={
                    !isEmpty(data?.parties_lead_source_id)
                      ? `/opportunities/${data.id}/parties/${data.parties_lead_source_id}/view`
                      : ''
                  }
                  name={data.leadSource.name || ''}
                  target={true}
                />
              ) : (
                ''
              )
            }
          />
        ) : (
          <UnitParty
            label={fieldLabel.leadSourceName}
            type="lead_source"
            subType=""
            value={{
              label: data?.leadSource?.name,
              value: data?.parties_lead_source_id
            }}
            onChange={(party: PartyListEntity) => {
              setOpportunity((prevOpportunity) => ({
                ...prevOpportunity,
                parties_lead_source_id: party?.id || '',
                leadSource: party
              }));

              setField('parties_lead_source_name');
            }}
            error={validation['parties_lead_source_name'] ?? ''}
            readOnly={readOnly}
            grid={{ sm: 5, xs: 8 }}
          />
        )}

        <UnitItem grid={{ sm: 1, xs: 4 }}>
          <CreateEditLink
            url={
              !isEmpty(data?.parties_lead_source_id) &&
              !isEmpty(data.leadSource.name)
                ? `/opportunities/${data.id}/parties/${data.parties_lead_source_id}/edit`
                : `/opportunities/${data.id}/parties/create`
            }
            value={data?.leadSource?.name}
          />
        </UnitItem>

        <UnitSelect
          name="seller_rep"
          label={fieldLabel.doesTheSellerHaveOutSideRepresentation}
          records={getObjectEntriesAsArray(yesNo)}
          value={data?.seller_rep ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          error={validation['seller_rep'] ?? ''}
        />

        <UnitText
          label={fieldLabel.leadSourceEmail}
          name="parties_lead_source_email"
          value={data?.leadSource?.email ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
        />

        <UnitEmpty />

        <UnitText
          label={fieldLabel.leadSourceType}
          name="parties_lead_source_type"
          value={data?.leadSource?.type ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
          error={validation['parties_lead_source_type'] ?? ''}
        />

        <UnitEmpty />
        <UnitEmpty />
        <UnitEmpty />

        {readOnly == true ? (
          <UnitRead
            grid={{ sm: 5, xs: 8 }}
            label={fieldLabel.leadOwnerName}
            value={
              !isEmpty(data?.parties_lead_owner_id) &&
              !isEmpty(data?.leadOwner?.name) ? (
                <RouteLink
                  url={
                    !isEmpty(data?.parties_lead_owner_id)
                      ? `/opportunities/${data.id}/parties/${data.parties_lead_owner_id}/view`
                      : ''
                  }
                  name={data?.leadOwner?.name || ''}
                  target={true}
                />
              ) : (
                ''
              )
            }
          />
        ) : (
          <UnitParty
            label={fieldLabel.leadOwnerName}
            type="lead_owner,buyer_representative"
            subType=""
            value={{
              label: data?.leadOwner?.name,
              value: data?.parties_lead_owner_id
            }}
            onChange={(party: PartyListEntity) => {
              setOpportunity((prevOpportunity) => ({
                ...prevOpportunity,
                parties_lead_owner_id: party?.id || '',
                leadOwner: party
              }));

              setField('parties_lead_owner_name');
            }}
            error={validation['parties_lead_owner_name'] ?? ''}
            grid={{ sm: 5, xs: 8 }}
            readOnly={readOnly}
          />
        )}

        <UnitItem grid={{ sm: 1, xs: 4 }}>
          <CreateEditLink
            url={
              !isEmpty(data?.parties_lead_owner_id) &&
              !isEmpty(data.leadOwner.name)
                ? `/opportunities/${data.id}/parties/${data.parties_lead_owner_id}/edit`
                : `/opportunities/${data.id}/parties/create`
            }
            value={data?.leadOwner?.name}
          />
        </UnitItem>

        <UnitLabel label={fieldLabel.commissionOutput} fontWeight="bold" />

        <UnitText
          label={fieldLabel.leadOwnerEmail}
          name="parties_lead_owner_email"
          value={data?.leadOwner?.email ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitPercentageFormatter
          name="buyer_source_pct"
          label={`${fieldLabel.buyerSource} (%)`}
          value={calculate('buyer_source_pct')}
          onChange={handleChangeWihEffect}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitText
          label={fieldLabel.leadOwnerType}
          name="parties_lead_owner_type"
          value={data?.leadOwner?.type ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitPercentageFormatter
          name="buyer_rep_pct"
          label={`${fieldLabel.didYouRepresenttheBuyer}? (%)`}
          value={calculate('buyer_rep_pct')}
          onChange={handleChangeWihEffect}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitEmpty />
        <UnitEmpty />

        {readOnly == true ? (
          <UnitRead
            grid={{ sm: 5, xs: 8 }}
            label={fieldLabel.buyerRepresentativeName}
            value={
              !isEmpty(data?.parties_buyer_representative_id) &&
              !isEmpty(data.buyerRepresentative.name) ? (
                <RouteLink
                  url={
                    !isEmpty(data?.parties_buyer_representative_id)
                      ? `/opportunities/${data.id}/parties/${data.parties_buyer_representative_id}/view`
                      : ''
                  }
                  name={data?.buyerRepresentative?.name || ''}
                  target={true}
                />
              ) : (
                ''
              )
            }
          />
        ) : (
          <UnitParty
            label={fieldLabel.buyerRepresentativeName}
            type="lead_owner,buyer_representative"
            subType=""
            value={{
              label: data?.buyerRepresentative?.name || '',
              value: data?.parties_buyer_representative_id || ''
            }}
            onChange={(party: PartyListEntity) => {
              setOpportunity((prevOpportunity) => ({
                ...prevOpportunity,
                parties_buyer_representative_id: party?.id || '',
                buyerRepresentative: party
              }));
              setField('parties_buyer_representative_name');
            }}
            error={validation['parties_buyer_representative_name'] ?? ''}
            grid={{ sm: 5, xs: 8 }}
            readOnly={readOnly}
          />
        )}

        <UnitItem grid={{ sm: 1, xs: 4 }}>
          <CreateEditLink
            url={
              !isEmpty(data?.parties_buyer_representative_id) &&
              !isEmpty(data.buyerRepresentative.name)
                ? `/opportunities/${data.id}/parties/${data.parties_buyer_representative_id}/edit`
                : `/opportunities/${data.id}/parties/create`
            }
            value={data?.buyerRepresentative?.name}
          />
        </UnitItem>

        <UnitPercentageFormatter
          name="seller_source_pct"
          label={`${fieldLabel.sellerSource} (%)`}
          value={calculate('seller_source_pct')}
          onChange={handleChangeWihEffect}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitText
          label={fieldLabel.buyerRepresentativeEmail}
          name="parties_buyer_representative_email"
          value={data?.buyerRepresentative?.email ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
        />

        <UnitPercentageFormatter
          name="seller_rep_pct"
          label={`${fieldLabel.doesTheSellerHaveOutSideRepresentation}? (%)`}
          value={calculate('seller_rep_pct')}
          onChange={emptyFunction}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitText
          label={fieldLabel.buyerRepresentativeType}
          name="parties_buyer_representative_type"
          value={
            data?.buyerRepresentative?.type
              ? valueToLabel(data.buyerRepresentative.type)
              : ''
          }
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitPercentageFormatter
          name="negotiator_commission_pct"
          label={fieldLabel.negotiatorTakeOfCommission}
          value={calculate('negotiator_commission_pct')}
          onChange={emptyFunction}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitEmpty />

        <UnitPriceFormatter
          name="negotiator_commission_amount"
          value={calculate('negotiator_commission_amount')}
          onChange={emptyFunction}
          label={fieldLabel.yourCommission}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitEmpty />
        <UnitEmpty />

        {readOnly == true ? (
          <UnitRead
            grid={{ sm: 5, xs: 8 }}
            label={fieldLabel.intendedBuyerName}
            value={
              !isEmpty(data?.parties_intended_buyer_id) &&
              !isEmpty(data?.intendedBuyer?.name) ? (
                <RouteLink
                  url={
                    !isEmpty(data?.intendedBuyer?.name)
                      ? `/opportunities/${data.id}/parties/${data.parties_intended_buyer_id}/view`
                      : ''
                  }
                  name={data?.intendedBuyer?.name || ''}
                  target={true}
                />
              ) : (
                ''
              )
            }
          />
        ) : (
          <UnitParty
            label={fieldLabel.intendedBuyerName}
            type="intended_buyer"
            subType=""
            value={{
              label: data?.intendedBuyer?.name,
              value: data?.parties_intended_buyer_id
            }}
            onChange={(party: PartyListEntity) => {
              setOpportunity((prevOpportunity) => ({
                ...prevOpportunity,
                parties_intended_buyer_id: party?.id || '',
                intendedBuyer: party
              }));
              setField('parties_intended_buyer_name');
            }}
            error={validation['parties_intended_buyer_name'] ?? ''}
            grid={{ sm: 5, xs: 8 }}
            readOnly={readOnly}
          />
        )}

        <UnitItem grid={{ sm: 1, xs: 4 }}>
          <CreateEditLink
            url={
              !isEmpty(data?.parties_intended_buyer_id) &&
              !isEmpty(data.intendedBuyer.name)
                ? `/opportunities/${data.id}/parties/${data.parties_intended_buyer_id}/edit`
                : `/opportunities/${data.id}/parties/create`
            }
            value={data?.intendedBuyer?.name}
          />
        </UnitItem>

        <UnitEmpty />

        <UnitText
          label={fieldLabel.intendedBuyerEmail}
          name="parties_intended_buyer_email"
          value={data?.intendedBuyer?.email ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitEmpty />

        <UnitText
          label={fieldLabel.intendedBuyerType}
          name="parties_intended_buyer_type"
          value={data?.intendedBuyer?.type ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
          error={validation['parties_intended_buyer_type'] ?? ''}
        />

        <UnitEmpty />
        <UnitEmpty />
        <UnitEmpty />

        {readOnly ? (
          <UnitRead
            grid={{ sm: 5, xs: 8 }}
            label={fieldLabel.uploadedByName}
            value={
              !isEmpty(data?.parties_uploaded_by_id) &&
              !isEmpty(data.uploadedBy.name) ? (
                <RouteLink
                  url={
                    !isEmpty(data?.parties_uploaded_by_id)
                      ? `/opportunities/${data.id}/parties/${data.parties_uploaded_by_id}/view`
                      : ''
                  }
                  name={data?.uploadedBy?.name || ''}
                  target={true}
                />
              ) : (
                ''
              )
            }
          />
        ) : (
          <UnitParty
            label={fieldLabel.uploadedByName}
            type="uploaded_by"
            subType=""
            value={{
              label: data?.uploadedBy?.name,
              value: data?.parties_uploaded_by_id
            }}
            onChange={(party: PartyListEntity) => {
              setOpportunity((prevOpportunity) => ({
                ...prevOpportunity,
                parties_uploaded_by_id: party?.id || '',
                uploadedBy: party
              }));
              setField('parties_uploaded_by_name');
            }}
            error={validation['parties_uploaded_by_name'] ?? ''}
            grid={{ sm: 5, xs: 8 }}
            readOnly={readOnly}
          />
        )}

        <UnitItem grid={{ sm: 1, xs: 4 }}>
          <CreateEditLink
            url={
              !isEmpty(data?.parties_uploaded_by_id) &&
              !isEmpty(data.uploadedBy.name)
                ? `/opportunities/${data.id}/parties/${data.parties_uploaded_by_id}/edit`
                : `/opportunities/${data.id}/parties/create`
            }
            value={data?.uploadedBy?.name}
          />
        </UnitItem>

        <UnitEmpty />

        <UnitText
          label={fieldLabel.uploadedByEmail}
          name="parties_uploaded_by_email"
          value={data?.uploadedBy?.email ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
          error={validation['parties_uploaded_by_email'] ?? ''}
        />

        <UnitEmpty />

        <UnitText
          label={fieldLabel.uploadedByType}
          name="parties_uploaded_by_type"
          value={data?.uploadedBy?.type ?? ''}
          onChange={handleChange}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
          error={validation['parties_uploaded_by_type'] ?? ''}
        />
      </FormContainer>
    </>
  );
};

export default OffMarketRecordView;
