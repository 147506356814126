import { ObjectType } from 'types';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { offer_to_list_c } from './offer-to-list-c';
import { offer_to_market_value_percentage_c } from './offer-to-market-value-percentage-c';
import { convertNumber, isEmpty } from 'helpers/misc-helper';
import { balance_to_close_c } from './balance_to_close_c';

export const offer_price_c = {
  validate: function (
    opportunity: OpportunityViewEntity,
    status: string,
    originalOpportunity: ObjectType
  ) {
    const errors: string[] = [];

    const data = JSON.parse(localStorage.getItem('user') || '0');
    const is_admin = data?.user?.is_admin ? data?.user?.is_admin : 0;

    if (status === 'action') {
      if (
        (opportunity?.opportunity_status_c ===
          oppurtunityStatusList.closing_diligence_period &&
          isEmpty(opportunity?.offer_price_c)) ||
        convertNumber(opportunity?.offer_price_c) === 0.0
      ) {
        errors.push(missingField('Offer Price'));
      }
    }

    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.closing_diligence_period &&
      convertNumber(opportunity?.offer_price_c) <
      convertNumber(opportunity?.contract_price_c)
    ) {
      errors.push(
        'Offer price cannot be lower the contract price, please update offer price before continuing!'
      );
    }

    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.offer_make_offer &&
      opportunity?.opportunity_status_c !==
      originalOpportunity.opportunity_status_c &&
      is_admin === 0
    ) {
      errors.push('Only Admin user can set this status - Offer: Make Offer');
    }

    return errors;
  },

  handleChange: function (
    opportunity: OpportunityViewEntity,
    originalOpportunity: OpportunityViewEntity
  ) {
    const result = {
      offer_to_list_c: offer_to_list_c.calculate(
        opportunity,
        originalOpportunity
      ),
      offer_to_market_value_percentage_c:
        offer_to_market_value_percentage_c.calculate(
          opportunity,
          originalOpportunity,
        ),
      balance_to_close_c: balance_to_close_c.calculate(
        opportunity
      )
    };

    return result;
  }
};
