import { BrokerageUserListEntity } from 'types/opportunity-brokerage-user-types';
import { GetResponse, UpdateResponse } from 'types/api-response-types';
import { ProfileEntity } from 'types/user';
import envConfig from 'config/env';
import httpService from './http-service';
import userTypes from 'assets/constants/user-types';

const userService = {
  getCurrentUser: async (): Promise<GetResponse<ProfileEntity>> => {
    const result = await httpService.get<ProfileEntity>(`/${envConfig.REACT_APP_API_VERSION_1}/me`);
    return result;
  },
  update: async (id: string, payload: ProfileEntity): Promise<UpdateResponse<ProfileEntity>> => {
    const result = await httpService.put<ProfileEntity>(
      `${envConfig.REACT_APP_API_VERSION_1}/users/${id}`,
      payload
    );
    return result;
  },
  getList: async (query: string) => {
    const result = await httpService.list<BrokerageUserListEntity>(
      `/${envConfig.REACT_APP_API_VERSION_1}/users${query}`
    );
    return result;
  },

  getUsers: (data: BrokerageUserListEntity[]) => {

    let users: { [key: string]: BrokerageUserListEntity } = {};

    for (const type of userTypes) {
      let user = data.filter(
        (x: BrokerageUserListEntity) => x.brokerge_user_role_for_opportunity === type
      );
      users = { ...users, [type]: user[0] };
    }

    let result = {
      benefittingNegotiator: users.benefitting_negotiator_id,
      benefittingNegotiatorChangedBy: users.benefitting_negotiator_changed_by_id,
      offerSubmittedBy: users.offer_submitted_by,
      saveForSingnatureBy: users.save_for_singnature_by,
      congratsLetterSentBy: users.congrats_letter_sent_by,
      accessRequestedBy: users.access_requested_by,
      activePrimaryNegotiatorUser: users.active_primary_negotiator_user_id,
      leaseback3SentBy: users.leaseback_3_sent_by,
      leaseback1SentBy: users.leaseback_1_sent_by,
      submittingUserCode: users.submitting_user_code,
      negoAtOfferSent: users.nego_at_offer_sent,
      negoAtOfferAccept: users.nego_at_offer_accept,
      negoAtOfferClosing: users.nego_at_closing,
      primaryTc: users.primary_tc,
      primaryTcAtDiligence: users.primary_tc_at_diligence,
      primaryTcAtSalePending: users.primary_tc_at_sale_pending,
      primaryTcAtClosing: users.primary_tc_at_closing,
      primaryLogistics: users.primary_logistics,
      primaryLogisticsAtDiligence: users.primary_logistics_at_diligence,
      primaryLogisticsAtSalePending: users.primary_logistics_at_sale_pending,
      primaryLogisticsAtClosing: users.primary_logistics_at_closing,
    }
    return result;
  },
};


export default userService;
