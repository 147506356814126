import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const buyer_requested_retrade_amount = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (isEmpty(opportunity?.buyer_requested_retrade_amount)) {
      errors.push(missingField('Buyer Requested Retrade Amount'));
    }

    return errors;
  }
};
