import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CallIcon from '@mui/icons-material/Call';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import ChatIcon from '@mui/icons-material/Chat';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreateIcon from '@mui/icons-material/Create';
import DraftsIcon from '@mui/icons-material/Drafts';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import InboxIcon from '@mui/icons-material/Inbox';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MailIcon from '@mui/icons-material/Mail';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import NoteIcon from '@mui/icons-material/Note';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import QueueIcon from '@mui/icons-material/Queue';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import TaskIcon from '@mui/icons-material/Task';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import brokerageActionItems from 'assets/menus/side-menu/brokerage-action-items';
import { v4 as uuid } from 'uuid';
import fieldLabel from 'assets/constants/fieldLabel';
import { isEmpty } from 'helpers/misc-helper';
import appModel from 'assets/constants/app-models';
import appPermission from 'assets/constants/app-permissions';
import { ObjectType } from 'types';
import { MarketPreferenceEntity } from 'types/market-preference-types';


const opportunityView = {
  label: fieldLabel.view,
  url: (id: string) => `/opportunities/${id}/view`,
  labelIcon: VisibilityIcon,
  model: appModel.opportunity,
  permission: appPermission.view,
  nodeId: uuid()
};

const opportunityEdit = {
  label: fieldLabel.edit,
  url: (id: string) => `/opportunities/${id}/edit`,
  labelIcon: EditIcon,
  model: appModel.opportunity,
  permission: appPermission.edit,
  nodeId: uuid()
};

const generateContract = {
  label: fieldLabel.generateContract,
  url: (id: string) => `/opportunities/${id}/generate_contract`,
  labelIcon: ListAltIcon,
  model: appModel.opportunity,
  permission: appPermission.view,
  nodeId: uuid()
};

const viewChangelog = {
  label: fieldLabel.viewChangelog,
  url: (id: string) => `/opportunities/${id}/change-log`,
  labelIcon: HistoryIcon,
  model: appModel.opportunity,
  permission: appPermission.list,
  key: uuid()
};

const opportunityMenu = {
  label: fieldLabel.opportunity,
  labelIcon: AttachMoneyIcon,
  subMenuItems: [opportunityView, opportunityEdit],
  nodeId: uuid()
};
const emailCompose = {
  label: fieldLabel.compose,
  url: (id: string) => `/opportunities/${id}/email/compose`,
  labelIcon: CreateIcon,
  model: appModel.email,
  permission: appPermission.edit,
  nodeId: uuid()
};

const emailInbox = {
  label: fieldLabel.inbox,
  url: (id: string) => `/opportunities/${id}/email/inbox`,
  labelIcon: InboxIcon,
  model: appModel.email,
  permission: appPermission.list,
  nodeId: uuid()
};

const emailDraft = {
  label: fieldLabel.draft,
  url: (id: string) => `/opportunities/${id}/email/draft`,
  labelIcon: DraftsIcon,
  model: appModel.email,
  permission: appPermission.edit,
  nodeId: uuid()
};

const email = {
  label: fieldLabel.email,
  labelIcon: MailIcon,
  subMenuItems: [emailCompose, emailInbox, emailDraft],
  nodeId: uuid()
};

const documentList = {
  label: fieldLabel.list,
  url: (id: string) => `/opportunities/${id}/documents`,
  labelIcon: ListAltIcon,
  model: 'Documents',
  permission: appPermission.list,
  nodeId: uuid()
};

const documentUpload = {
  label: fieldLabel.upload,
  url: (id: string) => `/opportunities/${id}/documents/upload`,
  labelIcon: CloudUploadIcon,
  model: 'Documents',
  permission: appPermission.edit,
  nodeId: uuid()
};

const document = {
  label: fieldLabel.documents,
  labelIcon: InsertDriveFileIcon,
  subMenuItems: [documentList, documentUpload],
  nodeId: uuid()
};

const serviceList = {
  label: fieldLabel.list,
  url: (id: string) => `/opportunities/${id}/services`,
  labelIcon: ListAltIcon,
  model: appModel.service,
  permission: appPermission.list,
  nodeId: uuid()
};

const serviceCreate = {
  label: fieldLabel.create,
  url: (id: string) => `/opportunities/${id}/services/create`,
  labelIcon: AddIcon,
  model: appModel.service,
  permission: appPermission.create,
  nodeId: uuid()
};

const service = {
  label: fieldLabel.services,
  labelIcon: MiscellaneousServicesIcon,
  subMenuItems: [serviceList, serviceCreate],
  nodeId: uuid()
};

const partyList = {
  label: fieldLabel.list,
  url: (id: string) => `/opportunities/${id}/parties`,
  labelIcon: ListAltIcon,
  model: appModel.party,
  permission: appPermission.list,
  nodeId: uuid()
};

const taskList = {
  label: fieldLabel.tasks,
  url: (id: string) => `/opportunities/${id}/tasks`,
  labelIcon: TaskIcon,
  model: appModel.task,
  permission: appPermission.list,
  key: uuid(),
  nodeId: uuid()
};

const callList = {
  label: fieldLabel.calls,
  url: (id: string) => `/opportunities/${id}/calls`,
  labelIcon: CallIcon,
  model: appModel.call,
  permission: appPermission.list,
  key: uuid(),
  nodeId: uuid()
};

const partyCreate = {
  label: fieldLabel.create,
  url: (id: string) => `/opportunities/${id}/parties/create`,
  labelIcon: AddIcon,
  model: appModel.party,
  permission: appPermission.edit,
  nodeId: uuid()
};

const party = {
  label: fieldLabel.parties,
  labelIcon: PeopleAltIcon,
  subMenuItems: [partyList, partyCreate],
  nodeId: uuid()
};


const negotiatorNotes = {
  label: fieldLabel.negotiationNotes,
  url: (id: string) => `/opportunities/${id}/notes_chats/negotiator_notes`,
  labelIcon: NoteAddIcon,
  model: appModel.opportunity,
  permission: appPermission.view,
  nodeId: uuid()
};

const transactionNotes = {
  label: fieldLabel.transactionNotes,
  url: (id: string) => `/opportunities/${id}/notes_chats/transaction_notes`,
  labelIcon: NoteAddIcon,
  model: appModel.opportunity,
  permission: appPermission.view,
  nodeId: uuid()
};

const brokerageNotes = {
  label: fieldLabel.brokerageNotes,
  url: (id: string) => `/opportunities/${id}/notes_chats/brokerage_notes`,
  labelIcon: NoteAddIcon,
  model: appModel.opportunity,
  permission: appPermission.view,
  nodeId: uuid()
};

const clientChats = {
  label: fieldLabel.clientChat,
  url: (id: string) => `/opportunities/${id}/notes_chats/client_chats`,
  labelIcon: ChatIcon,
  model: appModel.opportunity,
  permission: appPermission.view,
  nodeId: uuid()
};

const noteChat = {
  label: 'Notes/Chats',
  labelIcon: NoteIcon,
  subMenuItems: [
    negotiatorNotes,
    transactionNotes,
    brokerageNotes,
    clientChats
  ],
  nodeId: uuid()
};

const brokerageUserList = {
  label: fieldLabel.list,
  url: (id: string) => `/opportunities/${id}/opportunity-brokerage-users`,
  labelIcon: ListAltIcon,
  model: 'OPPORTUNITY_BROKERAGE_USER',
  permission: appPermission.list,
  nodeId: uuid()
};

const brokerageUserCreate = {
  label: fieldLabel.create,
  url: (id: string) =>
    `/opportunities/${id}/opportunity-brokerage-users/create`,
  labelIcon: AddIcon,
  model: appModel.opportunityBrokerageUser,
  permission: appPermission.edit,
  nodeId: uuid()
};

const brokerageUsers = {
  label: fieldLabel.opportunityBrokerageUser,
  labelIcon: AccountCircleIcon,
  subMenuItems: [brokerageUserList, brokerageUserCreate],
  nodeId: uuid()
};

const sqsList = {
  label: fieldLabel.list,
  url: (id: string) => `/opportunities/${id}/sqs`,
  labelIcon: ListAltIcon,
  model: appModel.sqs,
  permission: appPermission.list,
  nodeId: uuid()
};

const sqs: ObjectType = {
  label: fieldLabel.sqsMessage,
  labelIcon: QueueIcon,
  subMenuItems: [sqsList],
  nodeId: uuid()
};

const transactionList = {
  label: fieldLabel.transactionHistory,
  url: (id: string) => `/opportunities/${id}/transaction-histories`,
  labelIcon: ReceiptLongIcon,
  model: 'TRANSACTION_HISTORY',
  permission: appPermission.list,
  nodeId: uuid()
};


const getActions = (data: ObjectType) => {
  const actions = [];
  const statusActions =
    brokerageActionItems[data.opportunity_status_c || 'default'];

  for (const key of Object.keys(statusActions)) {
    const url = (id: string) =>
      `/opportunities/${id}/actions/${key}`;

    actions.push({
      label: statusActions[key],
      url: url(data.id),
      labelIcon: ListAltIcon,
      model: 'Opportunity',
      permission: appPermission.edit,
      nodeId: uuid()
    });
  }

  return actions;
};

const brokerageActions = (opportunityModel: ObjectType) => {
  let actions;
  const state = opportunityModel.opportunity_status_c || 'default';

  if (brokerageActionItems[state]) {
    actions = {
      label: 'Actions',
      labelIcon: CallToActionIcon,
      subMenuItems: getActions(opportunityModel),
      nodeId: uuid()
    };
    return actions;
  }

  return {};
};

const setSqsRetriggerMenu = (marketPreference: MarketPreferenceEntity) => {

  if (!isEmpty(marketPreference)) {
    const checkForSQSStatusTriggerValue = (value: string) =>
      isEmpty(marketPreference.sqs_status_trigger) || !marketPreference.sqs_status_trigger.includes(value);
    sqs.subMenuItems = [sqsList, {
      label: fieldLabel.reTriggerIntegrationMessageOfferMade,
      url: (id: string) =>
        `/opportunities/${id}/sqs/re_trigger_integration_message_offer_made`,
      labelIcon: LocalOfferIcon,
      model: appModel.opportunity,
      permission: appPermission.edit,
      nodeId: uuid(),
      disabled: checkForSQSStatusTriggerValue(fieldLabel.offerMakeOffer)
    },
      {
        label: fieldLabel.reTriggerIntegrationMessageOfferAccepted,
        url: (id: string) =>
          `/opportunities/${id}/sqs/re_trigger_integration_message_offer_accepted`,
        labelIcon: LocalOfferIcon,
        model: appModel.opportunity,
        permission: appPermission.edit,
        nodeId: uuid(),
        disabled: checkForSQSStatusTriggerValue(
          fieldLabel.closingDiligencePeriod
        )
      }];
  }
};

const opportunityMenuItems = {
  view: [
    opportunityMenu,
    email,
    document,
    service,
    party,
    noteChat,
    brokerageUsers,
    generateContract,
    callList,
    taskList,
    sqs,
    transactionList,
    viewChangelog
  ],
  brokerageActions: brokerageActions,
  setSqsRetriggerMenu: setSqsRetriggerMenu
};

export default opportunityMenuItems;