import { buyer_rep_pct } from './commission-buyer-rep-pct';
import { buyer_source_pct } from './commission-buyer-source-pct';
import { negotiator_commission_amount } from './commission-negotiator-commission-amount';
import { seller_rep_pct } from './commission-seller-rep-pct';
import { seller_source_pct } from './commission-seller-source-pct';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const negotiator_commission_pct = {
  calculate: function (
    opportunity: OpportunityViewEntity,
  ) {
    const buyerSourcePct = buyer_source_pct.calculate(
      opportunity
    );
    const buyerRepPct = buyer_rep_pct.calculate(
      opportunity
    );
    const sellerSourcePct = seller_source_pct.calculate(
      opportunity
    );
    const sellerRepPct = seller_rep_pct.calculate(
      opportunity
    );

    return (
      buyerSourcePct + buyerRepPct + sellerSourcePct + sellerRepPct
    );
  },

  handleChange: function (oppurtyunity: OpportunityViewEntity) {
    const result = {
      negotiator_commission_amount:
        negotiator_commission_amount.calculate(
          oppurtyunity
        )
    };

    return result;
  }
};
