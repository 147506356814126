import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';


export const short_sale_seller_accept_date = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];
    if (
      [oppurtunityStatusList.offer_short_sale_offer_accepted].includes(
        opportunity?.opportunity_status_c
      ) && isEmpty(opportunity?.short_sale_seller_accept_date)
    ) {
      errors.push(
        missingField(
          'Short Sale Seller Accept Date must be completed before moving status to Offer: Short Sale Offer Accepted.'
        )
      );
    }

    return errors;
  }
};
