import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { isEmpty } from 'helpers/misc-helper';

export const contract_termination_reasons = {
  validate: function (opportunity: OpportunityViewEntity, status: string) {
    const errors: string[] = [];

    if (status === 'edit') {
      if (
        [
          oppurtunityStatusList.closing_cancel_contract,
          oppurtunityStatusList.closing_termination_pending
        ].includes(opportunity?.opportunity_status_c) &&
        isEmpty(opportunity?.contract_termination_reasons)
      ) {
        errors.push(missingField('Contract Termination Reasons'));
      }
    }

    if (status === 'action') {


      if (isEmpty(opportunity?.contract_termination_reasons)) {
        errors.push(missingField('Contract Termination Reasons'));
      }
    }

    return errors;
  }
};
