import cancelReasons from 'assets/constants/cancel-reasons';
import cancelSubReasons from 'assets/constants/cancel-sub-reasons';
import fieldLabel from 'assets/constants/fieldLabel';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import SaveButton from 'components/form/button-save';
import FormContainer from 'components/form/container';
import UnitDate from 'components/form/unit-date';
import UnitSelect from 'components/form/unit-select';
import UnitText from 'components/form/unit-text';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import StackRow from 'components/stack/stack-row';
import { brokerageActionEvent } from 'event/brokerage-action-event';
import { refreshOpportunity } from 'event/opportunity-event';
import DateUtility from 'helpers/date-helper';
import emptyFunction from 'helpers/empty-function-helper';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import brokerageActionService from 'services/brokerage-action-service';
import { initialCancelOffer } from 'state/brokerage-actions/cancel-offer';
import {
  ActionRecordViewPropTypes,
  CancelOfferEntity,
  CancelOfferPaylodEntity
} from 'types/brokerage-action-types';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { OptionType } from 'types/option-type';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  isModal = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [data, setData] = useState<CancelOfferEntity>(initialCancelOffer);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancelReasonSubOption, setCancelReasonSubOption] = useState<
    OptionType[]
  >([]);

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = (): boolean => {
    const fieldTovalidate = ['cancel_request_received_date', 'cancel_reason'];

    if (cancelReasonSubOption.length > 0) {
      fieldTovalidate.push('cancellation_reason_subcategory');
    }

    const { isValid, errors } = validateBrokerageAction(data, fieldTovalidate);

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateData();

    if (!isValid) return;

    const reqBody: CancelOfferPaylodEntity = {
      offer_cancelled_date: data.offer_cancelled_date,
      cancel_request_received_date: data.cancel_request_received_date,
      cancel_reason: data.cancel_reason,
      cancellation_reason_subcategory: data.cancellation_reason_subcategory,
      cancel_note: data.cancel_note
    };

    setIsLoading(true);

    const result = await brokerageActionService.update<CancelOfferPaylodEntity>(
      opportunity.id,
      reqBody,
      'cancel_offer'
    );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.cancel_offer();
      } else {
        navigate(`/opportunities/${opportunity.id}/view`);
        refreshOpportunity();
      }
    }
  };

  useEffect(() => {
    setCancelReasonSubOption(cancelSubReasons[data.cancel_reason] || []);
  }, [data.cancel_reason]);

  useEffect(() => {
    setData({
      offer_cancelled_date:
        opportunity.offer_cancelled_date || DateUtility.getTodayDateString(),
      cancel_request_received_date: opportunity.cancel_request_received_date,
      cancel_reason: opportunity.cancel_reason,
      cancellation_reason_subcategory:
        opportunity.cancellation_reason_subcategory,
      cancel_note: opportunity.cancel_note,
      opportunity_status_c: oppurtunityStatusList.offer_canceled
    });
  }, [opportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 45vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer id="cancel_offer_form">
            <UnitDate
              label={fieldLabel.cancelDate}
              name="offer_cancelled_date"
              value={data.offer_cancelled_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'offer_cancelled_date', value: e }
                })
              }
              disabled
            />

            <UnitDate
              label={fieldLabel.cancelRequestReceivedDate}
              name="cancel_request_received_date"
              value={data.cancel_request_received_date ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'cancel_request_received_date', value: e }
                })
              }
              error={validation['cancel_request_received_date'] ?? ''}
              required
            />

            <UnitSelect
              name="cancel_reason"
              label={fieldLabel.cancelReason}
              records={getObjectEntriesAsArray(cancelReasons)}
              value={data.cancel_reason ?? ''}
              onChange={handleChange}
              error={validation['cancel_reason'] ?? ''}
              required
            />

            <UnitSelect
              name="cancellation_reason_subcategory"
              label={fieldLabel.cancelSubReason}
              records={cancelReasonSubOption}
              value={data.cancellation_reason_subcategory ?? ''}
              onChange={handleChange}
              error={validation['cancellation_reason_subcategory'] ?? ''}
            />

            <UnitText
              name={'cancel_note'}
              label={fieldLabel.cancelNote}
              value={data.cancel_note ?? ''}
              onChange={handleChange}
              multiline
              rows={4}
              grid={{ xs: 12, sm: 12 }}
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ p: 0 }}>
            <SaveButton onClick={handleSubmit} disabled={isLoading} />
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
