
import { negotiator_commission_pct } from './commission-negotiator-commission-pct';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const seller_rep_pct = {
  calculate: (opportunity: OpportunityViewEntity) => {
    if (opportunity?.seller_rep == 'yes') {
      return 7.5;
    }
    return 0;
  },

  handleChange: function (
    opportunity: OpportunityViewEntity
  ) {
    const result = {
      negotiator_commission_pct:
        negotiator_commission_pct.calculate(
          opportunity
        )
    };

    return result;
  }
};
