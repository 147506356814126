import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const escrow_company_phone_c = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    const stateList = [
      oppurtunityStatusList.offer_accepted,
      oppurtunityStatusList.closing_diligence_amended,
      oppurtunityStatusList.closing_sale_pending,
      oppurtunityStatusList.closing_sale_pending_amended,
      oppurtunityStatusList.closing_clear_to_close
    ];

    if (
      stateList.includes(opportunity?.opportunity_status_c) &&
      isEmpty(opportunity.escrow.mobile)
    ) {
      errors.push(missingField('Escrow Company Phone'));
    }

    return errors;
  }
};
