import emptyFunction from 'helpers/empty-function-helper';
import { createContext } from 'react';
import initialMarketPreference from 'state/market-preference';
import { initialViewOpportunity } from 'state/opportunity/initial-view-opportunity';
import { DispatchSetState } from 'types/common-types';
import { MarketPreferenceEntity } from 'types/market-preference-types';
import { OpportunityViewEntity } from 'types/opportunity-types';


export const OpportunityContext = createContext<{
  originalOpportunity: OpportunityViewEntity,
  setOriginalOpportunity: DispatchSetState<OpportunityViewEntity>,
  marketPreference: MarketPreferenceEntity
}>({
  originalOpportunity: initialViewOpportunity,
  setOriginalOpportunity: emptyFunction,
  marketPreference: initialMarketPreference
});
