import { getAdditionalInfo } from './utility';
import { isEmpty } from 'helpers/misc-helper';
import { moduleLabels } from 'assets/list/tracker/constant';
import { ObjectType } from 'types';
import { OpportunityContext } from './Context';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from 'features/breadcrumbs';
import CenterBox from 'components/box/center-box';
import CircularLoader from 'components/dog-loader/dog-lodar';
import ContainerRight from 'components/container/right';
import ErrorComponent from 'components/errors/error-component';
import eventBus from 'helpers/event-bus-helper';
import EVENTS from 'assets/constants/events';
import FormContainer from 'components/form/container';
import LayoutProvider from 'pages/common-layout/layout-provider-context';
import menuHelper from 'helpers/menu-helper';
import opportunityMenuItems from 'assets/menus/side-menu/opportunity';
import opportunityService from 'services/opportunity-service';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import React, { useContext, useEffect, useState } from 'react';
import TopBar from 'components/top-bar';
import trackerService from 'services/tracker-service';
import UnitItem from 'components/form/unit-item';
import usePageTitle from 'hooks/usePageTitle';
import { initialViewOpportunity } from 'state/opportunity/initial-view-opportunity';
import { MarketPreferenceEntity } from 'types/market-preference-types';
import initialMarketPreference from 'state/market-preference';
import marketPreferenceService from 'services/market-preference-service';

const OpportunityView: React.FC = () => {
  const { opportunity_id } = useParams<ObjectType>();
  const location = useLocation();

  const [originalOpportunity, setOriginalOpportunity] =
    useState<OpportunityViewEntity>(initialViewOpportunity);

  const [marketPreference, setMarketPreference] =
    useState<MarketPreferenceEntity>(initialMarketPreference);

  const [routeName, setRouteName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isConditionLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { setSideNavMenuItems, setTopBar, topBar } = useContext(LayoutProvider);

  const loadOpputunity = async (
    id: string,
    eventIsConditionLoading?: boolean
  ) => {
    if (!eventIsConditionLoading) setIsLoading(true);

    const result = await opportunityService.get(id);

    if (result.isError) {
      setErrorMessage(result.errorMessage.message);
      return;
    }

    setErrorMessage('');

    if (result.data.market_preference_id) {
      const response = await marketPreferenceService.get(
        result.data.market_preference_id
      );
      setMarketPreference(response.data);
    }
    setOriginalOpportunity(result.data);

    trackerService.createRecentlyViewed({
      module_name: moduleLabels.Opportunities.label,
      item_id: opportunity_id,
      item_summary: result.data.name,
      action: 'editview'
    });

    const { mainFields, assotiatedFields } = await getAdditionalInfo(
      result.data
    );

    setOriginalOpportunity((prevOpportunity) => ({
      ...prevOpportunity,
      ...mainFields,
      ...assotiatedFields
    }));

    if (!eventIsConditionLoading) setIsLoading(false);
  };

  usePageTitle(`${originalOpportunity.name ?? ''}`);

  useEffect(() => {
    if (location.pathname.includes('actions')) {
      if (opportunity_id !== undefined) loadOpputunity(opportunity_id);
    }
  }, [location]);

  useEffect(() => {
    if (opportunity_id !== undefined) loadOpputunity(opportunity_id);

    eventBus.on(EVENTS.UPDATE_OPPORTUNITY, (data?: ObjectType) => {
      if (opportunity_id !== undefined)
        loadOpputunity(opportunity_id, data?.isConditionLoading);
    });
  }, []);

  const getMenus = async (data: ObjectType) => {
    let menus = [];
    let menusToshow = [];

    menus = await menuHelper.getItems('Opportunities', 'view');

    const actions = opportunityMenuItems.brokerageActions(data);
    opportunityMenuItems.setSqsRetriggerMenu(data.market_preference);

    menusToshow = menus.map(function (item) {
      let subMenuItemsWithURL;

      if (item.subMenuItems && item.subMenuItems.length > 0) {
        subMenuItemsWithURL = item.subMenuItems.map(function (
          subMenuItem: ObjectType
        ) {
          return {
            ...subMenuItem,
            url:
              typeof subMenuItem.url === 'function'
                ? subMenuItem.url(opportunity_id)
                : ''
          };
        });
      }

      return {
        ...item,
        subMenuItems: subMenuItemsWithURL,
        url: typeof item.url === 'function' ? item.url(opportunity_id) : ''
      };
    });

    menusToshow.splice(1, 0, actions);
    setSideNavMenuItems(menusToshow);
  };

  useEffect(() => {
    getMenus(originalOpportunity);
  }, [originalOpportunity.opportunity_status_c]);

  useEffect(() => {
    if (!location.pathname.includes('edit')) setTopBar(null);
  }, [routeName]);

  if (!isEmpty(errorMessage))
    return (
      <PaperBox>
        <PaperBoxContent>
          {' '}
          <ErrorComponent message={errorMessage} />{' '}
        </PaperBoxContent>
      </PaperBox>
    );

  return (
    <React.Fragment>
      <OpportunityContext.Provider
        value={{
          originalOpportunity: originalOpportunity,
          setOriginalOpportunity: setOriginalOpportunity,
          marketPreference: marketPreference
        }}
      >
        {!isLoading && !isConditionLoading ? (
          <>
            <TopBar
              sx={{
                '.MuiGrid-root.MuiGrid-container': {
                  margin: 0,
                  height: '7vh'
                },
                '.MuiGrid-root.MuiGrid-item': {
                  padding: '13px'
                }
              }}
            >
              <FormContainer>
                <UnitItem grid={{ xs: 12, sm: 8, md: 8 }}>
                  <Breadcrumbs borderBottom={0} />
                </UnitItem>
                <UnitItem grid={{ xs: 12, sm: 4, md: 4 }}>{topBar}</UnitItem>
              </FormContainer>
            </TopBar>
            <ContainerRight>
              <Outlet context={{ routeName, setRouteName }} />
            </ContainerRight>
          </>
        ) : (
          <CenterBox>
            <CircularLoader />
          </CenterBox>
        )}
      </OpportunityContext.Provider>
    </React.Fragment>
  );
};

export default OpportunityView;
