import { isEmpty } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const offer_expiration_date_c = {
  calculate: (opportunity: OpportunityViewEntity) => {
    if (
      isEmpty(opportunity.offer_expiration_date_c) &&
      !isEmpty(opportunity.close_date_c)
    ) {
      return opportunity.close_date_c;
    }
    return opportunity.offer_expiration_date_c;
  }
};
