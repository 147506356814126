import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';

export const seller_name_c = {
  validate: function (opportunity: OpportunityViewEntity, status: string) {
    const errors: string[] = [];

    const stateList = [
      oppurtunityStatusList.offer_accepted,
      oppurtunityStatusList.closing_diligence_amended,
      oppurtunityStatusList.closing_sale_pending,
      oppurtunityStatusList.closing_sale_pending_amended,
      oppurtunityStatusList.closing_clear_to_close
    ];

    const isSellerEmpty = status === "edit" ? isEmpty(opportunity.seller.name) : isEmpty(opportunity.seller_name_c);

    const isDirty = stateList.includes(opportunity?.opportunity_status_c) &&
      !isEmpty(opportunity?.offer_finalized_at) &&
      isSellerEmpty

    if (isDirty) {
      errors.push(missingField('Seller Name'));
    }

    return errors;
  },

};
