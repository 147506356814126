
import { initial_commission_amount } from './initial_commission_amount';
import opportunityHelper from 'helpers/opportunity-helper';
import { convertNumber } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const initial_commission_percentage = {
  handleChange: (opportunity: OpportunityViewEntity) => {

    let data = { ...opportunity };

    const initialCommissionAmount = initial_commission_amount.calculate(data);
    data = {
      ...data,
      initial_commission_amount: initialCommissionAmount ?? 0
    };

    const commissions = opportunityHelper.calculateCommission(data);
    const result = {
      initial_commission_amount: initialCommissionAmount,
      ...commissions
    }

    return result;
  },


  calculate: function (
    opportunity: OpportunityViewEntity
  ) {

    if (opportunity?.initial_commission_percentage_changed) {
      return opportunity?.initial_commission_percentage;
    }

    const initialCommissionAmount = convertNumber(opportunity?.initial_commission_amount);

    const priceForCalculation = opportunityHelper.getCurrentClosingPrice(opportunity);

    const result = (initialCommissionAmount / priceForCalculation) * 100;

    return result;
  }
};
