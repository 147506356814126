import Box from '@mui/material/Box';
import EVENTS from 'assets/constants/events';
import opportunityDetailViewTabs from 'assets/constants/opportunity-view-tabs';
import tabFields from 'assets/tab-fields';
import CircularLoader from 'components/dog-loader/dog-lodar';
import CancelButton from 'components/form/button-cancel';
import SaveButton from 'components/form/button-save';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import TabArea from 'components/tabs/tab-area';
import eventBus from 'helpers/event-bus-helper';
import LayoutProvider, {
  LayoutProviderInterface
} from 'pages/common-layout/layout-provider-context';
import { OpportunityContext } from 'pages/opportunity/Context';
import {
  saveOpportunityCommission,
  validateOpportunity
} from 'pages/opportunity/utility';
import useRouteName from 'pages/route-outlet-context';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as validationService from 'services/validation-service';
import { ObjectType } from 'types';

import federalHolidayHelper from 'helpers/federal-holiday-helper';
import { InputChangeEvent } from 'types/common-types';
import BasicRecordView from '../record-view/basic';
import PrimaryInformationRecordView from '../record-view/primary-information';
import { OpportunityViewEntity } from 'types/opportunity-types';
import NotesTermsProvisionsRecordView from '../record-view/notes-terms-provisions';
import CharacterSticsRecordView from '../record-view/characteristics';
import { initialViewOpportunity } from 'state/opportunity/initial-view-opportunity';
import ContactInformationRecordView from '../record-view/contact-information';
import FinancialInformationRecordView from '../record-view/financial-information';
import DiligencePeriodRecordView from '../record-view/diligence-period';
import SettlementRecordView from '../record-view/settlement';
import OffMarketRecordView from '../record-view/off-market';
import opportunityService from 'services/opportunity-service';
import opportunityHelper from 'helpers/opportunity-helper';

const TabLabel = ({ title, index, validation }: ObjectType) => {
  const errorFields = Object.keys(validation);
  const hasError = tabFields[index]?.some(
    (x: string) => errorFields.includes(x) && validation[x].length > 0
  );
  return (
    <span style={{ display: 'inline', color: hasError ? 'red' : 'inherit' }}>
      {title}
    </span>
  );
};

const OppurtunityDetailEdit = ({ routeTag }: { routeTag: string }) => {
  const { opportunity_id } = useParams<ObjectType>();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const { originalOpportunity } = useContext(OpportunityContext);

  const [opportunity, setOpportunity] = useState<OpportunityViewEntity>(
    initialViewOpportunity
  );
  const { setTopBar } = useContext<LayoutProviderInterface>(LayoutProvider);

  const { setRouteName } = useRouteName();
  const navigate = useNavigate();
  const [validation, setValidation] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [field, setField] = useState('');
  const tabId = 'opportunity-edit';
  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  const handleChange = (e: InputChangeEvent) => {
    if (e.target) {
      setOpportunity(
        Object.assign({}, opportunity, { [e.target.name]: e.target.value })
      );
    } else {
      setOpportunity(Object.assign({}, opportunity, e));
    }
  };

  const handleChangeWihEffect = (e: InputChangeEvent) => {
    setOpportunity((prevOpportunity) => ({
      ...prevOpportunity,
      [e.target.name]: e.target.value
    }));
    setField(e.target.name);
  };

  const updateValidation = (obj: ObjectType) => {
    setValidation(Object.assign({}, validation, obj));
  };

  const handleSubmit = async () => {
    const { status, ...errors } = validateOpportunity(
      opportunity,
      originalOpportunity
    );
    setValidation(errors);

    if (!status) {
      const reqBody = opportunityHelper.preparePayload(opportunity);

      setIsLoading(true);

      const result = await opportunityService.update(opportunity_id, reqBody);

      setIsLoading(false);

      if (reqBody.data_source === 'off_market') {
        saveOpportunityCommission(opportunity);
      }

      if (result.isSuccess) {
        eventBus.dispatch(EVENTS.SHOW_TOAST, {
          message: 'Update Success',
          isError: false
        });
        eventBus.dispatch('refresh_opportunity', {});
        navigate(`/opportunities/${opportunity_id}/view`);
      }

      if (result.isError) {
        eventBus.dispatch(EVENTS.SHOW_TOAST, {
          message: result.errorMessage,
          isError: true
        });
      }
    }
  };

  const tabItems = () => {
    let items = {};
    delete opportunityDetailViewTabs.off_market;
    for (const item in opportunityDetailViewTabs) {
      items = {
        ...items,
        [item]: (
          <TabLabel
            title={opportunityDetailViewTabs[item]}
            index={item}
            validation={validation}
          />
        )
      };
    }

    if (opportunity?.data_source && opportunity?.data_source === 'off_market') {
      items = {
        ...items,
        off_market: (
          <TabLabel
            title={'Off Market'}
            index={'off_market'}
            validation={validation}
          />
        )
      };
    }

    return items;
  };

  const performAfterEffect = async (field: string) => {
    let result: Partial<OpportunityViewEntity> = {};
    if (validationService.validation[field]?.handleChange) {
      result = await validationService.validation[field]?.handleChange(
        opportunity,
        originalOpportunity
      );
      setOpportunity((prevOpportunity) => ({
        ...prevOpportunity,
        ...result
      }));
    }

    if (validationService.validation[field]?.validate) {
      const errors = validationService.validation[field]?.validate(
        opportunity,
        'edit',
        originalOpportunity
      );
      updateValidation({ [field]: errors ?? [] });
    }

    for (const key in result) {
      if (validationService.validation[key]?.handleChange) {
        setField(key);
      }
    }

    setField('none');
  };

  const TopBar = (
    <>
      <Box
        sx={{
          justifyContent: 'flex-end',
          display: 'flex',
          paddingTop: '0'
        }}
      >
        <Box pr={2}>
          <SaveButton onClick={handleSubmit} disabled={isLoading} />
        </Box>
        <Box pr={4}>
          <CancelButton
            onClick={() => {
              navigate(`/opportunities/${opportunity_id}/view`);
            }}
          />
        </Box>
      </Box>
    </>
  );

  useEffect(() => {
    performAfterEffect(field);
  }, [field]);

  useEffect(() => {
    setRouteName(routeTag);

    federalHolidayHelper.loadFederalHolidays();

    return () => {
      setOpportunity(originalOpportunity);
    };
  }, []);

  useEffect(() => {
    setTopBar(TopBar);
  }, [opportunity]);

  useEffect(() => {
    setOpportunity(originalOpportunity);
  }, [originalOpportunity.id]);

  return (
    <>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <HorizontalTabs
            value={activeTabIndex}
            handleChange={changeTab}
            tabItems={tabItems()}
            tabId={tabId}
            sx={{ height: '5vh' }}
          />

          <PaperBox sx={{ mt: 1, overflow: 'auto' }}>
            <PaperBoxContent>
              <TabArea
                index={0}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <BasicRecordView
                  data={opportunity}
                  originalOpportunity={originalOpportunity}
                  validation={validation}
                  readOnly={false}
                  handleChange={handleChange}
                  handleChangeWihEffect={handleChangeWihEffect}
                  setOpportunity={setOpportunity}
                />{' '}
              </TabArea>
              <TabArea
                index={1}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <PrimaryInformationRecordView
                  data={opportunity}
                  originalOpportunity={originalOpportunity}
                  validation={validation}
                  readOnly={false}
                  handleChangeWihEffect={handleChangeWihEffect}
                  handleChange={handleChange}
                  setOpportunity={setOpportunity}
                />
              </TabArea>
              <TabArea
                index={2}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <NotesTermsProvisionsRecordView
                  data={opportunity}
                  handleChange={handleChange}
                  readOnly={false}
                />
              </TabArea>
              <TabArea
                index={3}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <CharacterSticsRecordView
                  data={opportunity}
                  validation={validation}
                  handleChange={handleChange}
                  readOnly={false}
                />
              </TabArea>
              <TabArea
                index={4}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <ContactInformationRecordView
                  data={opportunity}
                  validation={validation}
                  handleChange={handleChange}
                  handleChangeWihEffect={handleChangeWihEffect}
                  originalOpportunity={originalOpportunity}
                  setOpportunity={setOpportunity}
                  readOnly={false}
                />
              </TabArea>
              <TabArea
                index={5}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <FinancialInformationRecordView
                  data={opportunity}
                  validation={validation}
                  handleChange={handleChange}
                  handleChangeWihEffect={handleChangeWihEffect}
                  originalOpportunity={originalOpportunity}
                  readOnly={false}
                />
              </TabArea>
              <TabArea
                index={6}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <DiligencePeriodRecordView
                  data={opportunity}
                  validation={validation}
                  handleChange={handleChange}
                  handleChangeWihEffect={handleChangeWihEffect}
                  originalOpportunity={originalOpportunity}
                  setOpportunity={setOpportunity}
                  readOnly={false}
                />
              </TabArea>
              <TabArea
                index={7}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <SettlementRecordView
                  data={opportunity}
                  validation={validation}
                  handleChange={handleChange}
                  handleChangeWihEffect={handleChangeWihEffect}
                  originalOpportunity={originalOpportunity}
                  setOpportunity={setOpportunity}
                  readOnly={false}
                  setField={setField}
                />
              </TabArea>
              <TabArea
                index={8}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <OffMarketRecordView
                  data={opportunity}
                  validation={validation}
                  handleChange={handleChange}
                  handleChangeWihEffect={handleChangeWihEffect}
                  originalOpportunity={originalOpportunity}
                  setOpportunity={setOpportunity}
                  readOnly={false}
                  setField={setField}
                />
              </TabArea>

              <StackRowWithDivider>
                <SaveButton onClick={handleSubmit} disabled={isLoading} />
                <CancelButton
                  onClick={() => {
                    navigate(`/opportunities/${opportunity_id}/view`);
                  }}
                />
              </StackRowWithDivider>
            </PaperBoxContent>
          </PaperBox>
        </>
      )}
    </>
  );
};

export default OppurtunityDetailEdit;
