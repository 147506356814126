import React, { useContext, useEffect } from 'react';
import { opportunityDetailViewTabs } from 'assets/tabs/opportunity-view';
import { OpportunityContext } from 'pages/opportunity/Context';
import HorizontalTabs from 'components/tabs/horizontal-tabs';
import TabArea from 'components/tabs/tab-area';
import useRouteName from 'pages/route-outlet-context';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import { Box, Grid } from '@mui/material';
import NegotiatorNotes from 'features/brokerage-actions/negotiator-notes';
import ButtonTab from 'components/button/button-tab';
import FormContainer from 'components/form/container';
import UnitItem from 'components/form/unit-item';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import fieldLabel from 'assets/constants/fieldLabel';
import CancelIcon from '@mui/icons-material/Cancel';
import TransactionNotes from 'features/brokerage-actions/transaction-notes';
import ClientChat from 'features/brokerage-actions/client-chat';
import BrokerageNotes from 'features/dashboards/brokerage-notes';
import BasicRecordView from '../record-view/basic';
import PrimaryInformationRecordView from '../record-view/primary-information';
import NotesTermsProvisionsRecordView from '../record-view/notes-terms-provisions';
import CharacterSticsRecordView from '../record-view/characteristics';
import ContactInformationRecordView from '../record-view/contact-information';
import FinancialInformationRecordView from '../record-view/financial-information';
import DiligencePeriodRecordView from '../record-view/diligence-period';
import SettlementRecordView from '../record-view/settlement';
import OffMarketRecordView from '../record-view/off-market';

const OppurtunityDetailView = ({ routeTag }: { routeTag: string }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const { originalOpportunity } = useContext(OpportunityContext);

  const [hideRightGrid, setHideRightGrid] = React.useState(true);
  const [rightSideComponentName, setRightSideComponentName] =
    React.useState('');
  const [hideRightSideComponent, setHideRightSideComponent] =
    React.useState(true);

  const getRightSideCompoent1 = (componentName: string) => {
    switch (componentName) {
      case 'NegotiatorNotes':
        return <NegotiatorNotes />;
      case 'TransactionNotes':
        return <TransactionNotes opportunity={originalOpportunity} />;
      case 'ClientChat':
        return <ClientChat />;
      case 'BrokerageNotes':
        return <BrokerageNotes />;
      default:
        <></>;
        break;
    }
  };

  const { setRouteName } = useRouteName();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };
  const toggelRightSideGrid = () => {
    setHideRightGrid(!hideRightGrid);
  };

  const toggelRightSideComponent = () => {
    setHideRightSideComponent(!hideRightSideComponent);
  };

  const showRightSideComponent = (componentName: string) => {
    setHideRightSideComponent(false);
    setRightSideComponentName(componentName);
  };

  const tabId = 'opportunity-view';
  const tabItems = () => {
    if (
      originalOpportunity?.data_source &&
      originalOpportunity?.data_source === 'off_market'
    ) {
      return {
        ...opportunityDetailViewTabs,
        ...{ off_market: 'Off Market' }
      };
    }

    return opportunityDetailViewTabs;
  };

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          md={hideRightGrid ? 12 : 6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 14vh)'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Box sx={{ width: '100%' }}>
              <HorizontalTabs
                value={activeTabIndex}
                handleChange={handleChange}
                tabItems={tabItems()}
                tabId={tabId}
              />
            </Box>

            {hideRightGrid === true && (
              <Box sx={{ flexShrink: 0 }}>
                <ButtonTab onClick={toggelRightSideGrid}>
                  <ArrowCircleLeftIcon />
                </ButtonTab>
              </Box>
            )}
          </Box>

          <PaperBox
            sx={{
              pb: 2,
              mt: 1,
              overflow: 'auto'
            }}
          >
            <PaperBoxContent>
              <TabArea
                index={0}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <BasicRecordView
                  originalOpportunity={originalOpportunity}
                  data={originalOpportunity}
                  readOnly={true}
                />
              </TabArea>
              <TabArea
                index={1}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <PrimaryInformationRecordView
                  data={originalOpportunity}
                  originalOpportunity={originalOpportunity}
                  readOnly={true}
                />
              </TabArea>
              <TabArea
                index={2}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <NotesTermsProvisionsRecordView
                  data={originalOpportunity}
                  readOnly={true}
                />
              </TabArea>
              <TabArea
                index={3}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <CharacterSticsRecordView
                  data={originalOpportunity}
                  readOnly={true}
                />
              </TabArea>
              <TabArea
                index={4}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <ContactInformationRecordView
                  data={originalOpportunity}
                  originalOpportunity={originalOpportunity}
                  readOnly={true}
                />
              </TabArea>
              <TabArea
                index={5}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <FinancialInformationRecordView
                  data={originalOpportunity}
                  originalOpportunity={originalOpportunity}
                  readOnly={true}
                />
              </TabArea>
              <TabArea
                index={6}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <DiligencePeriodRecordView
                  data={originalOpportunity}
                  originalOpportunity={originalOpportunity}
                  readOnly={true}
                />
              </TabArea>
              <TabArea
                index={7}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <SettlementRecordView
                  data={originalOpportunity}
                  originalOpportunity={originalOpportunity}
                  readOnly={true}
                />
              </TabArea>
              <TabArea
                index={8}
                value={activeTabIndex}
                border={false}
                tabId={tabId}
              >
                <OffMarketRecordView
                  data={originalOpportunity}
                  originalOpportunity={originalOpportunity}
                  readOnly={true}
                />
              </TabArea>
            </PaperBoxContent>
          </PaperBox>
        </Grid>

        {hideRightGrid === false && hideRightSideComponent === true && (
          <Grid
            item
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 14vh)'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ width: '100%' }}></Box>
              <Box sx={{ flexGrow: 1 }}>
                <ButtonTab onClick={toggelRightSideGrid}>
                  <ArrowCircleRightIcon />
                </ButtonTab>
              </Box>
            </Box>

            <PaperBox
              sx={{
                pb: 2,
                mt: 1,
                overflow: 'auto'
              }}
            >
              <PaperBoxContent>
                <FormContainer>
                  <UnitItem grid={{ xs: 3, sm: 3, md: 3, lg: 3 }}>
                    <ButtonTab
                      onClick={() => showRightSideComponent('NegotiatorNotes')}
                      sx={{ width: '100%' }}
                    >
                      {fieldLabel.negotiationNotes}
                    </ButtonTab>
                  </UnitItem>

                  <UnitItem grid={{ xs: 3, sm: 3, md: 3, lg: 3 }}>
                    <ButtonTab
                      onClick={() => showRightSideComponent('TransactionNotes')}
                      sx={{ width: '100%' }}
                    >
                      {fieldLabel.transactionNotes}
                    </ButtonTab>
                  </UnitItem>

                  <UnitItem grid={{ xs: 3, sm: 3, md: 3, lg: 3 }}>
                    <ButtonTab
                      onClick={() => showRightSideComponent('BrokerageNotes')}
                      sx={{ width: '100%' }}
                    >
                      {fieldLabel.brokerageNotes}
                    </ButtonTab>
                  </UnitItem>

                  <UnitItem grid={{ xs: 3, sm: 3, md: 3, lg: 3 }}>
                    <ButtonTab
                      onClick={() => showRightSideComponent('ClientChat')}
                      sx={{ width: '100%' }}
                    >
                      {fieldLabel.clientChat}
                    </ButtonTab>
                  </UnitItem>
                </FormContainer>
              </PaperBoxContent>
            </PaperBox>
          </Grid>
        )}

        {hideRightSideComponent === false && (
          <Grid
            item
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 14vh)'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ width: '100%' }}>
                <ButtonTab>{rightSideComponentName}</ButtonTab>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <ButtonTab onClick={toggelRightSideComponent}>
                  <CancelIcon />
                </ButtonTab>
              </Box>
            </Box>
            <Box
              sx={{
                p: 0,
                mt: 1,
                overflow: 'auto'
              }}
            >
              {getRightSideCompoent1(rightSideComponentName)}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default OppurtunityDetailView;
