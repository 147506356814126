import { seller_rep } from './commission-seller-rep';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const parties_seller_representative_name = {

  handleChange: (opportunity: OpportunityViewEntity) => {
    const result = {
      seller_rep: seller_rep.calculate(
        opportunity
      )
    };

    return result;
  }
};
