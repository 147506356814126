import fieldLabel from 'assets/constants/fieldLabel';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';


export const market_preference_id = {
  validate: (
    opportunity: OpportunityViewEntity
  ) => {
    const errors: string[] = [];

    if (isEmpty(opportunity?.market_preference_id)) {
      errors.push(missingField(fieldLabel.marketPreference));
    }

    return errors;
  }
};
