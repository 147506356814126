import envConfig from 'config/env';
import {
  ForgotPassword,
  ForgotResetPassword
} from 'types/forgot-password-type';
import httpService from './http-service';
import { CreateResponse, GetResponse } from 'types/api-response-types';

const service = {
  url: `/${envConfig.REACT_APP_API_VERSION_1}/oauth/password`,

  send: async (
    payload: ForgotPassword
  ): Promise<CreateResponse<ForgotPassword>> => {
    const result = await httpService.post<ForgotPassword>(
      `${service.url}/email`,
      payload
    );
    return result;
  },
  show: async (token: string): Promise<GetResponse<ForgotPassword>> => {
    const result = await httpService.get<ForgotPassword>(
      `${service.url}/reset/${token}`
    );
    return result;
  },
  reset: async (token: string, payload: ForgotResetPassword) => {
    const result = await httpService.post<ForgotResetPassword>(
      `${service.url}/reset/${token}`,
      payload
    );
    return result;
  }
};

export default service;
