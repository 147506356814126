import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const entera_contribution = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    const stateList = [
      oppurtunityStatusList.closing_diligence_period,
      oppurtunityStatusList.closing_diligence_amended
    ];

    if (
      stateList.includes(opportunity?.opportunity_status_c) &&
      (!opportunity?.entera_contribution ||
        opportunity?.entera_contribution.trim().length === 0)
    ) {
      errors.push(missingField('Entera Contribution'));
    }

    return errors;
  }
};
