import fieldLabel from 'assets/constants/fieldLabel';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';
import moment from 'moment-timezone';

export const forecasted_close_date = {
  validate: function (opportunity: OpportunityViewEntity, status: string, originalOpportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (status == "edit") {

      const close_date = opportunity?.close_date_c;
      const forecasted_close_date = opportunity?.forecasted_close_date;

      const due_diligence_end_c = opportunity?.due_diligence_end_c;
      const forecasted_dd_end_date = opportunity?.forecasted_dd_end_date;

      let stateList = [
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_clear_to_close,
        oppurtunityStatusList.closed_purchased
      ];

      if (
        originalOpportunity?.opportunity_status_c ==
        oppurtunityStatusList.closing_sale_pending_amended &&
        stateList.includes(opportunity?.opportunity_status_c)
      ) {
        if (
          !isEmpty(forecasted_close_date) &&
          close_date !== forecasted_close_date
        ) {
          errors.push('Close Date and Forecasted Close Date must be equal');
          return errors;
        }
      }

      stateList = [
        oppurtunityStatusList.closing_diligence_period,
        oppurtunityStatusList.closing_diligence_amended,
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended,
        oppurtunityStatusList.closing_clear_to_close,
        oppurtunityStatusList.closing_cancel_contract,
        oppurtunityStatusList.closed_purchased,
        oppurtunityStatusList.closing_termination_pending
      ];


      if (stateList.includes(opportunity?.opportunity_status_c)) {
        if (
          !isEmpty(forecasted_dd_end_date) &&
          !isEmpty(forecasted_close_date)
        ) {
          const forecastedCloseDate = moment(forecasted_close_date).unix();
          const forecastedDDEndDate = moment(forecasted_dd_end_date).unix();

          if (forecastedCloseDate < forecastedDDEndDate) {
            errors.push(
              'Forecasted Close Date must be greater then or equal Forecasted DD End Date'
            );
            return errors;
          }
        }

        if (
          isEmpty(forecasted_dd_end_date) &&
          !isEmpty(forecasted_close_date)
        ) {
          const forecastedCloseDate = moment(forecasted_close_date).unix();
          const ddEndDate = moment(DateUtility.getDateString(due_diligence_end_c)).unix();

          if (forecastedCloseDate < ddEndDate) {
            errors.push(
              'Forecasted Close Date must be greater then or equal to DD End Date'
            );
          }
        }
      }

    }


    if (status === "action") {

      const forecasted_close_date = opportunity?.forecasted_close_date;

      const due_diligence_end_c = opportunity?.due_diligence_end_c;
      const forecasted_dd_end_date = opportunity?.forecasted_dd_end_date;

      let stateList = [
        oppurtunityStatusList.closing_diligence_period,
        oppurtunityStatusList.closing_diligence_amended,
        oppurtunityStatusList.closing_clear_to_close
      ];

      const forecastedCloseDateInput = document.getElementById('forecasted_close_date');

      if (
        forecastedCloseDateInput?.getAttribute("disabled") !== '' &&
        stateList.includes(opportunity?.opportunity_status_c) &&
        isEmpty(forecasted_close_date)
      ) {
        errors.push(missingField(fieldLabel.forecastedCloseDate));
      }

      stateList = [
        oppurtunityStatusList.closing_diligence_period,
        oppurtunityStatusList.closing_diligence_amended,
      ];

      if (stateList.includes(opportunity.opportunity_status_c)) {
        if (
          !isEmpty(forecasted_dd_end_date) &&
          !isEmpty(forecasted_close_date)
        ) {
          const forecastedCloseDate = moment(forecasted_close_date).unix();
          const forecastedDDEndDate = moment(forecasted_dd_end_date).unix();

          if (forecastedCloseDate < forecastedDDEndDate) {
            errors.push(
              'Forecasted Close Date must be greater then or equal Forecasted DD End Date'
            );
            return errors;
          }
        }

        else if (
          isEmpty(forecasted_dd_end_date) &&
          !isEmpty(forecasted_close_date)
        ) {
          const forecastedCloseDate = moment(forecasted_close_date).unix();
          const ddEndDate = moment(DateUtility.getDateString(due_diligence_end_c)).unix();

          if (forecastedCloseDate < ddEndDate) {
            errors.push(
              'Forecasted Close Date must be greater then or equal to DD End Date'
            );
          }
        }
      }
    }


    return errors;
  }
};
