import { isEmpty } from "helpers/misc-helper";
import { OpportunityViewEntity } from "types/opportunity-types";

export const initial_due_diligence_end = {
  calculate: (opportunity: OpportunityViewEntity) => {
    if (
      isEmpty(opportunity.initial_due_diligence_end) &&
      opportunity.due_diligence_end_c !== ''
    ) {
      return opportunity.due_diligence_end_c;
    }
    return opportunity.initial_due_diligence_end;
  }
};
