import { BrokerageUserListEntity } from "types/opportunity-brokerage-user-types";

export const initialBrokerageUser: BrokerageUserListEntity = {
    brokerge_user_role_for_opportunity: '',
    first_name: '',
    id: '',
    last_name: '',
    user_name: '',
    crm_user_type: '',
    user_email_address_rel: {
        id: '',
        email_address_id: '',
        bean_id: '',
        bean_module: '',
        reply_to_address: 0,
        email_address: {
            id: '',
            email_address: '',
            email_address_caps: ' string'
        }
    }
}