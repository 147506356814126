import { congrats_letter_sent_by } from './congrats-letter-sent-by';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const congrats_letter_sent_date = {
  handleChange: (opportunity: OpportunityViewEntity, originalOpportunity: OpportunityViewEntity) => {
    const result = {
      ...congrats_letter_sent_by.calculate(
        opportunity,
        originalOpportunity
      )
    };

    return result;
  }
};
