import { Box } from '@mui/material';
import { ReactNode } from 'react';

export const ViewContainer = ({ children }: { children: ReactNode }) => (
  <Box p={1} sx={{ maxHeight: 'calc(80vh - 100px)', overflowY: 'auto' }}>
    {' '}
    {children}
  </Box>
);

export const EditContainer = ({ children }: { children: ReactNode }) => (
  <Box p={1} sx={{ maxHeight: '500px', overflowY: 'auto' }}>
    {children}
  </Box>
);
