import accountBuyerTypes from 'assets/constants/account-buyer-types';
import { ObjectType } from 'types';
import { isEmpty } from 'helpers/misc-helper';
import envConfig from 'config/env';
import { get } from './client-service';

const accountBuyerService = {

    getOpportunityAccountBuyers: async (id: string) => {
        const result: ObjectType = await get(
            `/${envConfig.REACT_APP_API_VERSION_1}/opportunities/${id}/account_buyers`
        );
        return result.data;
    },

    getBuyers: (data: ObjectType[]) => {


        let buyers: ObjectType = {};

        for (const type of accountBuyerTypes) {
            let user = data.filter(
                (x: ObjectType) => x.account_buyer_role_for_opportunity === type
            );
            buyers = { ...buyers, [type]: user[0] };
        }


        let result = {
            buyerContractNameAtOfferSent: `${buyers?.buyer_contract_name_at_offer_sent_id?.name ?? ''}`,
            buyerContractNameAtDiligenceStart: `${buyers?.buyer_contract_name_at_diligence_start_id?.name ?? ''}`
        };


        return result;
    }

}

export default accountBuyerService