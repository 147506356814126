import { Box, FormLabel, List, ListItem } from '@mui/material';
import { v4 as uuid } from 'uuid';
import AddButton from 'components/form/button-add';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import KnowledgeBaseHelper from './knowledge-base-helper';
import negotiatorNoteHelper from 'helpers/negotiator-note/negotiator-note-helper';
import offerGrade from 'assets/constants/offer-grade';
import opportunityHelper from 'helpers/opportunity-helper';
import React, { useEffect, useState } from 'react';
import RouteLink from 'components/link/route-link';
import UnitEmpty from 'components/form/unit-empty';
import UnitItem from 'components/form/unit-item';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitSelect from 'components/form/unit-select';
import UnitSwitch from 'components/form/unit-switch';
import UnitText from 'components/form/unit-text';
import variableConfig from 'config/variable';
import messageService from 'services/message-service';
import { useParams } from 'react-router-dom';
import { InputChangeEvent } from 'types/common-types';
import { NoteEntity, OpportunityEntity } from 'types/opportunity-types';

interface RecordViewType {
  data: OpportunityEntity;
  handleChange?: (e: InputChangeEvent) => void;
  readOnly?: boolean;
}

const NotesTermsProvisionsRecordView = ({
  data,
  handleChange = emptyFunction,
  readOnly = false
}: RecordViewType) => {
  const { opportunity_id } = useParams<{ opportunity_id: string }>();

  const [negotiatorNotes, setNegotiatorNotes] = useState<NoteEntity[]>([]);
  const [transactionNotes, setTransactionNotes] = useState<NoteEntity[]>([]);

  const loadNegotiatorNotes = async (id: string) => {
    const result = await messageService.getNegotiatorNotes(id);

    if (result.isSuccess) {
      setNegotiatorNotes(result.data.data);
    }
  };

  const loadTransactionNotes = async (id: string) => {
    const result = await messageService.getTransactionNotes(id);

    if (result.isSuccess) {
      setTransactionNotes(result.data.data);
    }
  };
  1;

  useEffect(() => {
    if (opportunity_id !== undefined) {
      loadNegotiatorNotes(opportunity_id);
      loadTransactionNotes(opportunity_id);
    }
  }, []);

  return (
    <>
      <FormContainer>
        <UnitItem>
          <FormLabel>{fieldLabel.negotiationNotes}:</FormLabel>
          <Box
            sx={{
              width: '100%',
              maxHeight: 350,
              overflowY: 'scroll'
            }}
          >
            <List>
              {negotiatorNotes.map((note: NoteEntity) => (
                <ListItem
                  key={uuid()}
                  sx={{ padding: '10px' }}
                  component="div"
                  disablePadding
                >
                  {negotiatorNoteHelper.getNegotiatorNoteLineItem(note)}
                </ListItem>
              ))}
            </List>
          </Box>
          <FormContainer>
            <UnitItem grid={{ xs: 1, sm: 12 }}>
              <RouteLink
                name={<AddButton onClick={emptyFunction} />}
                key={'view'}
                url={`/opportunities/${data.id}/notes_chats/negotiator_notes`}
              />
            </UnitItem>
          </FormContainer>
        </UnitItem>
        <UnitItem>
          <FormLabel>{fieldLabel.transactionNotes}:</FormLabel>
          <Box
            sx={{
              width: '100%',
              maxHeight: 350,
              overflowY: 'scroll'
            }}
          >
            <List>
              {transactionNotes.map((note: NoteEntity) => (
                <ListItem
                  key={uuid()}
                  sx={{ padding: '10px' }}
                  component="div"
                  disablePadding
                >
                  {opportunityHelper.getTransactionNoteStaring(note)}
                </ListItem>
              ))}
            </List>
          </Box>
          <FormContainer>
            <UnitItem grid={{ xs: 1, sm: 12 }}>
              <RouteLink
                key={'view'}
                url={`/opportunities/${data.id}/notes_chats/transaction_notes`}
                name={<AddButton onClick={emptyFunction} />}
              />
            </UnitItem>
          </FormContainer>
        </UnitItem>

        <UnitSwitch
          value={data?.sellers_disclosure_received ?? 0}
          onChange={handleChange}
          name="sellers_disclosure_received"
          label={fieldLabel.sellerDisclosureReceived}
          disabled={readOnly}
        />

        <UnitSwitch
          value={data.mog_received ?? 0}
          onChange={handleChange}
          name="mog_received"
          label={fieldLabel.mogReceived}
          disabled={readOnly}
          grid={{ xs: 12, sm: 6 }}
        />

        <UnitText
          label={fieldLabel.addendumNo}
          name="addendum_no"
          value={data.addendum_no ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />

        <UnitText
          label={fieldLabel.addendumNotes}
          name="addendum_notes"
          value={data.addendum_notes ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline
          rows={4}
        />

        <UnitText
          label={fieldLabel.publicRemarksC}
          name="public_remarks_c"
          value={data.public_remarks_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline
          rows={4}
        />

        <UnitText
          label={fieldLabel.realtorRemarksC}
          name="realtor_remarks_c"
          value={data.realtor_remarks_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline
          rows={4}
        />

        <UnitText
          label={fieldLabel.additionalTermsCommentsC}
          name="additional_terms_comments_c"
          value={data.additional_terms_comments_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline
          rows={4}
        />

        <UnitText
          label={fieldLabel.inclusions}
          name="inclusion"
          value={data.inclusion ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline
          rows={4}
        />

        <UnitText
          label={fieldLabel.exclusions}
          name="exclusion"
          value={data.exclusion ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline
          rows={4}
        />

        <UnitText
          label={fieldLabel.otherCounterOfferTerms}
          name="other_counter_offer_terms"
          value={data.other_counter_offer_terms ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline
          rows={4}
        />

        <UnitText
          label={fieldLabel.backwardsStatusMoveReason}
          name="backwards_status_move_reason"
          value={data.backwards_status_move_reason ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
          multiline
          rows={4}
        />

        <UnitEmpty />

        <UnitSelect
          name="offer_grade"
          label={fieldLabel.offerGrade}
          records={getObjectEntriesAsArray(offerGrade)}
          value={data.offer_grade ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />

        <KnowledgeBaseHelper
          tag={variableConfig.opportunitiesNotesTermsProvisionOfferGradeTag}
        />

        <UnitPriceFormatter
          label={fieldLabel.lowestAcceptablePrice}
          name="lowest_acceptable_price"
          value={data.lowest_acceptable_price ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />

        <UnitText
          label={fieldLabel.offerResponseDetails}
          name="offer_response_details"
          value={data.offer_response_details ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline
          rows={4}
        />
      </FormContainer>
    </>
  );
};

export default NotesTermsProvisionsRecordView;
