import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';


export const parties_listing_agent_name = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.offer_sent_to_seller &&
      isEmpty(opportunity.listingAgent.name)
    ) {
      errors.push(missingField('Listing Agent'));
    }

    return errors;
  }
};
