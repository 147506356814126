import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { isEmpty } from 'helpers/misc-helper';

export const hoa_addendum_received = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    if (
      opportunity?.opportunity_status_c ===
      oppurtunityStatusList.closing_diligence_period &&
      isEmpty(opportunity?.hoa_addendum_received)
    ) {
      errors.push(missingField('HOA Addendum Received'));
    }
    return errors;
  }
};
