import { getFilterString } from 'helpers/query-string-helper';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { OptionType } from 'types/option-type';
import accountBuyerNameService from 'services/account-buyer-name-service';
import cancelReasons from 'assets/constants/cancel-reasons';
import cancelSubReasons from 'assets/constants/cancel-sub-reasons';
import CopyToClipboard from 'components/copy-to-clipboard';
import customerReservationStatus from 'assets/constants/customer-reservation-status';
import DateUtility from 'helpers/date-helper';
import earnestMoneyDueType from 'assets/constants/earnest-money-due-type';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import getObjectEntriesAsArray from 'helpers/object-field-helper';
import inspectionPeriod from 'assets/constants/inspection-period';
import offerSubmitTypes from 'assets/constants/offer-submit-types';
import optionDaysType from 'assets/constants/option-days-type';
import primaryRejectReasons from 'assets/constants/primary-reject-reasons';
import React, { useEffect, useState } from 'react';
import requestDescription from 'assets/constants/request-description';
import retradeReasons from 'assets/constants/retrade-reasons';
import RouteLink from 'components/link/route-link';
import sellerOwnershipRepresentation from 'assets/constants/seller-ownership-representation';
import state from 'assets/constants/state';
import terminationReason from 'assets/constants/termination-reason';
import terminationSubReason from 'assets/constants/termination-sub-reason';
import UnitAccount from 'components/form/unit-account';
import UnitDate from 'components/form/unit-date';
import UnitDateTime from 'components/form/unit-date-time';
import UnitEmpty from 'components/form/unit-empty';
import UnitPriceFormatter from 'components/form/unit-price-formatter';
import UnitRead from 'components/form/unit-read';
import UnitSelect from 'components/form/unit-select';
import UnitSwitch from 'components/form/unit-switch';
import UnitText from 'components/form/unit-text';
import variableConfig from 'config/variable';
import yesNo from 'assets/constants/yes-no';
import UnitItem from 'components/form/unit-item';
import InfoMessage from 'components/errors/info-component';
import { DispatchSetState, InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { OpportunityViewEntity } from 'types/opportunity-types';

interface RecordViewType {
  data: OpportunityViewEntity;
  originalOpportunity: OpportunityViewEntity;
  validation?: ErrorValidation;
  handleChange?: (e: InputChangeEvent) => void;
  handleChangeWihEffect?: (e: InputChangeEvent) => void;
  readOnly?: boolean;
  setOpportunity?: DispatchSetState<OpportunityViewEntity>;
}

const PrimaryInformationRecordView = ({
  data,
  validation = {},
  handleChange = emptyFunction, 
  handleChangeWihEffect = emptyFunction,
  readOnly = false,
  setOpportunity = emptyFunction
}: RecordViewType) => {
  const [buyerContract, setBuyerContract] = useState<OptionType[]>([]);

  const loadAccountBuyerName = async () => {
    const query = getFilterString({
      account_id: data.account_id_1,
      entera_customer_id: data.entera_customer_id,
      market_id: data.market.id,
      entera_market_id: data.entera_market_id
    });

    const result = await accountBuyerNameService.getList(query);

    if (result.isSuccess) {
      const accountBuyerNameList = result.data.data;
      const buyerContractOptions: OptionType[] = [];
      for (const accountBuyerName of accountBuyerNameList) {
        if (
          accountBuyerName.account_id === data.account_id_1 &&
          accountBuyerName.market_id === data.market.id &&
          accountBuyerName.entera_customer_id === data.entera_customer_id &&
          accountBuyerName.entera_market_id === data.entera_market_id
        ) {
          buyerContractOptions.push({
            label: accountBuyerName.name,
            value: accountBuyerName.id
          });
        }
      }

      const accountBuyer = buyerContractOptions.find(
        (option) => option.value == data.account_buyer_id
      );

      setOpportunity((prevOpportunity) => ({
        ...prevOpportunity,
        accountBuyerName: accountBuyer?.label ?? ''
      }));

      setBuyerContract(buyerContractOptions);
    }
  };

  const getCopyToClipboardValueForSelect = (field: string): string => {
    const value = buyerContract.find((x: ObjectType) => x.value === field);
    return (value && value.label) ?? '';
  };

  useEffect(() => {
    if (
      !isEmpty(data.account_id_1) &&
      !isEmpty(data.entera_customer_id) &&
      !isEmpty(data.market.id) &&
      !isEmpty(data.entera_market_id)
    ) {
      loadAccountBuyerName();
    }
  }, [
    data.account_buyer_id,
    data.account_id_1,
    data.entera_customer_id,
    data.market.id,
    data.entera_market_id
  ]);
  return (
    <>
      <FormContainer>
        {readOnly === true ? (
          <UnitRead
            label={fieldLabel.account}
            value={
              <>
                <RouteLink
                  url={`/accounts/${data?.account?.id}/view`}
                  name={data?.account?.name || '-'}
                  target={true}
                />
              </>
            }
          />
        ) : (
          <UnitAccount
            value={{
              label: data?.account?.name || '',
              value: data?.account?.id || ''
            }}
            multiple={false}
            onChange={(val: OptionType) => {
              setOpportunity((prevOpportunity) => ({
                ...prevOpportunity,
                account: {
                  id: val?.value || '',
                  name: val?.label || ''
                },
                account_id_1: val?.value || ''
              }));
            }}
            readOnly={readOnly}
            error={validation['account_id_1']}
          />
        )}

        <UnitDateTime
          label={fieldLabel.dateModified}
          name="date_modified"
          value={data?.date_modified || null}
          onChange={(value: string) =>
            handleChange({
              target: {
                name: 'date_modified',
                value: value
              }
            })
          }
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />

        <UnitSelect
          label={fieldLabel.buyerContractName}
          name="account_buyer_id"
          value={data?.account_buyer_id ?? ''}
          onChange={handleChange}
          records={buyerContract}
          readOnly={readOnly}
          required
          error={validation['account_buyer_id'] ?? []}
          InputProps={{
            endAdornment: (
              <CopyToClipboard
                value={getCopyToClipboardValueForSelect(data.account_buyer_id)}
              />
            )
          }}
        />

        <UnitEmpty />

        <UnitSelect
          label={fieldLabel.buyerNameHud}
          name="buyer_name_prelim_hud_id"
          value={data?.buyer_name_prelim_hud_id ?? ''}
          onChange={handleChange}
          records={buyerContract}
          readOnly={readOnly}
          InputProps={{
            endAdornment: (
              <CopyToClipboard
                value={getCopyToClipboardValueForSelect(
                  data.buyer_name_prelim_hud_id
                )}
              />
            )
          }}
        />
        <UnitText
          label={fieldLabel.buyerContractNameAtOfferSent}
          name="buyer_contract_name_at_offer_sent_name"
          value={data?.buyerContractNameAtOfferSent ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
          InputProps={{
            endAdornment: (
              <CopyToClipboard
                value={data.buyerContractNameAtOfferSent ?? ''}
              />
            )
          }}
        />
        <UnitText
          label={fieldLabel.buyerContractNameAtDiligenceStart}
          name="buyer_contract_name_at_diligence_start_name"
          value={data?.buyerContractNameAtDiligenceStart ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
          InputProps={{
            endAdornment: (
              <CopyToClipboard
                value={data.buyerContractNameAtDiligenceStart ?? ''}
              />
            )
          }}
        />
        <UnitSelect
          label={fieldLabel.buyerContractNameAtClosing}
          name="buyer_contract_name_at_closing_id"
          value={data?.buyer_contract_name_at_closing_id ?? ''}
          onChange={handleChange}
          records={buyerContract}
          readOnly={readOnly}
          InputProps={{
            endAdornment: (
              <CopyToClipboard
                value={getCopyToClipboardValueForSelect(
                  data?.buyer_contract_name_at_closing_id
                )}
              />
            )
          }}
        />

        <UnitText
          label={fieldLabel.address}
          name="property_address_c"
          value={data?.property_address_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.city}
          name="property_address_city_c"
          value={data?.property_address_city_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.streetName}
          name="street_name"
          value={data?.street_name ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitEmpty />
        <UnitSelect
          name="property_address_state_c"
          label={fieldLabel.state}
          records={getObjectEntriesAsArray(state)}
          value={data?.property_address_state_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.zip}
          name="property_address_postalcode_c"
          value={data?.property_address_postalcode_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.country}
          name="county_c"
          value={data?.county_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitSelect
          name="customer_reservation_status"
          label={fieldLabel.customerReservationStatus}
          records={getObjectEntriesAsArray(customerReservationStatus)}
          value={data?.customer_reservation_status ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.schoolDistrict}
          name="school_district"
          value={data?.school_district ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitSelect
          name="customer_accepted_status_verified"
          label={fieldLabel.customerAcceptedStatusVerified}
          records={getObjectEntriesAsArray(yesNo)}
          value={data?.customer_accepted_status_verified ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.fullAddress}
          name="full_address"
          value={data?.full_address ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline={true}
          rows={2}
          sx={{ padding: 0 }}
        />
        <UnitSelect
          name="offer_submit_type"
          label={fieldLabel.offerSubmitType}
          records={getObjectEntriesAsArray(offerSubmitTypes)}
          value={data?.offer_submit_type ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitDate
          label={fieldLabel.offerDate}
          name="offer_date_c"
          value={data?.offer_date_c ? data?.offer_date_c : null}
          error={validation['offer_date_c'] ?? ''}
          onChange={(value: string) =>
            handleChange({
              target: {
                name: 'offer_date_c',
                value: value
              }
            })
          }
          readOnly={readOnly}
        />
        <UnitDate
          label={fieldLabel.offerSubmitByDate}
          name="offer_submit_by_date"
          value={data?.offer_submit_by_date || null}
          error={validation['offer_submit_by_date'] ?? ''}
          onChange={(value: string) =>
            handleChangeWihEffect({
              target: {
                name: 'offer_submit_by_date',
                value: value
              }
            })
          }
          readOnly={readOnly}
        />
        <UnitDate
          label={fieldLabel.offerExpiration}
          name="offer_expiration_date_c"
          value={
            data?.offer_expiration_date_c ? data?.offer_expiration_date_c : null
          }
          onChange={(value: string) => {
            handleChangeWihEffect({
              target: {
                name: 'offer_expiration_date_c',
                value: value
              }
            });
          }}
          readOnly={readOnly}
        />
        <UnitSelect
          name="seller_ownership_representation"
          label={fieldLabel.sellerOwnershipRepresentation}
          records={getObjectEntriesAsArray(sellerOwnershipRepresentation)}
          value={data?.seller_ownership_representation ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitDate
          label={fieldLabel.contractExecutionDate}
          name="contract_execution_date_c"
          value={data?.contract_execution_date_c || null}
          error={validation['contract_execution_date_c'] ?? ''}
          onChange={(value: string) => {
            handleChangeWihEffect({
              target: {
                name: 'contract_execution_date_c',
                value: value
              }
            });
          }}
          readOnly={readOnly}
        />
        <UnitEmpty />
        <UnitDate
          label={fieldLabel.contractReceivedDate}
          name="contract_received_date"
          value={data?.contract_received_date || null}
          onChange={(value: string) => {
            handleChange({
              target: {
                name: 'contract_received_date',
                value: value
              }
            });
          }}
          readOnly={readOnly}
        />
        <UnitEmpty />
        <UnitDate
          label={fieldLabel.earnestMoneyDueDate}
          name="earnest_money_due_date_c"
          value={data?.earnest_money_due_date_c || null}
          error={validation['earnest_money_due_date_c'] ?? ''}
          onChange={(value: string) => {
            handleChangeWihEffect({
              target: {
                name: 'earnest_money_due_date_c',
                value: value
              }
            });
          }}
          readOnly={readOnly}
        />
        <UnitSelect
          name="earnest_money_due_c"
          label={fieldLabel.earnestMoneyDueType}
          records={getObjectEntriesAsArray(earnestMoneyDueType)}
          value={data?.earnest_money_due_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitDateTime
          label={fieldLabel.dueDiligenceStartDate}
          name="due_diligence_start_c"
          value={data?.due_diligence_start_c || null}
          error={validation['due_diligence_start_c'] ?? ''}
          onChange={(e: string) => {
            handleChangeWihEffect({
              target: { name: 'due_diligence_start_c', value: e }
            });
          }}
          readOnly={readOnly}
        />
        <UnitEmpty />
        <UnitDateTime
          label={fieldLabel.initialDueDiligenceEnd}
          name="initial_due_diligence_end"
          value={data?.initial_due_diligence_end ?? null}
          onChange={(e) => {
            handleChange({
              target: { name: 'initial_due_diligence_end', value: e }
            });
          }}
          readOnly={readOnly}
          disabled={readOnly === true ? false : true}
        />
        <UnitSwitch
          value={data?.no_diligence_period ?? 0}
          onChange={handleChange}
          name="no_diligence_period"
          label={fieldLabel.noDueDiligencePeriod}
          disabled={readOnly}
        />
        <UnitItem>
          <UnitDateTime
            label={fieldLabel.dueDiligenceEndDate}
            name="due_diligence_end_c"
            value={data?.due_diligence_end_c || null}
            error={validation['due_diligence_end_c'] ?? ''}
            onChange={(e: string) => {
              handleChangeWihEffect({
                target: {
                  name: 'due_diligence_end_c',
                  value: DateUtility.addHours(
                    e,
                    variableConfig.DUE_DILIGENCE_END_START_TIME
                  )
                }
              });
            }}
            readOnly={readOnly}
            grid={{ sm: 12, xs: 12 }}
          />
          {!isEmpty(
            data?.due_diligence_end_c_federal_holiday_validation_message
          ) && (
            <InfoMessage
              message={
                data?.due_diligence_end_c_federal_holiday_validation_message ??
                ''
              }
            />
          )}
        </UnitItem>
        <UnitEmpty />
        <UnitDate
          label={fieldLabel.forecastedDdEndDate}
          name="forecasted_dd_end_date"
          value={data?.forecasted_dd_end_date || null}
          error={validation['forecasted_dd_end_date'] ?? ''}
          onChange={(value: string) => {
            handleChange({
              target: {
                name: 'forecasted_dd_end_date',
                value: value
              }
            });
          }}
          readOnly={readOnly}
        />
        <UnitEmpty />
        <UnitItem>
          <UnitDate
            label={fieldLabel.closeDate}
            name="close_date_c"
            value={data?.close_date_c || null}
            error={validation['close_date_c'] ?? ''}
            onChange={async (value: string) => {
              handleChangeWihEffect({
                target: {
                  name: 'close_date_c',
                  value: value
                }
              });
            }}
            readOnly={readOnly}
            grid={{ sm: 12, xs: 12 }}
          />
          {!isEmpty(data?.close_date_c_federal_holiday_validation_message) && (
            <InfoMessage
              message={
                data?.close_date_c_federal_holiday_validation_message ?? ''
              }
            />
          )}
        </UnitItem>

        <UnitDate
          label={fieldLabel.shortSaleSellerAcceptDate}
          name="short_sale_seller_accept_date"
          value={data?.short_sale_seller_accept_date || null}
          error={validation['short_sale_seller_accept_date'] ?? ''}
          onChange={(value: string) => {
            handleChangeWihEffect({
              target: {
                name: 'short_sale_seller_accept_date',
                value: value
              }
            });
          }}
          readOnly={readOnly}
        />
        <UnitDate
          label={fieldLabel.forecastedCloseDate}
          name="forecasted_close_date"
          value={data?.forecasted_close_date || null}
          error={validation['forecasted_close_date'] ?? ''}
          onChange={(value: string) => {
            handleChange({
              target: {
                name: 'forecasted_close_date',
                value: value
              }
            });
          }}
          readOnly={readOnly}
        />
        <UnitEmpty />
        <UnitDate
          label={fieldLabel.originalCloseOfEscrowDate}
          name="original_close_of_escrow_date"
          value={data?.original_close_of_escrow_date || null}
          onChange={(value: string) => {
            handleChange({
              target: {
                name: 'original_close_of_escrow_date',
                value: value
              }
            });
          }}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />
        <UnitEmpty />
        <UnitSelect
          name="reason_description"
          label={fieldLabel.reasonDescription}
          records={getObjectEntriesAsArray(requestDescription)}
          value={data?.reason_description ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitEmpty />
        <UnitText
          label={fieldLabel.optionPeriodDays}
          name="option_period_days_c"
          value={data?.option_period_days_c ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          error={validation['option_period_days_c'] ?? ''}
        />
        <UnitSelect
          name="option_days_type_c"
          label={fieldLabel.optionDaysType}
          records={getObjectEntriesAsArray(optionDaysType)}
          value={data?.option_days_type_c ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
        />
        <UnitSelect
          name="contract_termination_reasons"
          label={fieldLabel.terminationReasons}
          records={getObjectEntriesAsArray(terminationReason)}
          value={data?.contract_termination_reasons ?? ''}
          onChange={(e) => {
            handleChangeWihEffect(e);
            setOpportunity((preOpportunity) => ({
              ...preOpportunity,
              termination_reason_subcategory: ''
            }));
          }}
          readOnly={readOnly}
          error={validation['contract_termination_reasons'] ?? ''}
        />
        <UnitSelect
          name="option_inspection_period_c"
          label={fieldLabel.optionInspectionPeriod}
          records={getObjectEntriesAsArray(inspectionPeriod)}
          value={data?.option_inspection_period_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitSelect
          name="termination_reason_subcategory"
          label={fieldLabel.terminationReasonSubcategory}
          records={terminationSubReason[data?.contract_termination_reasons]}
          value={data?.termination_reason_subcategory ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          error={validation['termination_reason_subcategory'] ?? ''}
        />
        <UnitEmpty />
        <UnitDate
          label={fieldLabel.contractTerminatedDate}
          name="contract_terminated_date"
          value={data?.contract_terminated_date || null}
          error={validation['contract_terminated_date'] ?? ''}
          onChange={(value: string) => {
            handleChangeWihEffect({
              target: {
                name: 'contract_terminated_date',
                value: value
              }
            });
          }}
          disabled={readOnly}
          readOnly={readOnly}
        />
        <UnitSelect
          name="retrade_reason"
          label={fieldLabel.retradeReason}
          records={getObjectEntriesAsArray(retradeReasons)}
          value={data?.retrade_reason ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitSelect
          name="reject_reason"
          label={fieldLabel.rejectReason}
          records={getObjectEntriesAsArray(primaryRejectReasons)}
          value={data?.reject_reason ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          error={validation['reject_reason'] ?? ''}
        />
        <UnitSelect
          name="cancel_reason"
          label={fieldLabel.cancelReason}
          records={getObjectEntriesAsArray(cancelReasons)}
          value={data?.cancel_reason ?? ''}
          onChange={(e) => {
            handleChange(e);
            setOpportunity((prevOpportunity) => ({
              ...prevOpportunity,
              cancellation_reason_subcategory: ''
            }));
          }}
          readOnly={readOnly}
          error={validation['cancel_reason'] ?? ''}
        />
        <UnitEmpty />
        <UnitSelect
          name="cancellation_reason_subcategory"
          label={fieldLabel.cancelSubReason}
          records={cancelSubReasons[data?.cancel_reason]}
          value={data?.cancellation_reason_subcategory ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          error={validation['cancellation_reason_subcategory'] ?? ''}
        />
        <UnitText
          label={fieldLabel.rejectNote}
          name="reject_note"
          value={data?.reject_note ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline={true}
          rows={4}
        />
        <UnitText
          label={fieldLabel.otherCancellationReason}
          name="additional_cancellation_reason"
          value={data?.additional_cancellation_reason ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline={true}
          rows={4}
        />
        <UnitDate
          label={fieldLabel.rejectDate}
          name="reject_date"
          value={data?.reject_date || null}
          onChange={(value: string) => {
            handleChangeWihEffect({
              target: {
                name: 'reject_date',
                value: value
              }
            });
          }}
          readOnly={readOnly}
        />
        <UnitDate
          label={fieldLabel.offerLostDate}
          name="offer_lost_date"
          value={data?.offer_lost_date || null}
          onChange={(value: string) => {
            handleChange({
              target: {
                name: 'offer_lost_date',
                value: value
              }
            });
          }}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.howManyCompetingOffers}
          name="competing_offer_count"
          value={data?.competing_offer_count ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitDate
          label={fieldLabel.offerCancelledDate}
          name="offer_cancelled_date"
          value={data?.offer_cancelled_date || null}
          onChange={(value: string) => {
            handleChange({
              target: {
                name: 'offer_cancelled_date',
                value: value
              }
            });
          }}
          disabled={readOnly === true ? false : true}
          readOnly={readOnly}
        />
        <UnitPriceFormatter
          name="competing_offer_highest_price"
          value={data?.competing_offer_highest_price}
          onChange={handleChange}
          label={fieldLabel.highestCompetingOfferPrice}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.cancelNote}
          name="cancel_note"
          value={data?.cancel_note ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline={true}
          rows={4}
        />
        <UnitEmpty />
        <UnitDate
          label={fieldLabel.cancelRequestReceivedDate}
          name="cancel_request_received_date"
          value={data?.cancel_request_received_date || null}
          onChange={(value: string) => {
            handleChange({
              target: {
                name: 'cancel_request_received_date',
                value: value
              }
            });
          }}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.bidId}
          name="bid_id"
          value={data?.bid_id ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.bidUrl}
          name="bid_url"
          value={data?.bid_url ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.emailUniqueId}
          name="simba_unique_id"
          value={data?.simba_unique_id ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.productType}
          name="product_type"
          value={data?.product_type ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitSelect
          name="lease_back_allowed_c"
          label={fieldLabel.leaseBackAllowed}
          records={getObjectEntriesAsArray(yesNo)}
          value={data?.lease_back_allowed_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitSelect
          name="homeowner_association_c"
          label={fieldLabel.homeOwnerAssociation}
          records={getObjectEntriesAsArray(yesNo)}
          value={data?.homeowner_association_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitSelect
          name="survey_requested_c"
          label={fieldLabel.surveyRequested}
          records={getObjectEntriesAsArray(yesNo)}
          value={data?.survey_requested_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitSelect
          name="warranty_required_c"
          label={fieldLabel.warrantyRequired}
          records={getObjectEntriesAsArray(yesNo)}
          value={data?.warranty_required_c ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitEmpty />
        <UnitSelect
          name="has_leasing_restrictions"
          label={fieldLabel.leasingRestrictions}
          records={getObjectEntriesAsArray(yesNo)}
          value={data?.has_leasing_restrictions ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          error={validation['has_leasing_restrictions'] ?? ''}
        />
        <UnitText
          label={fieldLabel.internalTerminationFeedback}
          name="internal_termination_feedback"
          value={data?.internal_termination_feedback ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
          multiline={true}
          rows={4}
        />
        <UnitText
          label={fieldLabel.restrictionsNotes}
          name="restrictions_notes"
          value={data?.restrictions_notes ?? ''}
          onChange={handleChangeWihEffect}
          readOnly={readOnly}
          multiline={true}
          rows={4}
          error={validation['restrictions_notes'] ?? ''}
        />
        <UnitSelect
          name="disable_inspection_emails_for_waived_inspections"
          label={fieldLabel.disableInspectionEmailsWithWaived}
          records={getObjectEntriesAsArray(yesNo)}
          value={data?.disable_inspection_emails_for_waived_inspections ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
        <UnitText
          label={fieldLabel.buyerOfferKey}
          name="buyer_offer_key"
          value={data?.buyer_offer_key ?? ''}
          onChange={handleChange}
          readOnly={readOnly}
        />
      </FormContainer>
    </>
  );
};

export default PrimaryInformationRecordView;
