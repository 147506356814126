import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';

export const parties_lead_source_type = {
  validate: (
    opportunity: OpportunityViewEntity
  ) => {
    const errors: string[] = [];
    if (
      !isEmpty(opportunity?.leadSource.name) &&
      isEmpty(opportunity?.leadSource.type)
    ) {
      errors.push(missingField('Parties Lead Source Type'));
    }

    return errors;
  }
};
