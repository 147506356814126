import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';

export const lease_type = {
  validate: function (opportunity: OpportunityViewEntity, status: string) {
    const errors: string[] = [];

    if (status === 'edit') {
      if (
        opportunity?.has_post_occupancy === 'yes' &&
        isEmpty(opportunity?.lease_type)
      ) {
        errors.push(missingField('Lease Type'));
      }
    }

    if (status === 'action') {
      if (
        (opportunity?.has_post_occupancy === 'yes' ||
          isEmpty(opportunity?.has_post_occupancy)) &&
        opportunity?.lease_type.trim().length === 0
      ) {
        errors.push(missingField('Lease Type'));
      }
    }

    return errors;
  }
};
