import { ObjectType } from 'types';
import { negotiator_commission_pct } from './commission-negotiator-commission-pct';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const buyer_source_pct = {
  calculate: (opportunity: OpportunityViewEntity) => {
    const values: ObjectType = {
      '': 0,
      entera_client: 0,
      entera_known: 10,
      direct_unknown: 17.5
    };

    return values[opportunity.buyer_source];
  },

  handleChange: function (
    opportunity: OpportunityViewEntity
  ) {
    const result = {
      negotiator_commission_pct:
        negotiator_commission_pct.calculate(
          opportunity

        )
    };

    return result;
  }
};
