import { OpportunityViewEntity } from 'types/opportunity-types';
import { OptionType } from 'types/option-type';

export const municipal_inspection_types = {
  validate: function (opportunity: OpportunityViewEntity) {
    const errors: string[] = [];

    const value = opportunity?.municipal_inspection_types_edit?.map(
      (x: OptionType) => x.value
    );

    if (value?.includes('none_required') && value.length > 1) {
      errors.push('To select other value Please unselect empty or None Required value');
    }

    return errors;
  }
};
