import fieldLabel from 'assets/constants/fieldLabel';
import { missingField } from 'assets/validation-template';
import { OpportunityViewEntity } from 'types/opportunity-types';
import { isEmpty } from 'helpers/misc-helper';

export const msa_id_1 = {
  validate: (
    opportunity: OpportunityViewEntity,

  ) => {
    const errors: string[] = [];

    if (isEmpty(opportunity?.msa_id_1)) {
      errors.push(missingField(fieldLabel.msa));
    }

    return errors;
  }
};
