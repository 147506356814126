import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import defaultSort from 'assets/list/dashboard/default-sort';
import DataGrid from 'components/data-grid';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import useRouteName from 'pages/route-outlet-context';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ObjectType } from 'types';
import { OpportunityListResponseType } from 'types/api-response';
import OppurtunityService from 'services/oppurtunity-service';
import { OpportunityListItem } from 'types/opportunity-list-item';
import DefaultDashboardFields from 'assets/list/dashboard/default-column';
import oppurtuniyColumns from 'assets/list/dashboard/column';
import marketPreferenceService from 'services/market-preference-service';

const OpportunityList = ({ routeTag }: { routeTag: string }) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  const [oppurtunities, setOppurtunities] = useState<OpportunityListItem[]>([]);

  const [selectedColumns] = useState<string[]>(
    DefaultDashboardFields.OPPORTUNITY_LIST
  );

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { market_preference_id } = useParams<ObjectType>();

  const { setRouteName } = useRouteName();

  const [rowCountState, setRowCountState] = React.useState(0);

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      pageSize: 25,
      page: 0
    });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    defaultSort
  ]);

  const getColumns = useCallback(() => {
    return oppurtuniyColumns.filter((x: ObjectType) =>
      selectedColumns.includes(x.field)
    );
  }, [selectedColumns]);

  const getMarketPreferenceService = async (market_preference_id: string) => {
    const response: ObjectType = await marketPreferenceService.get(
      market_preference_id
    );
    return response;
  };

  const getOpportunities = async () => {
    const marketPreference = await getMarketPreferenceService(
      market_preference_id
    );

    const filterInfo = {
      account_id: marketPreference?.data?.account_id,
      msa_id: marketPreference?.data?.msa_id
    };

    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filterInfo
    });

    setIsLoading(true);

    const result: OpportunityListResponseType = await OppurtunityService.get(
      queryString
    );

    setIsLoading(false);

    if (result.isError) {
      setErrorMessage(result.errorMessage);
      return;
    }

    setOppurtunities(result.data.data);
    setRowCountState(result.data.meta.total);
  };

  useEffect(() => {
    if (!initialLoad) getOpportunities();
    setInitialLoad(true);
  }, []);

  useEffect(() => {
    if (!initialLoad) return;
    getOpportunities();
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    if (!initialLoad) return;
    getOpportunities();
  }, [JSON.stringify(sortModel)]);

  useEffect(() => {
    setRouteName(routeTag);
  }, []);

  return (
    <>
      <DataGrid
        rows={oppurtunities}
        columns={getColumns()}
        rowCount={rowCountState}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        loading={isLoading}
        error={errorMessage}
      />
    </>
  );
};

export default OpportunityList;
