import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import DateUtility from 'helpers/date-helper';
import { isValueChanged } from 'helpers/misc-helper';
import { OpportunityViewEntity } from 'types/opportunity-types';

export const offer_lost_date = {
  calculate: (opportunity: OpportunityViewEntity, originalOpportunity: OpportunityViewEntity) => {
    if (
      oppurtunityStatusList.offer_lost_deal ===
      opportunity.opportunity_status_c &&
      isValueChanged(opportunity, originalOpportunity, 'opportunity_status_c')
    ) {
      return DateUtility.getTodayDateString();
    }

    return opportunity.offer_lost_date;
  }
};
